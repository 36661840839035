import { Component, OnInit, ElementRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { trigger, state, style } from '@angular/animations';
import { Location } from '@angular/common';
import { FilterService } from 'src/app/services/filter.service';
import { LoaderService } from 'src/app/services/loader.service';
import { DashboardService } from 'src/app/services/dashboard.service';
import { FormGroup, FormBuilder } from '@angular/forms';

@Component({
  selector: 'app-page-nav',
  templateUrl: './page-nav.component.html'
})
export class PageNavComponent implements OnInit {

  constructor(
    private _activRoute: ActivatedRoute,
    private _router: Router,
    private _loc: Location,
    private _filtServ: FilterService,
    private _loader: LoaderService,
    private _dashServ: DashboardService,
    private _elementRef : ElementRef,
    private _fb: FormBuilder) {
     
     }

  pageUrl:string;
  filterState:string;
  isLoading:boolean;

  zenithForm: FormGroup;

  ngOnInit() {
    this.pageUrl = this._loc.path();
    //console.log(this.pageUrl)
    this.zenithForm = this._fb.group({
      quantite: ["",[]]
    })
    // const url = this.activRoute.snapshot.paramMap;
    // //console.log(this.pageUrl)
    this._filtServ.getFilterState().subscribe(
      state => {
        // //console.log("state dans page-nav => ",state);
        this.filterState = state;
      }
    )
  }

  hireZenith(){
    return;
  }

  ngOnChanges(){
    this._filtServ.changeFilterState(this.filterState);
  }

}
