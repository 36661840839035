import { Injectable } from '@angular/core';
import { BehaviorSubject, Subscription, Subject } from 'rxjs';
import { FilterService } from './filter.service';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { share, refCount, publishLast, publishReplay } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class OffresService {

  constructor(private _filtServ: FilterService, private _http: HttpClient) { 
    this.offresSubj.next(this.offres);
    //console.log(this.offresSubj.getValue());
  } 
  public offresSubj = new BehaviorSubject<any[]>(null);

  _env = environment;

  offres = 
  [ 
    {
      type: 'B Baz',
      title: "B BAZ",
      text: `C’est un contrat qui vous permet de fixer une base tout en conservant la possibilité de fixer votre cours matif sur une échéance préalablement choisie. B.BAZ intègre la dimension de volatilité des marchés à terme pour des productions standardisées`,
    },
    { 
      type: 'B Beuz', 
      title: "B BEUZ",
      text: `C’est un contrat à prix ferme et définitif qui laisse à Bz Collecte la responsabilité de gérer toutes les composantes du prix (Matif, transport, échéance...)`,
    },
    {
      type: 'B Osmoz', 
      title: "B OSMOZ",
      text: `C’est un contrat adossé à des demandes clients spécifiques inscrites dans la durée avec un cahier des charges et un nombre d’hectares préalablement définis avec vous au moment de l’assolement. La prime est fixée au moment de la conclusion du contrat. Le cours du matif sera fixé ultérieurement par vos soins, selon les modalités annuellement définies par Bz collecte. C’est par excellence le contrat des protéagineux.`
    },
    { 
      type: "B Zap", 
      title: "B ZAP",
      text: `C’est un contrat adossé à des demandes clients spécifiques ponctuelles avec un cahier des charges défini et une quantité globale et limitée dans le temps.  Ce contrat valorise la prime que le client est prêt à rémunérer à un moment donné pour satisfaire sa demande. Le prix est ferme et définitif à conclusion du contrat. Le risque pour vous est un risque production dans la mesure où les demandes clients ne sont pas  garanties d’une campagne sur l’autre.`
    },
    { 
      type: "B Zenith",
      title: "B ZENITH",
      text: `Vous donnez à BZ Collecte un prix d’objectif sur le marché physique pour une durée donnée. Dès que votre prix d’objectif est atteint, un contrat est automatiquement généré. Au-delà de cette durée, si le prix n’a pas été atteint, votre ordre devient caduque. Vous avez néanmoins la possibilité de renouveler votre ordre.`
    },
    {
      type: "B Zen", 
      title: "B ZEN",
      text:  `Vous engagez une quantité pour une période plus ou moins longue. Arrivé au terme de votre période d’observation, votre prix sera le résultat de la moyenne matif de l’échéance considérée (cours de clôture quotidien) sur la période en question moins votre base.  Ce contrat est utilisable pour une majorité de productions quel que soit leur type.`
    },
    {
      type: "B Futur", 
      title: "B FUTUR",
      text:  `A travers ce contrat, vous optez pour une échéance matif donnée et vous vous laissez la possibilité de fixer la prime portuaire à Rouen ultérieurement pendant la période concernée. B.Futur est facilementutilisable pour des productions standardisées avec des qualités normées.`
    },
    {
      type: "B Symbioz", 
      title: "B SYMBIOZ",
      text:  `Majoritairement souscrit avant la moisson, il vous permet de répondre à des besoins de trésorerie et d’enlèvements logistiques à la récolte tout en différant votre choix de contractualisation.Lors de la première étape, vous vous engagez avec Bz collecte uniquement sur une production et une quantité déterminées. Dans un second temps, vous choisissez votre type de contractualisation.`
    },
    {
      title: "Optimiz et Securiz",
      type: "bPluZ", 
      text:  `<p class="mb-2">Les huit outils de la gamme commerciale peuvent être associés à des contrats de couverture de risques.</p>
              <p class="my-4 text-center main-subtitle bplusz text-darkgreen">B + Z = BZ</p>  
              <p class="mt-2">En effet, un outil commercial (B) couplé à un contrat de couverture (Z) permettent soit de capter une hausse des marchés, soit de se protéger d’une baisse. Il existe deux produits de couverture : Optimiz contrat de couverture à la hausse et Securiz contrat de couverture à la baisse. Leur souscription est obligatoirement liée à un contrat commercial de marchandise conclu avec Bz collecte.</p>`
    }    
  ]

  showOffre(type:string){
    //console.log(type);
    let offresCurrValue = this.offresSubj.getValue();

    const dex = offresCurrValue.findIndex(elem => elem.type == type);

    //remplace l'index 0 du tableau par l'index séléctionné
    offresCurrValue[0] = [offresCurrValue[dex],offresCurrValue[dex] = offresCurrValue[0]][0];

    this.offresSubj.next(offresCurrValue);
    //console.log(this.offresSubj.getValue())

  }
}
