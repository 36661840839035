import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Subject, BehaviorSubject, Subscription } from 'rxjs';
import { Produit } from '../models/produit';

import { environment } from "../../environments/environment"; 
import { LoaderService } from './loader.service';
import { FilterService } from './filter.service';

@Injectable({
  providedIn: 'root'
})
export class DashboardService {

  constructor(
    private http: HttpClient,
    private _loader: LoaderService,
    private _filtServ: FilterService) { }

  getFilter: Subscription;
  getFilterReg: Subscription;
  getRegs: Subscription;
  
  getFilterLiv: Subscription;
  getLivs:Subscription;

  dummyDatas: Produit[] = [
    {
      produit: "Blé",
      fermeNonDispo: 15,
      fermeDispo: 30,
      auDepot: 38,
      pathIcon: "/assets/icons/produits/ble.svg",
      vendu: 50,
      moyenne:174,
      engagement:15,
      infomarche: { 
        produit: "Blé",
        prixParTonne: 150,
        indice: "+14.50"
      }
    },
    {
      produit: "Colza",
      fermeNonDispo: 100,
      fermeDispo: 30,
      auDepot: 50,
      pathIcon: "/assets/icons/produits/colza.svg",
      vendu: 50,
      moyenne:174,
      engagement:75,
      infomarche: { 
        produit: "Colza",
        prixParTonne: 200,
        indice: "-1.50"
      }
    },
    {
      produit: "Maïs",
      fermeNonDispo: 60,
      fermeDispo: 50,
      auDepot: 98,
      pathIcon: "/assets/icons/produits/mais.svg",
      vendu: 50,
      moyenne:174,
      engagement:65,
      infomarche: { 
        produit: "Maïs",
        prixParTonne: 350,
        indice: "0"
      }
    }
  ];

  user = {
    nom: "Maxime",
    aSigner: 2,
    campagnes: ["2019","2018","2017"],
    structures: ["Saint-André-de-l'Eure","Pacy-sur-Eure","Evreux","Bernay","Nice","Anecy","Menton"],
    produits: this.dummyDatas
  }
  
  infomarche;
  mvmnts= [];
  public all_set:number;
  public allSetSubject = new BehaviorSubject<number>(0);
  private maCommerce = new Subject<any>();
  private mesLivraisons = new Subject<any>();
  private mesReglements = new Subject<any>();
  private mesStocks = new Subject<any>();
  private mesStocksMvmnts = new Subject<any>();
  private mesStocksFerme = new Subject<any>();

  private _env = environment;

  getMesStocksSub: Subscription;

  //// Stocks
  private stocks: Produit[] = [];
  private dummyStocks = new Subject<Produit[]>();

  //Pourcentages visuels barres mesStocks
  setPercentbars(mesStocksValue){
    // //console.log(widthGlobalBar)
    //console.log(mesStocksValue)
    mesStocksValue.map(x => {
      const fnd = x.non_disponible;
      const fd = x.disponible;
      const ad = x.depot;

      const totalTon = (fnd + fd + ad);
      const fndPer = (100*fnd)/totalTon;
      const fdPer = (100*fd)/totalTon
      const adPer = (100*ad)/totalTon
      
      // //console.log("totalTon => ",totalTon);
      // //console.log("fndPer=> ",fndPer)
      // //console.log("fdPer=> ",fdPer)
      // //console.log("adPer=> ", adPer)
      // //console.log("------------------")

      x['tonnePercent'] = {
        "fndPer":fndPer,
        "fdPer":fdPer,
        "adPer":adPer,
        "totalTon": totalTon
      }
    })
    return mesStocksValue; 
  }
  getDatas(){
    /* //console.log(this.dummyStocks); */
    // return this.dummyStocks.asObservable();
    // this.setPercentbars();
    return this.dummyDatas;
  }

  setMesStocks(){
    this.http.get(this._env.BZ_API_BASE_URL + "messtocks/getstocks/format/json",{withCredentials:true}).subscribe(
      stocks =>{
        //console.log(stocks)
        const modifiedStocks = this.setPercentbars(stocks['MesStocks']);
        //console.log(modifiedStocks);
        this.mesStocks.next(modifiedStocks);  
      }
    )
  }

  getMesStocks(){
    return this.mesStocks.asObservable();
  }

  setMesStocksMouvements(produit: string){
    this.http.get(this._env.BZ_API_BASE_URL + "meslivraisons/getlivraisons/format/json",{withCredentials:true}).subscribe( 
      stocks =>{
        //console.log()
        stocks['MESLIVRAISONS'][0]['cult'].map(
          x =>{
            if(x['cult.culture'] == produit){
              //console.log(x.effectuees);
              x.effectuees.map(
                x => {
                  this.mvmnts.push(x.mouvts);
                  this.mesStocksMvmnts.next(...this.mvmnts); 
                }
              )
            }
          }
        )
      }
    )
  }
  getMesStocksMouvements(){
    return this.mesStocksMvmnts.asObservable();
  }

  setMesStocksFerme(produit: string){
    this.http.get(this._env.BZ_API_BASE_URL + "meslivraisons/getlivraisons/format/json",{withCredentials:true}).subscribe( 
      stocks =>{
        //console.log(stocks)
        // stocks['MESLIVRAISONS'][0]['cult'].map(
        //   x =>{
        //     if(x['cult.culture'] == produit){
        //       //console.log(x.effectuees);
        //       x.effectuees.map(
        //         x => {
        //           this.mvmnts.push(x.mouvts);
        //           this.mesStocksFerme.next(...this.mvmnts); 
        //           //console.log(this.mesStocksFerme)
        //         }
        //       )
        //     }
        //   }
        // )
        // this.mesStocksFerme.next(stocks)
      }
    )
    // this.getFilter = this._filtServ.getFilter().subscribe(
    //   filterConf => {
    //     //console.log(filterConf)
    //     const filterData = new FormData();
    //     filterData.append("filter",JSON.stringify(filterConf));
    //     return this.http.post(this._env.BZ_API_BASE_URL + "macommercialisation/getcommercialisation/format/json",filterData,{withCredentials:true}).subscribe(
    //       commerce =>{
    //         //console.log(commerce);
    //         this.maCommerce.next(commerce);
    //       }
    //     )
    //   }
    // )
  }

  getMesStocksFerme(){
    return this.mesStocksFerme.asObservable();
  }

  setCommercialisation(){
     this.getFilter = this._filtServ.getFilter().subscribe(
      filterConf => {
        //console.log(filterConf)
        const filterData = new FormData();
        filterData.append("filter",JSON.stringify(filterConf));
        return this.http.post(this._env.BZ_API_BASE_URL + "macommercialisation/getcommercialisation/format/json",filterData,{withCredentials:true}).subscribe(
          commerce =>{
            //console.log(commerce);
            this.maCommerce.next(commerce);
            this.getFilter.unsubscribe();
          }, error => {
            this.getFilter.unsubscribe();
          }
        )
      }
    )
  }

  getCommercialisation(){
    return this.maCommerce.asObservable();
  }

  setLivraisons(){
    this.getFilterLiv = this._filtServ.getFilter().subscribe(
      filterContent => {
         const filterConf = this._filtServ.createFilterFromCheckedValues(filterContent);
        const filterData = new FormData();
        filterData.append("filter",JSON.stringify(filterConf));

        this.getFilterLiv  ? this.getFilterLiv .unsubscribe():null;
        if(filterContent){
          this.getLivs =  this.http.post(this._env.BZ_API_BASE_URL + "meslivraisons/getlivraisons/format/json",filterData,{withCredentials:true}).subscribe(
            livraisons => {
              //console.log(livraisons);
              const livs = JSON.parse(livraisons[0][""]);
              const livsAdapt = livs["MESLIVRAISONS"];
              //console.log(livsAdapt);
              this.mesLivraisons.next(livsAdapt);
              this.getLivs.unsubscribe();
            }
          )
        }
      }
    )
  }

  getLivraisons(){
    return this.mesLivraisons.asObservable();
  }

  setReglements(){
    this.getFilterReg = this._filtServ.getFilter().subscribe(
      filterContent => {
        const filterConf = this._filtServ.createFilterFromCheckedValues(filterContent);
        const filterData = new FormData();
        filterData.append("filter",JSON.stringify(filterConf));
        this.getFilterReg ?  this.getFilterReg.unsubscribe():null;

        if(filterContent){
          this.getRegs =  this.http.post(this._env.BZ_API_BASE_URL + "mesreglements/getreglements/format/json",filterData,{withCredentials:true}).subscribe(
            reglements => {
              const reg = JSON.parse(reglements[0][""]);
              const regAdapt = reg["MESREGLEMENTS"];
              //console.log(regAdapt);
              this.mesReglements.next(regAdapt);
              this.getRegs.unsubscribe();
            }
          )
        }
      }
    )
  }

  getReglements(){
    return this.mesReglements.asObservable();
  }

  getInfoMarche(){
    // this.http.get("http://localhost:83/transaction/matif/format/json")
    //   .subscribe(response=>{
    //     //console.log(response);
    //   })
    // this.http
    //   .get(this.env.API_BASE_URL + "/api/admin/toiles/findToileById/" + toileId)
    //   .subscribe(responseToile => {
    //     //console.log(responseToile);
    //     this.toileToEdit = responseToile["toile"];
    //     this.toileSubject.next(this.toileToEdit);
    //     const stringify = JSON.stringify(responseToile["toile"]["imgInfos"]);
    //     this.BufferServ.setPersistedImgDir(JSON.parse(stringify));
    //     // this.persistedImgsDir = JSON.parse(stringify);
    //     // this.persistedImgsSubject.next([...this.persistedImgsDir]);
    //   });
  }

  getUser(){
    return this.user;
  }

  ngOnDestroy(){
      this.getFilter.unsubscribe();
      this.getFilterReg.unsubscribe();
      this.getLivs.unsubscribe();
      this.getRegs.unsubscribe();
  }

}
