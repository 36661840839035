import { Component, OnInit, ViewChild } from '@angular/core';
import { FilterService } from 'src/app/services/filter.service';
import { ContratsService } from 'src/app/services/contrats.service';
import { Subscription } from 'rxjs';
import { FormGroup, FormControl, NgForm, Validators } from '@angular/forms';
import { ModalDirective } from 'angular-bootstrap-md';
import { debounceTime, share } from 'rxjs/operators';
import { ToastrStateService } from 'src/app/services/toastr-state.service';
import { ToastrService } from 'ngx-toastr';
import { CookieService } from 'ngx-cookie-service';
import { UserService } from 'src/app/services/user.service';
import { AuthService } from 'src/app/services/auth.service';
import { Router } from '@angular/router';
import {IdFromCultNamePipe} from '../../pipes/id-from-cult-name.pipe';

@Component({
  selector: 'app-mes-contrats-indexes',
  templateUrl: './mes-contrats-indexes.component.html',
  styleUrls: ['./mes-contrats-indexes.component.scss']
})
export class MesContratsIndexesComponent implements OnInit {

  getContratsIndexes: Subscription;
  blockSub: Subscription;
  listenerSub: Subscription;
  getCamp: Subscription;

  verifyUserRole: Subscription;

  contratsIndexes;

  checkedProduct;

  viewportWidth;

  noContratsToshow;

  p: number[] = [];

  step1: FormGroup;
  isStep1Submit;
  isSubmit;
  interval;

  blocageIdchb;
  blocageIdct;
  blocagePrixNet;
  blocageEch;
  blocageQteLimit

  selectedCamp;
  contratSelected;
  selectedPage;

  errorTonnage;

  @ViewChild('step2',null) step2Modal: ModalDirective;
  @ViewChild('frame',null) step1Modal: ModalDirective;

  constructor(
    private _filtServ: FilterService, private _contratServ: ContratsService,
    private _toaster: ToastrService, private _toasterState: ToastrStateService,
    private _cookieServ: CookieService, private _userServ: UserService,
    private _authServ: AuthService, private _router: Router) { 
    this.listenerSub = this._filtServ.filterChangeEmitter.subscribe(newFilter => {
      console.log("listener commercialisation");
      this._contratServ.setMesContratsIndexes()
    })
  }

  ngOnInit() {
    window.scrollTo(0, 0)
    if(this._userServ.userRole.getValue() == null){
      this.verifyUserRole = this._userServ.getUserDetail().subscribe(
        response => {

          this._userServ.userRole.next(response['compte']['structure'][0]['profil']);
          if(this._cookieServ.get('userProfile') != this._userServ.userRole.getValue()){
            this._toaster.error("Votre rôle ne correspond pas à celui enregistré dans notre base de données. Retour Login");
            setTimeout(() => {
              this._authServ.logOut();
              this._router.navigate(['']);              
            }, 2000);
          }
        },
        error => {
          console.log(error)
          this.verifyUserRole ? this.verifyUserRole.unsubscribe() : null;
        }
      )
    } else {
      if(this._cookieServ.get('userProfile') != this._userServ.userRole.getValue()){
        this._toaster.error("Votre rôle ne correspond pas à celui enregistré dans notre base de données. Retour Login");
        setTimeout(() => {
          this._authServ.logOut();
          this._router.navigate(['']);              
        }, 2000);
      }
    }

    this.step1 = new FormGroup({
      qteha: new FormControl("",[Validators.required]),
      idchb: new FormControl("",[]),
      pwd: new FormControl("",[Validators.required]),
      idct: new FormControl("",[]),
      prixNet: new FormControl("",[]),
      prixEch: new FormControl("",[]),
    });


    this._contratServ.setMesContratsIndexes();

    this.getCamp = this._filtServ.selectedCamp.subscribe(
      year => {
        console.log(year)
        this.selectedCamp  = year;
      }
      )
    this.getContratsIndexes = this._contratServ.getMesContratsIndexés().subscribe(
      contrats => {
        console.log(contrats);
        //On teste ittère les contrats et s'ils n'ont pas d' idchb, on rajoute la clé avec une valeur à 0 => plus pratique pour la ps en backend
        if(contrats && contrats['Mescontratsindexés']){
          this.noContratsToshow = null;
          contrats['Mescontratsindexés'].forEach(element => {
            element.typecontrat.forEach(type => {
              type.contrat.forEach(cont => {
                if(!cont['contrat.idchb']){
                  cont['contrat.idchb'] = 0;
                }
              })
            })
          });
          this.contratsIndexes = contrats;

          //Si le sujet n'est pas vide, => vient de la page ma commercialisation
          if(this._contratServ.contratIndexesFromCommercialisation.getValue()){
            console.log("log contrats indx valeur fromCommercialisation != null",this.contratsIndexes['Mescontratsindexés'])
            console.log("valeurs séléctionné depuis commercialisation", this._contratServ.contratIndexesFromCommercialisation.getValue())

            //On récupère les infos du sujets contratIndexesFromCommercialisation (contrat.id et culture)
            const fromCom = this._contratServ.contratIndexesFromCommercialisation.getValue();

            //On pipe le nom de culture pour récupérer le code culture auquel on concatène la campagne
            let pipe = new IdFromCultNamePipe();
            const contratId = fromCom.contratId;
            const ccFunc =  ()=>{return pipe.transform(fromCom.culture)};
            const codeCulture = this.selectedCamp + ccFunc()

            console.log(codeCulture);
            console.log(contratId);
            //On recherche l'index du premier tableau ['Mescontratsindexés']
            const dex = this.contratsIndexes['Mescontratsindexés'].findIndex(cat =>  cat.culture == codeCulture );
            this.contratSelected = contratId;
            console.log(dex)
            // const toShow = this.contratsIndexes['Mescontratsindexés'][0];
            this.setSelectedProduct(this.contratsIndexes['Mescontratsindexés'][dex])
          } else {
            this.setSelectedProduct(this.contratsIndexes['Mescontratsindexés'][0])
          }
        } else if(contrats && !contrats['Mescontratsindexés']) {
          this.noContratsToshow = "vous n'avez pas de contrats indexés";
          console.log(this.noContratsToshow)
          this.contratsIndexes = null;
          this.checkedProduct = null;
        }
      }
    )

    this.interval = setInterval(()=>{
      if(!this.blocageIdchb && !this.blocageIdct && !this.blocagePrixNet && !this.blocageEch && !this.blocageQteLimit){
        const checkedProd = this.checkedProduct;
        this._contratServ.setMesContratsIndexes()
        this.getContratsIndexes = this._contratServ.getMesContratsIndexés().subscribe(
          contrats => {
            console.log(contrats);
            //On teste ittère les contrats et s'ils n'ont pas d' idchb, on rajoute la clé avec une valeur à 0 => plus pratique pour la ps en backend
            if(contrats && contrats['Mescontratsindexés']){
              this.noContratsToshow = null;
              contrats['Mescontratsindexés'].forEach(element => {
                element.typecontrat.forEach(type => {
                  type.contrat.forEach(cont => {
                    if(!cont['contrat.idchb']){
                      cont['contrat.idchb'] = 0;
                    }
                  })
                })
              });
              this.contratsIndexes = contrats;
              const dexCultureSelected = this.contratsIndexes['Mescontratsindexés'].findIndex(ind => ind.culture == checkedProd.culture);
              this.setSelectedProduct(this.contratsIndexes['Mescontratsindexés'][dexCultureSelected])
            } else if(contrats && !contrats['Mescontratsindexés']) {
              this.noContratsToshow = "vous n'avez pas de contrats indexés";
              console.log(this.noContratsToshow)
              this.contratsIndexes = null;
              this.checkedProduct = null;
            }
          }
        )
      }
    }, 30000);
  }

  setSelectedProduct(cultureObj){
    this.checkedProduct = cultureObj;
    this.p = [];
  }

  setBlocageIdchb(idchb,idct,net,echeance,qteMaxLimit){
    this.blocageIdchb = idchb;
    this.blocageIdct = idct;
    this.blocagePrixNet = net;
    this.blocageEch = echeance;
    this.blocageQteLimit = qteMaxLimit;
    console.log(idct)
  }

  validateStep1(){
    this.isStep1Submit = true;
    console.log(this.blocageQteLimit)
    if(this.step1.controls.qteha.value > this.blocageQteLimit ) this._toaster.error("Votre quantité à bloquer dépasse la quantité totale du contrat")
    if(this.step1.controls.qteha.value > this.blocageQteLimit ) return false;

    
    this.step1.patchValue({ idchb: this.blocageIdchb }); 
    this.step1.get("idchb").updateValueAndValidity();
    this.step1.patchValue({ idct: this.blocageIdct }); 
    this.step1.get("idct").updateValueAndValidity();
    this.step1.patchValue({ prixNet: this.blocagePrixNet }); 
    this.step1.get("prixNet").updateValueAndValidity();
    this.step1.patchValue({ prixEch: this.blocageEch }); 
    this.step1.get("prixEch").updateValueAndValidity();
    console.log(this.step1.value)
    this.step2Modal.show();
  }

  blockContrat(){
    this.isSubmit = true;
    console.log("je valide")

    this.verifyUserRole = this._userServ.getUserDetail().subscribe(
      response => {

        this._userServ.userRole.next(response['compte']['structure'][0]['profil']);
        if(this._cookieServ.get('userProfile') != this._userServ.userRole.getValue()){
          this._toaster.error("Votre rôle ne correspond pas à celui enregistré dans notre base de données. Retour Login");
          setTimeout(() => {
            this._authServ.logOut();
            this._router.navigate(['']);  
          }, 2000);
          return;            
        }
      },
      error => {
        console.log(error)
        this.verifyUserRole ? this.verifyUserRole.unsubscribe() : null;
      }
    )

    if(!this.step1.valid && this.step1.touched){
      return;

    } else {
      this.blockSub = this._contratServ.blockContrat(this.step1.value).subscribe(
        response => {
          console.log(response);
          if(!response['success']){
            this.resetForm();
            this.step1Modal.hide();
            this.step2Modal.hide();

            this._toaster.error(response['error'])
          } else {  
            this.resetForm();
            this.step1Modal.hide();
            this.step2Modal.hide();
   
            this._toaster.success(response['success'])
          }
          this.blockSub.unsubscribe();
        }, error => {
          console.log(error)
          this.blockSub.unsubscribe()
        }
      )
    }

    // this.step1.reset()

  }

  resetForm(){
    this.step1.reset();
    this.isStep1Submit = false;
    this.isSubmit = false
    this.blocageIdchb = null;
    this.blocageIdct = null;
    this.blocagePrixNet = null;
    this.blocageEch = null;
    this.blocageQteLimit = null;
  }

  ngDoCheck(){
    this.viewportWidth = window.innerWidth;
  }

  ngOnDestroy(){
    this.blockSub ? this.blockSub.unsubscribe():null;
    this.getContratsIndexes ? this.getContratsIndexes.unsubscribe():null; 
    this.listenerSub ? this.listenerSub.unsubscribe():null; 
    this.getCamp ? this.getCamp.unsubscribe():null; 
    this.verifyUserRole ? this.verifyUserRole.unsubscribe() : null;
    if(this.interval){
      clearInterval(this.interval)
    }
  }

}
