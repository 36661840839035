import { Injectable } from '@angular/core';
import { FilterService } from './filter.service';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Subject, Subscription } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DownloadService {

  constructor(private _http: HttpClient, private _filtServ: FilterService) { }

  _env = environment;

  dlDocuments: Subscription;
  mvmntFilename = new Subject();

  downloadDocument(id,TypeDoc){

    const data = new FormData();
    data.append("id",id);
    data.append("tdoc",TypeDoc); 

    return this._http.post(this._env.BZ_API_BASE_URL + "telechargement/index",data,{withCredentials:true,responseType: 'blob' as 'json'});
  }

  downloadMvmntStocksDetail(ccsdc,cdscs){
    return;
  }
  getMvmntFileName(){
    return this.mvmntFilename.asObservable();
  }
}
