import { Component, OnInit } from '@angular/core';
import * as Highcharts from 'highcharts';
import { HttpClient } from '@angular/common/http';
import { StockChart } from 'angular-highcharts';
import { interval, Subscription } from 'rxjs';
import { InfomarchesService } from 'src/app/services/infomarches.service';

declare var require: any;
let Boost = require('highcharts/modules/boost');
let noData = require('highcharts/modules/no-data-to-display');
let More = require('highcharts/highcharts-more');

Boost(Highcharts);
noData(Highcharts);
More(Highcharts);
noData(Highcharts);

@Component({
  selector: 'app-graph-marche',
  templateUrl: './graph-marche.component.html',
  styleUrls: ['./graph-marche.component.scss']
})
export class GraphMarcheComponent implements OnInit {

  constructor(private http: HttpClient, private _infoServ: InfomarchesService) {
    Highcharts.setOptions({
      lang: {
        months: [
          'Janvier', 'Février', 'Mars', 'Avril',
          'Mai', 'Juin', 'Juillet', 'Août',
          'Septembre', 'Octobre', 'Novembre', 'Décembre'
        ],
        weekdays: [
          'Dimanche', 'Lundi', 'Mardi', 'Mercredi',
          'Jeudi', 'Vendredi', 'Samedi'
        ],
        shortMonths : [
          'Janv', 'Fev', 'Mars', 'Avr',
          'Mai', 'Juin', 'Juil', 'Août',
          'Sept', 'Oct', 'Nov', 'Dec'
        ],
        rangeSelectorFrom : 'De',
        rangeSelectorTo : 'A'
    },
    plotOptions: {
      series: {
          color: 'rgb(170, 217, 85)',
      }
    },
    navigator: {
      series: {
        lineColor: 'rgb(170, 217, 85)',
      },
      maskFill: 'rgba(240, 245, 102, 0.3)'
    },
    rangeSelector:{
      buttons: [
        {
            type: 'month',
            count: 1,
            text: '1m'
        }, 
        {
            type: 'month',
            count: 3,
            text: '3m'
        }, 
        {
            type: 'month',
            count: 6,
            text: '6m'
        }, 
        {
            type: 'ytd',
            text: 'YTD'
        }, 
        {
            type: 'year',
            count: 1,
            text: '1an'
        }, 
        {
            type: 'all',
            text: 'Tout'
        }
      ],
      buttonTheme:{
        style: {
          color: 'gray',
          fontWeight: 'bold',
          fill: '#ECECEC'
        },
        states: {
          hover: {
          },
          select: {
              fill: 'rgb(170, 217, 85)',
              style: {
                  color: 'white'
              }
          }
          // disabled: { ... }
      }
      }
    }
  });
  this.getCourbesTitle = this._infoServ.getInfoCourbesTitle().subscribe(
    title => {
      //console.log("title const ====>",title);
      this.courbesTitle = title;
    }
  )
    // //console.log(this.CourbesTitle )
    // if(title){
      
    // }
    
  }
  stock: StockChart;
  getInfoCourbes:Subscription;
  
  infoCourbesHighC;
  getCourbesTitle: Subscription;
  courbesTitle:string;

  ngOnInit() {
    this.getInfoCourbes = this._infoServ.getInfoCourbesMatif().subscribe(
      infosCharts => {
        // //console.log(infosCharts);
        this.infoCourbesHighC = infosCharts;
        if(this.infoCourbesHighC){
          this.stock = new StockChart({
            chart: {
              margin: 0
            },
            yAxis: {
              opposite:false,
              tickInterval: 5
            },
            rangeSelector: {
              selected: 1
            },
            title: {
              text: this.courbesTitle
            },
            series: [{
              colors: ['#058DC7'],
              type: undefined,
              tooltip: {
                valueDecimals: 2
              },
              name: this.courbesTitle,
              data: this.infoCourbesHighC
            }]
          });
        }
      }
    )
    
  }

  ngOnDestroy(){
    if(this.getInfoCourbes){
      this.getInfoCourbes.unsubscribe()
    }
    if(this.getCourbesTitle){
      this.getCourbesTitle.unsubscribe();
    }
  }


}
