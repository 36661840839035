import { Component, OnInit } from '@angular/core';
import { FilterService } from 'src/app/services/filter.service';
import { Location } from '@angular/common';

@Component({
  selector: 'app-filter-nav',
  templateUrl: './filter-nav.component.html'
})

export class FilterNavComponent implements OnInit {

  constructor(private _filtServ: FilterService,private _loc: Location) { 
    this.pageUrl = this._loc.path();
  }
  filterState:string;
  pageUrl:string;

  ngOnInit() {
    this._filtServ.getFilterState().subscribe(
      state => {
        this.filterState = state;
      }
    );
    if(this.pageUrl == "/mes-documents"){
      this._filtServ.changeFilterState("closed")
    }
  }

  expandFilter(){
    this._filtServ.changeFilterState(this.filterState);
  }

  ngDoCheck(){
    
  }

}
