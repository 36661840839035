import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AideService {

  
  descriptions = [ 
    {
      state: "closed",
      category: "modifMdp",
      title: "J’ai perdu mon mot de passe, comment le modifier ?",
      text: "Dans la partie “Mon compte”, vous pouvez cliquer sur 'modification de mot de passe'"
    },
    {
      state: "closed",
      category: "signContrats",
      title: "Où et comment signer mes contrats sur EBZ ?",
      text: `Les contrats à signer sont indiqués dès la page d’accueil à 2 endroits :
      Sur le menu, le nombre de contrats à signer apparaît dans une pastille rouge, au-dessus du pictogramme « Mes contrats à signer ».  Cliquez sur le pictogramme. 
      
      En bas à gauche de la page : le nombre de contrats à signer apparaît en rouge. Cliquez sur le bouton « signer ». 
      Votre mot de passe pour signer les contrats en ligne est le même que votre mot de passe de connexion. 
      Si vous n’avez pas accès à cette rubrique, contactez le service administratif de BZ Collecte.`
    },
    {
      state: "closed",
      category: "findContrats",
      title: "Je ne retrouve pas tous mes contrats dans mes documents ou dans la partie “signature”, pourquoi ?",
      text: "Si votre contrat est soldé, il n’apparaît plus dans les contrats à signer. Si vous ne retrouvez pas certains contrats dans vos documents ou dans la partie “Mes contrats à signer”, contactez le service administratif de BZ Collecte."
    },
    {
      state: "closed",
      category: "filter",
      title: "À quoi sert le filtre ?",
      text:`Concernant les campagnes, cela vous permet de voir les informations par campagne. 
      Si vous gérez plusieurs sociétés, cela vous permet de consulter les informations de façon dissociée, par société. 
      Vous pouvez également filtrer l’information par culture.`
    },
    {
      state: "closed",
      category: "printDoc",
      title: "Où retrouver et imprimer un document de type facture, contrat, etc. ?",
      text:`Vous pouvez retrouver l’ensemble de vos documents en cliquant sur le pictogramme « Documents » dans le menu en haut. Pour télécharger le document, cliquez sur la flèche verte.`
    },
    {
      state: "closed",
      category: "tiercePers",
      title: "Je souhaite donner accès à EBZ à une tierce personne, comment faire ? ",
      text: "Vous pouvez donner accès à une tierce personne avec différents profils, contactez le service administratif pour plus de renseignements."
    },
    {
      state: "closed",
      category: "nouvelleStructure",
      title: "Comment intégrer une nouvelle structure sur EBZ ?",
      text: "Si vous souhaitez intégrer une nouvelle structure sur votre EBZ, veuillez contacter le service administratif."
    },
    {
      state: "closed",
      category: "contactBZ",
      title : "Comment écrire à BZ Collecte, transmettre un document de type RIB, mandat, etc. ?",
      text: "Dans l’onglet “Messages”, vous pouvez cliquer sur “nous écrire” et ajouter une pièce jointe. "
    },
    {
      state: "closed",
      category: "mesStocksFermesND",
      title:"Dans la partie « Mes stocks », à quoi correspond le stock ferme non disponible ? ",
      text: "Cela correspond à ce qui est auto-consommé ou bien à ce qui est destiné à un autre collecteur que BZ Collecte."
    },
    {
      state: "closed",
      category: "mesStocksFD",
      title: "Dans la partie « Mes stocks », à quoi correspond le stock ferme disponible ? ",
      text: "Cela correspond à ce que vous avez en stock dans votre ferme, qui n’est pas encore vendu, selon les informations que vous avez transmises à votre commercial ou ce qu’il a estimé. N’hésitez pas à vous rapprocher de votre commercial pour affiner ces informations afin d’utiliser au mieux cet outil d’aide à la commercialisation."
    },
    {
      state: "closed",
      category: "voirDepot",
      title: "Où voir ce que j’ai en dépôt dans les silos BZ ? ",
      text: "Cela correspond à ce que vous avez en stock dans votre ferme, qui n’est pas encore vendu, selon les informations que vous avez transmises à votre commercial ou ce qu’il a estimé. N’hésitez pas à vous rapprocher de votre commercial pour affiner ces informations afin d’utiliser au mieux cet outil d’aide à la commercialisation."
    },
    {
      state: "closed",
      category: "voirDepot",
      title: "Où voir ce que j’ai en dépôt dans les silos BZ ? ",
      text: "Dans la partie « Mes stocks », vous retrouverez l’ensemble de vos dépôts, segmentés par culture ainsi que les résultats d’analyse de chaque livraison. Vous pouvez télécharger les bon d’apports en cliquant sur « télécharger données ». "
    },
    {
      state: "closed",
      category: "analyses",
      title: "J’ai transmis un échantillon de grain pour le faire analyser par BZ, où obtenir le résultat de l’analyse ?",
      text: "Vous trouverez les résultats de vos échantillons en cliquant sur le bouton « Résultats d’analyse », en haut à droite de la page “Mes stocks”."
    },
    {
      state: "closed",
      category: "offres",
      title: "Où découvrir l’ensemble des contrats proposés par BZ Collecte ?",
      text: "La gamme commerciale de BZ Collecte est présentée dans l’onglet « OFFRES BZ ». "
    },
    {
      state: "closed",
      category: "contratsEngagés",
      title: "Où voir l’ensemble de mes contrats engagés avec BZ Collecte ? ",
      text: `Dans la partie « Ma commercialisation », vous retrouverez l’ensemble de vos engagements avec BZ Collecte. Ceux-ci sont segmentés par culture puis par type de contrat. Vous pouvez télécharger les contrats dans cette partie en cliquant sur « Télécharger données ». 
      Vous pouvez également les retrouver dans votre synthèse.`
    },
    {
      state: "closed",
      category: "calculMoyenne",
      title: "Comment est calculée la moyenne de prix de ma commercialisation ?",
      text: `Votre moyenne est calculée sur tous les contrats dont le prix est définitivement arrêté.`
    },
    {
      state: "closed",
      category: "commercialisationOnLine",
      title: "Est-il possible de commercialiser en ligne sur EBZ ?",
      text: `La commercialisation avec BZ Collecte se fait par téléphone avec votre commercial. Seule la fixation matif de contrats indexés est possible sur EBZ.`
    },
    {
      state: "closed",
      category: "valeurMatif",
      title: "Est-il possible d’arrêter la valeur matif de mes contrats indexés sur EBZ ?",
      text: `Oui, pour cela, il faut cliquer sur le pictogramme « Mes contrats indexés » du menu. Vous trouvez ici l’ensemble des contrats indexés dont la valeur matif n’a pas encore été fixée. Pour fixer cette valeur, cliquez sur le bouton « bloquez ». Vous devez renseigner votre mot de passe pour bloquer définitivement votre valeur matif.`
    },
    {
      state: "closed",
      category: "livraisonsAffectées",
      title: "Où retrouver les livraisons affectées à mes contrats ? ",
      text: `Soit dans la partie “Synthèse”, dans l’onglet “tableaux” 
      Soit dans la partie “Mes livraisons et enlèvements effectués”, en cliquant sur le bouton “téléchargement”`
    },
    {
      state: "closed",
      category: "mouvements",
      title: "Où trouver mes mouvements ?",
      text: `Soit dans la partie “Mes livraisons et enlèvements” s’ils sont affectés à 1 contrat, soit dans “Mes stocks” s’ils ne sont pas commercialisés.`
    },
    {
      state: "closed",
      category: "campagnes",
      title: "Où consulter l’évolution de ma commercialisation sur plusieurs campagnes ? ",
      text: `Vous pouvez voir la synthèse des 2 précédentes campagnes et de la campagne en cours dans la partie “Ma synthèse”, en cliquant sur le pictogramme du menu.`
    },

  ];

  public AideContent = new BehaviorSubject(this.descriptions);
  


  constructor() { }

  changeState(category){
    let currentDescriptions = this.AideContent.getValue();
    const index = currentDescriptions.findIndex(obj => obj.category == category);
    currentDescriptions[index].state == "closed" ? currentDescriptions[index].state = "open" : currentDescriptions[index].state = "closed";

    this.AideContent.next(currentDescriptions);
  }

  search(text){
    let currentDescriptions = this.AideContent.getValue();
    const result = currentDescriptions.filter(obj => obj.text.toLowerCase().includes(text.toLowerCase()) || obj.title.toLowerCase().includes(text.toLowerCase()));
    // //console.log(text)
    // //console.log(result)
    return result;
  }
}
