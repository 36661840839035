import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { CookieService } from 'ngx-cookie-service';
import { debounceTime, share, first, map, filter, switchMap } from 'rxjs/operators';
import { Subject, Subscription, Observable, BehaviorSubject, forkJoin, pipe, UnsubscriptionError } from 'rxjs';
import { FilterService } from './filter.service';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { UserService } from 'src/app/services/user.service';

@Injectable({
  providedIn: 'root'
})
export class MonSaiService {

  private StructureSelectionnee = new BehaviorSubject<any>(null);
  public campagneActive = new BehaviorSubject<any>(null);
  private listeQuestionSai = new BehaviorSubject<any>(null);  
   listeAxesSai;  
  private paramsList = new BehaviorSubject<any>(null);
  _env=environment;

  constructor(private _http: HttpClient,private _cookieServ: CookieService,private _filtServ: FilterService, private _toaster: ToastrService,private router: Router,private _userServ: UserService) {
    //console.log("constructeur");
    this.setCampagneActive();
    this.setStaticsData();
    
  }

  setCampagneActive(){
     this._http.post(this._env.BZ_API_BASE_URL + "monsai/getcampactive/format/json", null,{withCredentials:true})
        .subscribe(res => { 
                    const resultat = JSON.parse(res[0][""]);
                    this.campagneActive.next(resultat.params[0]['campActive']);

        //send success response
        }, (err) => {
        //send error response
      });
        
  }

  deleteSignature(ObjTiers){

    const data = new FormData();
    data.append("params", JSON.stringify(ObjTiers));
    this._http.post(this._env.BZ_API_BASE_URL + "monsai/deletesignature/format/json", data,{withCredentials:true})
    .subscribe(res => { 
                const resultat = JSON.parse(res[0][""]);
                console.log(resultat);
    //send success response
    }, (err) => {
    //send error response
  });    
  }


  setStructureSelectionnee(structure){
    console.log('passage dans setStructure selectionee de mon-sai service bbbbb ');
    this.setStaticsData(structure)
    this.StructureSelectionnee.next(structure)
     
  }

  getStructureSelectionnee(){
    return this.StructureSelectionnee.asObservable();
  }

  mergeDoc(clause){
    console.log("ici")
    return this.getStaticsData().pipe(map(para=>{
      if(para){
        console.log(clause)
        var data = new FormData()
        data.append("params",JSON.stringify(JSON.parse(para[0][""]).params[0]));
        data.append("clause", JSON.stringify(clause));
        console.log(JSON.parse(para[0][""]).params[0])
        return this._http.post(this._env.BZ_API_BASE_URL + "monsai/saveclausepdf/format/json", data,{withCredentials:true})  
      }
      
    }))
          
  }

  setQuestionSai(){
    
    if(!this.getStaticsData()){
      this.setStaticsData()
    }

    var subscribe_question = this.getStaticsData().subscribe(p => {
      if(p){
         const d = new FormData();
          d.append("params", JSON.stringify(JSON.parse(p[0][""]).params[0]));
          this._http.post(this._env.BZ_API_BASE_URL + "monsai/getsimusai/format/json", d,{withCredentials:true}).subscribe(
              questionnaire =>{
                //console.log(JSON.parse(questionnaire[0][""]))
                this.listeQuestionSai.next(JSON.parse(questionnaire[0][""]));
                
              }
             )
          //subscribe_question ? subscribe_question.unsubscribe():null;
          
      }})
     
  }

async getAxeAmelioration() {
  const p = await this.getStaticsData().toPromise();
  const d = new FormData();
  d.append("params", JSON.stringify(JSON.parse(p[0][""]).params[0]));
  const axe = await this._http.post(this._env.BZ_API_BASE_URL + "monsai/getaxesamelioration/format/json", d, { withCredentials: true })
    .pipe(map(response => JSON.parse(response[0][""])))
    .toPromise();
  this.listeAxesSai = axe;
  return this.listeAxesSai;
}

  saveQuestionnaireSai(thematique, questionnaireFini){
    var theme = {
      Tbl_LabelisationQuestionnaire : thematique.Tbl_LabelisationQuestionnaire,
      id_ResLabeQues : thematique.id_ResLabeQues,
      questionnaireFini : questionnaireFini
    }
    var subscribe_question = this.getStaticsData().subscribe(res => {
      let p = JSON.parse(res[0][""]).params[0]
      console.log(p)
      p.Tiers.questionnaire_complet = questionnaireFini
      const d = new FormData();
      d.append("params", JSON.stringify(p));
      d.append("thematique", JSON.stringify(theme));
      this._http.post(this._env.BZ_API_BASE_URL + "monsai/savequestion/format/json", d,{withCredentials:true}).subscribe(
          num =>{
            
          }
         )
      subscribe_question?subscribe_question.unsubscribe():null;
      })
  }


  getQuestionSai(){
    return this.listeQuestionSai.asObservable();
  }

  setStaticsData(struc?){
  /*  var userInfos = this._userServ.getUserConnected();
    const filterData = new FormData();
    const name_user = userInfos.userName.replace("+"," ").split(" ")
    filterData.append("prenom", name_user[0]);
    filterData.append("nom", name_user[1]);
    filterData.append("mail", userInfos.userMail); 
    var structure = typeof struc === 'undefined' ? null:struc;
    //si on an'a pas recu de parametre alors recherche dans la liste des structure selectionnée
    if (!structure){
      var subscribestruc=this.getStructureSelectionnee().subscribe(
          struc =>{
            if(struc){
              structure = struc
              subscribestruc ? subscribestruc.unsubscribe():null;
            }
            
          }
        )
    }
    //console.log(structure)
    filterData.append("tiers", structure)
    

    this.paramsList = new BehaviorSubject<any>(null);
     this._http.post(this._env.BZ_API_BASE_URL + "monsai/getparams/format/json", filterData,{withCredentials:true})
        .subscribe(res => {
          //console.log(res);
                          const resultat = JSON.parse(res[0][""]);
                          this.paramsList.next(null);
                        this.paramsList.next(resultat.params[0]);
                        //console.log(resultat.params[0])
        //send success response
        }, (err) => {
        //send error response
      });*/
        
  }

  getStaticsData(struc?){
    var userInfos = this._userServ.getUserConnected();
    const name_user = userInfos.userName.replace("+"," ").split(" ")
    console.log("name_user")
    console.log(name_user)
    const filterData = new FormData();
    //filterData.append("mail", userInfos.userMail);
    filterData.append("mail", userInfos.userMail);
    filterData.append("prenom", name_user[0]);
    filterData.append("nom", name_user[1]);    
    var structure = typeof struc === 'undefined' ? null:struc;
    //si on an'a pas recu de parametre alors recherche dans la liste des structure selectionnée
    if (!structure){
      var subscribestruc=this.getStructureSelectionnee().subscribe(
          struc =>{
            if(struc){
              structure = struc
              subscribestruc ? subscribestruc.unsubscribe():null;
            }
            
          }
        )
    }
    //console.log("structure")
    //console.log(structure)
    filterData.append("tiers", structure)
    

    this.paramsList = new BehaviorSubject<any>(null);
    return this._http.post(this._env.BZ_API_BASE_URL + "monsai/getparams/format/json", filterData,{withCredentials:true});
  }  

}