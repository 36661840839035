import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class AclService {

  constructor(private _http: HttpClient) { }
  _env = environment;
  setAcl(){
    return this._http.get(this._env.BZ_API_BASE_URL + "accueil/index",{withCredentials: true}).subscribe(
      acl =>{
        //console.log(acl);
      }
    )
  }

  getAcl(){

  }
}
