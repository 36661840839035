import { Component,OnInit } from '@angular/core';
import { Router, ActivatedRoute, NavigationEnd, NavigationCancel, NavigationError, NavigationStart } from '@angular/router';
import { Location } from '@angular/common';
import { AuthService } from './services/auth.service';
import { LoaderService } from './services/loader.service';
import { DashboardService } from './services/dashboard.service';
import { FilterService } from './services/filter.service';
import { CookieService } from 'ngx-cookie-service';

declare let gtag: Function;
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html'
})
export class AppComponent implements OnInit {
  title = 'BZ COLLECTE';
  screenHeight:number;
  screenWidth:number;
  pageUrl;
  isLoggedIn:boolean;
  param;

  
  constructor(
    private _router: Router,
    private _authServ: AuthService,
    private _activRoute: ActivatedRoute,
    private _loader: LoaderService,
    private _loc: Location,
    private _dashServ: DashboardService,
    private _cookieServ: CookieService){
        this._router.events.subscribe(event => {
           if(event instanceof NavigationEnd){
               gtag('config', 'xx-xxxxx-xx', 
                     {
                       'page_path': event.urlAfterRedirects,
                     }
                    );

            // if(!this._cookieServ.check('sessionId')){
            //   // this._authServ.logOut();  
            // }
         }
      })
    }
    
    
    
    ngOnInit(){
      
    // this.isLoggedIn = this._authServ.isLoggedIn();
    // if(!this.isLoggedIn){
    //   this._authServ.logOut()
    // }
    // this.screenWidth = window.innerWidth;
  }

  // ngAfterContentChecked(){
  //   // this.isLoggedIn = this._authServ.isLoggedIn(); 
  //   // //console.log(this.isLoading);
  // }
  
  // ngDoCheck(){
  //   this.isLoading = this._loader.isLoading.getValue(); 
  // }
}
