import { Component, OnInit,Pipe} from '@angular/core';
import { Location } from '@angular/common';
import { Subscription, BehaviorSubject, interval } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { FilterService } from 'src/app/services/filter.service';
import { MonGesService } from 'src/app/services/mon-ges.service';
import { Router, NavigationEnd } from '@angular/router';
import { MonSaiService } from 'src/app/services/mon-sai.service';

@Component({
  selector: 'app-labelisation-navigation',
  templateUrl: './labelisation-navigation.component.html'
})


export class LabelisationNavigationComponent implements OnInit {

  listeLabelisation;
  pageUrl;
  paramList;
  paramListSai

  constructor(private _filterServ: FilterService, private _saiServ : MonSaiService, private _loc: Location,private _gesServ: MonGesService, private _router : Router) {
    
  }

  ngOnInit() {

    window.scrollTo(0, 0);
    this.pageUrl = this._loc.path();
    var subscribe_param = this._gesServ.getStructureSelectionnee().pipe(switchMap(res => this._gesServ.getReloadedStaticsData(res))).subscribe(
        (p) =>{
          var param = JSON.parse(p[0][""]).params[0]
          if(param){            
            this.paramList = param
            console.log(param)
            subscribe_param?subscribe_param.unsubscribe():null
          }
          
        }
      )

    var subscribe_paramSai = this._saiServ.getStaticsData().subscribe(param=>{
      if(param){
        this.paramListSai = JSON.parse(param[0][""]).params[0];
        subscribe_paramSai?subscribe_paramSai.unsubscribe():null;

        console.log("this.paramListSai-navigation")
        console.log(this.paramListSai)
        console.log("this.paramListSai?.Tiers.dossier_signe")
        console.log(this.paramListSai.Tiers.dossier_signe)
        console.log("paramListSai?.Tiers.attente_signature_TblLabeTiers")
        console.log(this.paramListSai.Tiers.attente_signature_TblLabeTiers)

      }
    })

  }

  ngOnDestroy(){
    
  }

  navigueTo(path){
    this._router.navigate(path);
  }
}


