import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { MonGesService } from 'src/app/services/mon-ges.service';
import { FilterService } from 'src/app/services/filter.service';
import { MessageService } from 'src/app/services/message.service';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';


import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';


@Component({
  selector: 'app-pre-questionnaire',
  templateUrl: './pre-questionnaire.component.html'
})
export class PreQuestionnaireComponent implements OnInit {

  //private QuestionReponse :Question[] ;

  //déclaration des variables local
  gesListPreQuestion;
  filterListener:Subscription;
  getMaListSimuQuestion: Subscription;
  resultatSimulation ;
  subscribeResultat: Subscription;
  resultatBool: boolean ;
  fileName = '';
  file_name ='';
  //fichier pac envoyé par le client
  filePac;
  controle;
  msgRetourEligibilite;
  paramList;
  

  //constructeur avec les parametres de filtre,service ges toaster
  constructor(private _filterServ: FilterService,private _gesServ: MonGesService, private _toaster: ToastrService, private _rooter : Router,private _activRoute: ActivatedRoute,private router: Router, private message: MessageService) {
    this.filterListener = this._filterServ.filterChangeEmitter.subscribe(
      newFilter =>  {
        //console.log("listener mon-ges-pre-questionnaire");

        this.ngOnInit();
          
        
      }
    )
   }

  InfosTile(title){
    alert(title);
  } 

   ngOnDestroy(){
    this.subscribeParam ? this.subscribeParam.unsubscribe():null;
    this.subscribeResultat?this.subscribeResultat.unsubscribe():null;
   }


   subscribeParam : Subscription
  ngOnInit() {
    this.subscribeParam ? this.subscribeParam.unsubscribe():null;
    this.subscribeParam = this._gesServ.getStaticsData().subscribe(param =>{
          //console.log(param)
          if(param){
            this.paramList = param
              //dans le cas ou il y a déjà eu le pré-formulaire et pas le fichier pac
            if (this._activRoute.snapshot.paramMap.get("statut_code") == "GEPA") {
              if(param.Tiers.Eligible && param.Tiers.Nbr_fichiers > 0){
                this._rooter.navigate(['mes-labelisations/mon-ges']);
                return;
              }
              //this.filePac = this._activRoute.snapshot.paramMap.get("statut_code");
              this.resultatSimulation =  this._activRoute.snapshot.paramMap.get("statut_code");
              this.resultatBool = true ;
              this.filePac = null;
              this.file_name =null;
              this.file_data= null;

            }else{
              if(param.Tiers.Eligible){
                this._rooter.navigate(['mes-labelisations/mon-ges']);
                return;
              }
              this._gesServ.setGesSimuQuestion();
              this.getMaListSimuQuestion = this._gesServ.getGesSimuQuestion().subscribe(
                tiersSimuQuestion => {
                  //console.log(tiersSimuQuestion);
                  this.gesListPreQuestion = tiersSimuQuestion;     
                },error => {
                  this.getMaListSimuQuestion.unsubscribe();
                }
              )    
            } 
          }
               
        }) 
  }

  getStylePac(pac) : Object{
    if (pac.culture == 'CZH') { return {'color' : '#000000', 'font-weight' : 'bold'}}else{return {'color' : '#B6B6B6'}}
  }

  register(form) {
    //console.log(this.gesListPreQuestion)
    var resultat = "";
    
    //parcours toutes les questions 
    for(var index in this.gesListPreQuestion){ 
    
      //parcours toutes les reponses
      for (var reponse in this.gesListPreQuestion[index]["valeur"]) {
          var id = this.gesListPreQuestion[index]["valeur"][reponse]["id_TblGesPreEligPara"]; 
          if((<HTMLInputElement>document.getElementById(id)).checked == true){
            resultat = resultat + "1,";
          }else{
            resultat = resultat + "0,";
          }
       } 
        
    }
    console.log(resultat)
    this._gesServ.getGesSimuResultat(resultat.substring(0, resultat.length - 1));
    this.subscribeResultat?this.subscribeResultat.unsubscribe():null;
    this.subscribeResultat= this._gesServ.getResultat().subscribe(
        result =>{
            this.resultatSimulation = result;
            //console.log(this.resultatSimulation);
            if(this.resultatSimulation){
              //console.log(this.resultatSimulation)
              if (this.resultatSimulation.substring(0, 1).toLowerCase() == "o"){
                this.resultatBool = true ;
              }else{
                this.msgRetourEligibilite =  "Vos pratiques culturales, ne vous permettent d'être éligible !";
                this.resultatBool = null;
              }              
            }
        }
      )
  }


    file_data:any=null

fileChange(index,event) {
    
    const fileList: FileList = event.target.files;
    //check whether file is selected or not
    if (fileList.length > 0) {

      const file = fileList[0];
      //get file information such as name, size and type
      //console.log('finfo',file.name,file.size,file.type);
      if(file.type == 'text/xml'){
        this.file_name = file.name;
        //max file size is 4 mb
        if((file.size/1048576)<=4)
        {
          let formData = new FormData();
          let info={id:2,name:'raja'}
          formData.append('file', file, file.name);
          formData.append('id','2');
          formData.append('tz',new Date().toISOString())
          formData.append('update','2')
          formData.append('info',JSON.stringify(info))
          formData.append('id_ResDoTy',"2870")
          this.file_data=formData
          //console.log(this.file_data)
        }
      }else{
          this._toaster.error('Le document renseigné n\'est pas valide.');
      }
    }

  }
uploadFile()
    {
      if (this.file_data){
        this.controle = "";
        this._gesServ.fileUpload(this.file_data);

        var getPac = this._gesServ.getPacFile().subscribe(
            fp => {
              if(!fp) return;

              //verification de la structure et ou de la campagne

              // Cas d'erreur
              if (fp['controle'] != "") {
                this.controle = fp['controle'];
                if (fp['mail'] == true){
                    this._gesServ.sendMailErreurSiret(this.paramList,fp['siret'])
                }
                return;
              }



              this.filePac = fp;
            }, error =>{},
            () => {
              getPac ? getPac.unsubscribe() : null;
            }
          )
      //  console.log(getPac)
      }

    }


loadFilePac(){
  this._gesServ.loadFilePac();
  this._gesServ.setStaticsData();
  this.router.navigate(['/mes-labelisations/mon-ges/questionnaire/']);
}

changeFile()
{
  this.filePac = null;
  this.file_name =null;
  this.file_data= null;
}

}
