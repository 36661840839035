import { Component, OnInit } from '@angular/core';
import { AideService } from 'src/app/services/aide.service';

@Component({
  selector: 'app-aide',
  templateUrl: './aide.component.html',
  styleUrls: ['./aide.component.scss']
})
export class AideComponent implements OnInit {

  viewportWidth;
  searchValue;
  searchResult;
  constructor(private _aideServ: AideService) { }

  ngOnInit() {
    window.scrollTo(0, 0)
  }

  extendText(category){
    this._aideServ.changeState(category);
  }

  search(value){
    this.searchResult = this._aideServ.search(value)
  }

  ngDoCheck(){
    this.viewportWidth = window.innerWidth;
  }

}
