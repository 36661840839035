import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { FilterService } from './filter.service';
import { MonGesService } from 'src/app/services/mon-ges.service';
import { ToastrService } from 'ngx-toastr';
import { Subscription, Subject, BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class MessageService {

  constructor(private _http: HttpClient,private _filtServ: FilterService, private _toaster: ToastrService, private _gesServ : MonGesService) { }
  _env=environment;

  getMessages: Subscription;
  getFilter: Subscription;

  public mesMessages = new BehaviorSubject(null);
  public messagesDests = new BehaviorSubject(null);
  public messagesNonLusNbr = new BehaviorSubject(null);

  
  setAllMessages(){      
    const filterData = new FormData();
    filterData.append("filter",JSON.stringify(this._filtServ.filterValue.getValue()));
    return this._http.post(this._env.BZ_API_BASE_URL + "push/messages/format",filterData,{withCredentials:true});
  }

  getAllMessages(){
    return this.mesMessages.asObservable();
  }

  readMessage(messageId){
    //console.log(messageId)
    return this._http.post(this._env.BZ_API_BASE_URL + "push/messageread/",{"idMessage": messageId},{withCredentials:true});
  }


  sendemail(to,objet,message){
    let data = new FormData();
    data.append("to",to);
    data.append("object",objet);
    data.append("message",message);

    return this._http.post(this._env.BZ_API_BASE_URL + "push/sendmail",data,{withCredentials:true});      
  }

  changementTel(nouveau_num){

    this._gesServ.getStaticsData().subscribe(param =>{
      console.log('ez')
      var formData = new FormData()
      formData.append("params", JSON.stringify(param))
      formData.append("tel", nouveau_num)
      this._http.post(this._env.BZ_API_BASE_URL + 'administration/changementnumerotelephone/format/json', formData,{withCredentials:true}).subscribe(res=>{
        if(res){
          if (res['msgerreur'] == ''){
            this._toaster.success("Un mail a été envoyé à votre commercial, vous serez contacté très prochainement !")
          }else{
            this._toaster.error(res['msgerreur'])
          }
        }
      });    
    })
   
  }
}
