import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'traductionEcheance'
})
export class TraductionEcheancePipe implements PipeTransform {

  transform(value: string): any {
    let result = null;
    let val = value.toLowerCase();
    let year = value.substring(value.length-4);
    // //console.log(val);
    // //console.log(year);
    if(val.startsWith("january")){
      result = "Janvier " + year;
    } else if(val.startsWith("february")){
      result = "Février " + year;
    } else if(val.startsWith("march")){
      result = "Mars " + year;
    } else if(val.startsWith("april")){
      result = "Avril " + year;
    } else if(val.startsWith("may")){
      result = "Mai " + year;
    } else if(val.startsWith("june")){
      result = "Juin " + year;
    } else if(val.startsWith("july")){
      result = "Juillet " + year;
    } else if(val.startsWith("august")){
      result = "Aôut " + year;
    } else if(val.startsWith("september")){
      result = "Septembre " + year;
    } else if(val.startsWith("october")){
      result = "Octobre " + year;
    } else if(val.startsWith("november")){
      result = "Novembre " + year;
    } else if(val.startsWith("december")){
      result = "Décembre " + year;
    }

    return result;
  }
// //console.log(result);
}


