import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { InfomarchesService } from 'src/app/services/infomarches.service';
import { FilterService } from 'src/app/services/filter.service';
import { Subscription, Observable } from 'rxjs';

import {Chart} from 'chart.js';
import * as Highcharts from "highcharts";
import { ToolsService } from 'src/app/services/tools.service';

import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-infos-marche',
  templateUrl: './infos-marche.component.html',
  styleUrls: ['./infos-marche.component.scss']
})
export class InfosMarcheComponent implements OnInit {

  infosMarche;
  infosCBOT;

  infoCourbesHighC;
  chartHCDisplayed;

  checkedProduct;
  infosToShow;
  infosGraphs = [];

  canvas;
  selectedCour;
  interval;

  getInfosMarche: Subscription;
  getInfosCBOT: Subscription;
  getInfoCourbes: Subscription;

  nodes;

  isVisibleDesc;

  viewportWidth;
  dynamicTitle;

  constructor(private _infoServ: InfomarchesService, private _filtServ: FilterService,private _elementRef : ElementRef, private _tools: ToolsService) {
    //console.log(this.infosGraphs);
    this._infoServ.setInfoMarche();
    this._infoServ.setInfoCBOT();
    this.getInfosMarche = this._infoServ.getInfoMarche().subscribe(
      infos => {
        //console.log("infosMarche compo => ",infos)
        this.infosMarche = infos;

        if(this.infosMarche){
          this.setSelectedProduct(this.infosMarche.culture[0].nom)
        }
      }
    )
    


    
    this.getInfosCBOT = this._infoServ.getInfoCBOT().subscribe(
      CBOT => {
        const cultNames = {0:"ble",1:"mais",2:"soja"};
        
        if(CBOT){
          this.dynamicTitle = CBOT.accordion.recap;
          //console.log("CBOT info marche compo =>",CBOT)
          CBOT.chicago.echeances.map(
            (x,index)=>{
              x.helperName = cultNames[index].toUpperCase();
              //console.log(x.helperName)
            }
          )
            
          CBOT.accordion.marches.type.map( x => {
            if(x.culture.length){
              x.culture.map(
                x => {
                  this.infosGraphs.push(x)
                }
              )
            } else {
              this.infosGraphs.push(x.culture);
            }
          })
          const dateMarche = CBOT.datemarches;
          CBOT.datemarches = dateMarche.substr(11,21);
          //console.log(dateMarche.substr(11,21))
          this.infosCBOT = CBOT;
        }
      }
    )
   }
  
  ngOnInit() {
    this.interval = setInterval(()=>{
      // //console.log("coucou")
      // //console.log(this.selectedCour)
      // //console.log(this.checkedProduct)

      const selectedCour = this.selectedCour
      const checkedProduct = this.checkedProduct;
      const dexProduit = this.infosMarche.culture.findIndex(ind=>  ind.nom == checkedProduct);
      const selectedObj = this.infosMarche.culture[dexProduit];
      const dexCours = selectedObj.cours.cour.findIndex(ind => ind.id == selectedCour);
      const coursToReload = selectedObj.cours.cour[dexCours];
      // //console.log(selectedObj)
      this._infoServ.setInfoMarche();
      this.getInfosMarche = this._infoServ.getInfoMarche().subscribe(
        infos => {
          //console.log("infosMarche compo => ",infos)
          
          if(this.infosMarche){
            this.setSelectedProduct(checkedProduct)
            if(coursToReload){
              this._infoServ.setInfoCourbesMatif(coursToReload.id,coursToReload.titre);
            }
          }
          return this.infosMarche = infos;
        },
        error => console.log(error)
      )
    }, 30000);
  }

  showHideDesc(index){
    this.isVisibleDesc == index ? this.isVisibleDesc = null : this.isVisibleDesc =  index;
    ;
    //console.log(this.isVisibleDesc)
  }

  setSelectedProduct(productName){
    this.checkedProduct = productName;
    this.infosMarche.culture.map(
      cult =>{
        if(cult.nom == productName){
          this.infosToShow = cult;
          // //console.log(this.infosToShow)
        }
      }
    )
  }

  setSelectedCour(courObj){
    // //console.log(courObj);
    if(this.selectedCour == courObj.id){
      this._infoServ.InfoCourbesTitle.next(null);
      this._infoServ.infoCourbesMatif.next(null);
      this.selectedCour = null;
    } else {
      this.selectedCour = courObj.id;
      this._infoServ.setInfoCourbesMatif(courObj.id,courObj.titre);
    }
  }

  ngAfterViewChecked(){
    if(this.infosGraphs && this.infosCBOT){
      // //console.log("GRAPHS INFOS =>", this.infosGraphs)

      this.infosGraphs.forEach(
        (infoGraph,indx) => {
          // const imgName = this._tools.lowercase(infoGraph['@attributes'].nom);
          const containerNameEl = "container-" + infoGraph['@attributes'].nom;
/*          //console.log(containerNameEl)
          //console.log(this.infosGraphs);*/
          

          const el = document.getElementById(containerNameEl);
          this.nodes = document.querySelectorAll('#canvas-'+infoGraph['@attributes'].nom);
/*           //console.log(el)
           //console.log(this.nodes);*/
          if(this.nodes.length == 0 && el){
            el.insertAdjacentHTML(
              'beforeend',
              '<canvas id="canvas-'+infoGraph['@attributes'].nom+'" style="" class="canvas"></canvas>'
            )

            let canva = <HTMLCanvasElement>document.querySelector('#canvas-'+infoGraph['@attributes'].nom);
            // const chartCtx = canva.getContext('2d');
            // //console.log(chartCtx);

            const helperLabels = [];
            infoGraph.graphe.x.pt.map(
              x=>{
                const val = x.substring(5);
                const jour = val.substring(3);
                const mois = val.substring(0,2)
                // //console.log("valeur helper val => ",val)
                // //console.log("jour helper val => ",jour)
                // //console.log("mois helper val => ",mois)

                const frenchDate = jour + "-" + mois;
                helperLabels.push(frenchDate);
              }
            )
            new Chart(canva, { 
                type: 'line',
                data: {
                  labels: helperLabels,
                  datasets: [{                    
                    data: infoGraph.graphe.y.pt,
                    label: "",
                    borderColor: "rgb(170, 217, 85)",
                    fill: false,
                    lineTension: 0
                  }]
                },
                options: {
                  responsive: true,
                  scales: {
                    xAxes: [{
                      ticks: {
                        minRotation: 0,
                        maxRotation: 0
                      }
                    }]
                  },
                  legend: {
                    display: false
                  }
                }
            });
          }
        }
      )
        //infoGraph.graphe.x.pt
    }
  }

  triggerScrollTo(){
    document.querySelector('#destination').scrollIntoView({behavior: 'smooth'});
  }
  ngOnDestroy(){
      this.getInfosMarche.unsubscribe();
      this.getInfosCBOT.unsubscribe();
      if(this.interval){
        clearInterval(this.interval)
      }
  }

  ngDoCheck(){
    this.viewportWidth = window.innerWidth;
  }
 

}
