import { Component, OnInit, ElementRef, ViewChildren } from '@angular/core';
import { DashboardService } from 'src/app/services/dashboard.service';
import { Location, NgStyle } from '@angular/common';
import { FilterService } from 'src/app/services/filter.service';
import { BehaviorSubject, Subscription } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { FormGroup, FormBuilder } from '@angular/forms';
import { DocumentService } from 'src/app/services/document.service';
import { ResearchService } from 'src/app/services/research.service';
import { FilterDocumentsService } from 'src/app/services/filter-documents.service';
import { createOfflineCompileUrlResolver } from '@angular/compiler';
import { LoaderService } from 'src/app/services/loader.service';

@Component({
  selector: 'app-filter-content',
  templateUrl: './filter-content.component.html'
})
export class FilterContentComponent implements OnInit {

  constructor(
    private _dashSrv: DashboardService,
    private _loc: Location,
    private _filterServ: FilterService,
    private _route: ActivatedRoute,
    private _fb: FormBuilder,
    private _docFiltServ: FilterDocumentsService,
    private _loader: LoaderService,
    private _ref: ElementRef
    ) {
      
     }

  user;
  pageUrl;
  filterContent;
  param;
  selectedType="ALL";
  selectedFilter="DATE"

  filterListener:Subscription;
  getDocs:Subscription;
  allDocs;

  checkboxStatus = [];
    @ViewChildren('structureCheckbox') checkboxes: HTMLElement;
  
  ngOnInit() {
    // this.filterListener = this._filterServ.filterChangeEmitter.subscribe(
    //   newFilter =>  {
    //     //console.log("listener filter-content")
    //     this.ngOnInit()
    //   }
    // )
    this.pageUrl = this._loc.path();
    this.param = this._route.snapshot.paramMap.get("produit");
    this._filterServ.getFilter().subscribe(
      filterConfig => {
        //console.log(filterConfig)
        // const modified = this._filterServ.createFilterFromCheckedValues(filterConfig)
        // //console.log(modified);
        this.filterContent = filterConfig;
        console.log(this.filterContent)
      }
    )
  }

  setSearchValue(evt){
    const searchValue = evt.target.value;
    //console.log(searchValue,this.selectedType)
    this._docFiltServ.setFilterDocumentSearchText(searchValue);
  }

  setSelectedType(type:string){
    this.selectedType = type;
    this._docFiltServ.setFilterDocumentType(type);
  }

  selectSortBy(evt){
    this._docFiltServ.setFilterDocumentSortBy(evt.currentTarget.value);
  }

  selectSearchOn(par){
    this._docFiltServ.setFilterDocumentSearchOn(par);
  }

  modifyFilterCamp(idCamp){
    //console.log(this.filterContent)
    this.filterContent.camps.camp.forEach(element => {
      if(element['@attributes'].idcamp == idCamp.toString()){
        // //console.log(element)
        element['@attributes'].checked = element['@attributes'].checked == "0" ? "1" : "0";
      } else {
        element['@attributes'].checked = "0"
      }
    });

    this.filterContent.cultures.culture.forEach(cultFilter => {
      cultFilter['@attributes'].clecu = (idCamp  + cultFilter['@attributes'].codecu).toString();  
    })
    //console.log(this.filterContent)
    this.modifyFilter();
    // //console.log(this.filterContent);
  }

  modifyFilterCultures(culture){
    this.filterContent.cultures.culture.forEach(element => {
      if(element['@attributes'].lib == culture.toString()){
        // //console.log(element)
        element['@attributes'].checked = element['@attributes'].checked == "0" ? "1" : "0";
      } 
    });
    this.modifyFilter();
    // //console.log(this.filterContent);
  }

  modifyFilterStructures(structureId,evt){
    // //console.log(evt);
    const checkboxStatus = [];
    //On pousse un string dans le tableau checkboxStatus lorsque une chckbx est cochée
    this.checkboxes['_results'].forEach(
      elem => {
        if(elem.nativeElement.checked){
          checkboxStatus.push("checked")
        }
      }
    )
    //console.log(checkboxStatus)
    //Si aucune structure n'est cochée
    if(checkboxStatus.length == 0){
      // this.filterContent.structures.structure[0]['@attributes'].checked = '1'
      //On cherche l'index  de l'élément coché en dernier (=> plutôt décoché)
      const dex = this.checkboxes['_results'].findIndex(checkbx => checkbx.nativeElement.id == evt.target.id)

      //On recoche la case et on bloque le chagement du filtre et la requête
      this.checkboxes['_results'][dex].nativeElement.checked = true;
      // //console.log(this.checkboxes['_results'])
      return;
    } else { //Si il y a au moins une checkbox coché le filtre est changé norùalement
      this.filterContent.structures.structure.forEach(
        element => {
            if(element['@attributes'].idti == structureId.toString()){
              // //console.log(element)
              element['@attributes'].checked = element['@attributes'].checked == "0" ? "1" : "0";
              // //console.log(element)
            } 
        }
      );
      this.modifyFilter();
    }
  
    


    
    // //console.log(this.filterContent);
  }

  modifyFilter(){
    this._filterServ.setFilter(this.filterContent);
  }

}
