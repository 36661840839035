import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'docSvgPath'
})
export class DocSvgPathPipe implements PipeTransform {

    transform(value: any, ...args: any[]): any {
      let result = null;
      // //console.log(value);
      switch (value) {
          case "FACTURE": 
              result = "/assets/icons/autres/facture.svg"; 
              break;
          case "CONTRAT": 
              result = "/assets/icons/autres/contrat.svg";
              break;
          case "BON APPORT": 
              result = "/assets/icons/autres/bon-apport.svg";
              break;
          case "BON VOYAGE": 
              result = "/assets/icons/autres/bon-voyage.svg";
              break;
          case "DOC GROUPE": 
              result = "/assets/icons/autres/doc-groupe.svg";
              break;
      
          default:
              break;
      }
    // //console.log(result);
      return result;  
    }

}
