import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-redirection-ie',
  templateUrl: './redirection-ie.component.html',
  styleUrls: ['./redirection-ie.component.scss']
})
export class RedirectionIeComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
