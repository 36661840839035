import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators, FormBuilder } from "@angular/forms";
import { AuthService } from 'src/app/services/auth.service';
import { Subscription } from 'rxjs';
import { Router } from '@angular/router';
import { DeviceDetectorService } from 'ngx-device-detector';
import { UserService } from 'src/app/services/user.service';
import { LoaderService } from 'src/app/services/loader.service';
import { ToastrStateService } from 'src/app/services/toastr-state.service';
import { ValidationService } from 'src/app/services/validation.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html'
})
export class LoginComponent implements OnInit {

  constructor(
    private _authServ: AuthService, private _router: Router, 
    private _browserDetect: DeviceDetectorService, private _userServ: UserService,
    private loaderService: LoaderService, private _toasterState: ToastrStateService,
    private _toaster: ToastrService,
    private _userserv: UserService, private _fb: FormBuilder) { 

    }

  loginForm:FormGroup;

  browserInfos;
  messageIdentifiant:string;
  messagePwd:string;
  messageServer:any;
  viewportWidth;

  isSubmit = false;

  logSub: Subscription;
  isMdpVisible = 0;
  ngOnInit() {
    //console.log(document.URL);
    this.viewportWidth = window.innerWidth;
    // //console.log(this.viewportWidth)
    this.browserInfos = this._browserDetect.getDeviceInfo();
    // //console.log(this.browserInfos);
    const browser_version = parseInt(this.browserInfos.browser_version);

    //console.log("Votre navigateur est "+ this.browserInfos.browser + " " + this.browserInfos.browser_version);
    if(this.browserInfos.browser.startsWith("IE")){
      /*//console.log("Tu utilises Ie :(")*/
      this._router.navigate(['bz-ie-redirection']);
    }

    this.loginForm = this._fb.group({
      login: ["",[Validators.required,Validators.email]],
      pwd: ["",[Validators.required]],
      rememberMe: ["",[]]
    });

   /* //console.log(this.loginForm )*/


  }

  forgottenPwd(loginValue){
    /*//console.log(loginValue)*/
    if(!loginValue){
      this._toasterState.emitToasterEvent({type: "error", message: "Entrez votre identifiant/mail pour accéder au mot de passe oublié"})
    } else {
      this._userserv.modifyPassword(loginValue).subscribe(
        response => {

          this._toasterState.emitToasterEvent({type: response['type'], message: response['message']})
          /*//console.log(response);*/
        },
        error => {
          //console.log(error);
        }
      )
    }
  }


  login(){
    // this.messageIdentifiant = "";
    // this.messagePwd = "";
    // this.messageServer = "";
    this.isSubmit = true;

    if(!this.loginForm.valid){
      /*//console.log(this.loginForm)*/
      this.isSubmit = false;
      return;

    } else {
      /*//console.log("form client OK => post")*/
      const idInput = document.getElementById('defaultRegisterFormEmail');
      const pwdInput = document.getElementById('defaultRegisterFormPassword');

      this.loaderService.isLoading.next(true)
      this.logSub = this._authServ.authLogin(this.loginForm.value).subscribe(
        response =>{
          this.messageServer = response['message'];
          /*//console.log(response)*/
          if(this.messageServer == "Vous êtes connecté"){

            idInput.className = 'form-control text-culture mb-4';
            pwdInput.className = 'form-control text-culture mb-4';
            this._authServ.setLogin(true,response['sessionId'] ,response['userId'],response['userName'],response['userFirstName'],response['userLastName'],response['userTel'],response['userMail'],response['userProfile']);
            this.logSub.unsubscribe();
            // //console.log("CASE")

          } else {
            this.isSubmit = false;
            /*//console.log(idInput)*/
            idInput.className = 'form-control text-culture mb-4 input-error';
            pwdInput.className = 'form-control text-culture mb-4 input-error';
            this._toaster.error(this.messageServer)
            // this._toasterState.toasterState.next(null);
            // this._toasterState.toasterState.next({type: "error", message: this.messageServer});
            // this._toasterState.emitToasterEvent({type: "error", message: this.messageServer})
            this.logSub.unsubscribe();
          }
          /*//console.log(this.messageServer);*/
        },
        error =>{
          //console.log(error);
          this.logSub.unsubscribe();
        }
      );
    }
  }

  showMdp(){
    this.isMdpVisible = this.isMdpVisible == 0  ? 1 : 0;
  }

  ngDoCheck(){
    this.viewportWidth = window.innerWidth;
  }

  ngOnDestroy() {

  }
}
