import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ToolsService {

  constructor() { }


  lowercase(exp){
    const string = exp;
    const regexp = /\s+/g;
    const result = exp.replace(regexp, "");
    const regex = /\b[A-Z]{2,30}\b/g;
    let modified = result.replace(regex, function(match) {
      
      // //console.log(match.toLowerCase());
      return match.toLowerCase();
    });
    return modified;
  }

  cleanParam(exp){
    if(exp){
      exp.replace(/([%20])/g, '-');
      // //console.log(exp);
    }
    return exp;
  }

  helperUrlProduct(exp){ 
    const result = exp.replace(" ", "%20");
    return result;
  }

  //fonction qui permet de retourner un tableau par groupe
  groupBy(list, keyGetter) {
    const map = new Map();
    list.forEach((item) => {
         const key = keyGetter(item);
         const collection = map.get(key);
         if (!collection) {
             map.set(key, [item]);
         } else {
             collection.push(item);
         }
    });
    return map;
  }

  onlyNumbers(exp){
    const regex = /^[0-9]{1,2}([,.][0-9]{1,2})?$/;
    const result = exp.replace(/([t/€])/g, '');
    return result;
  }

  onlyCyphers(exp){
    const regex =  /[^-0-9.,]+/;
    const result = exp.replace(regex,'');
    // //console.log(result)
    return result;
  }
}
