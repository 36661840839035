import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'idFromCultName'
})
export class IdFromCultNamePipe implements PipeTransform {

  transform(value: string): any {
    let result = null;
    // //console.log(value);
    // //console.log(val);
    switch (value) {
        case "BLE": 
            result = "00";
            break;
        case "BLE DUR": 
            result = "05";
            break;
        case "ORGE": 
            result = "10";
            break;
        case "MAIS": 
            result = "20";
            break;
        case "AVOINE": 
            result = "40";
            break;
        case "COLZA": 
            result = "80";
            break;
        case "POIS": 
            result = "90";
            break;
        case "POIS CHICHE": 
            result = "94";
            break;
        case "FEVEROLE": 
            result = "95";
            break;
    
        default:
            break;
    }
    // //console.log(result);
    return result;
}

}
