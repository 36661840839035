import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ErrorService {

  constructor() { }

  message = new BehaviorSubject("");
  public ErrorMode = new BehaviorSubject(false);

  ngOnInit() {
  }

  setMessage(message:string){
    this.message.next(message);
  }

  getMessage(){
    return this.message.getValue();
  }
}
