import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "colorCourPipe"
})
export class colorCourPipe implements PipeTransform {
  transform(value: string): any {
    let result = null;
    //console.log(value)
    if(value){
      if (value.startsWith("-")) {   
        result = `<span class="negatif font-weight-bold">${value}</span>`;
  
      } else if (value.startsWith("+")) {
        result = `<span class="positif font-weight-bold">+${value}</span>`;
  
      } else if (value == "0.00") {
        //console.log(value);
        result = `<span class="zero font-weight-bold">${value}</span>`;
      } else {
        result = `<span class="positif font-weight-bold">+${value}</span>`;
      }
    }
    return result;
  }
}