import { Component, OnInit, ElementRef, ɵConsole } from '@angular/core';
import { FilterService } from 'src/app/services/filter.service';
import { AnalyseCommService } from 'src/app/services/analyse-comm.service';
import { ButtonService } from 'src/app/services/button.service';
import {Chart, ChartTooltipLabelColor} from 'chart.js';
import { Subscription, empty } from 'rxjs';
import { ToolsService } from 'src/app/services/tools.service';
import { contratColorsPipe } from 'src/app/pipes/contratsColors';
import { cultureByIdPipe } from 'src/app/pipes/culturebyid';


@Component({
  selector: 'app-synthese-commerciale',
  templateUrl: './synthese-commerciale.component.html'
})
export class SyntheseCommercialeComponent implements OnInit {
  getSynthCom:Subscription;
  getSynthBubbles:Subscription;
  getSynthArrayData:Subscription;

  graphCommData;
  graphUsedCultures = [];
  barChart;
  barChartData = {
    labels: null,
    datasets: []
  }
  barChartNormalColors;

  bubbleGraph;
  bubbleGraphData;
  bubbleTabMvmnt;

  selectedBubbleidCt;
  
  onGoingYear;
  viewportWidth;

  SynthArrayData;
  expandedCultureRows = [];

  isGraphInit = false;

  selectedCulture = {
    cultureId: null,
    campId: null,
    state: null,
    selectedStructure: [],
    selectedContrats:[]
  }

  selectedCampInGraph;
  
  btnState:string="GRAPHES";


  options = {
    scales: {
        xAxes: [{
            barPercentage: 1,
            gridLines: {
                offsetGridLines: true
            }
        }],
        yAxes: [{
          scaleLabel: {
            display: true,
            labelString: "TONNES"
          },
          ticks: {
            // stepSize: 250
          }
          
        }]
    },
    events: ['click'],
    legend: {
      display: false
    },
    tooltips: {
      callbacks: {
        label: function(tooltipItem, data) {
          //console.log(tooltipItem);
          //console.log(data);

          const value = tooltipItem.value;
          return data.datasets[tooltipItem.datasetIndex].label + ": "+  tooltipItem.value + " tonnes"
        },
      }
    },
    responsive: true,
    onClick: this.barClick.bind(this)
};

  constructor(
    private _filtServ: FilterService,private _synthServ: AnalyseCommService,
    private _btnServ: ButtonService, private _elementRef: ElementRef,
    private _tools:ToolsService) { 
    
    // this._synthServ.setSyntheseBubbles();
    // this._synthServ.setSynthArrayData();
    const d = new Date().getFullYear();
    this.onGoingYear = d.toString().substring(2);
      // //console.log(this.barChartData)
  }
    
  ngOnInit(){
    window.scrollTo(0, 0);
    this._synthServ.setSyntheseCom();
    this.getSynthCom = this._synthServ.getSyntheseComm().subscribe(
      (dataSynthese:[]) => {

        let data = dataSynthese;
        const modifiedData = data.map(
          x => {
            let cultures:[] = x['culture'];

            cultures.forEach((cult:object) => {
              cult['culture.colorcult'] = cult['culture.culture'] == "BLE" ? '#F4D722' : cult['culture.culture']  == "COLZA" ? '#BDAA44' : cult['culture.culture'] == "FEVEROLE" ? '#90886B' : cult['culture.culture'] == "ORGE" ? '#536C21' : cult['culture.culture']== "POIS" ? '#AACD69' : cult['culture.culture'] == "POIS CHICHE" ? '#FFA500' : cult['culture.culture'] == "AVOINE" ? '#955251' : cult['culture.culture'] == "BLE DUR" ? '#b98600' :'transparent'; 
              cult['campagne'] = x['campagne'];

              if(!this.graphUsedCultures.some(elem => elem['culture.culture'] == cult['culture.culture'])){
                this.graphUsedCultures.push(cult);
                // //console.log(this.graphUsedCultures);
              } 
              return cult;
            })
            return x;
          }
        )

        this.graphCommData = modifiedData.slice(-3);
        //console.log(this.graphCommData)
        this.SynthArrayData = data;
    
        //Graph bars 
        ////console.log(this.graphCommData)
        //console.log(this.SynthArrayData)
        // //console.log(this.graphUsedCultures)
        const el = document.getElementById("graph-container");

        const Alldatasets = [];

        this.graphCommData.forEach(
          (x,index) => {
            // //console.log(x);
            let DS = []; 
            x['culture'].forEach( cult=> {
              // //console.log(cult);
              const set = {
                label: cult['culture.culture'],
                backgroundColor: cult['culture.colorcult'],
                data: [cult['culture.qtect']]
              }
              DS.push(set);
            })
            Alldatasets.push(DS);
          }
        )

        let rawData = [];
        Alldatasets.forEach(
          x => {
            x.forEach((elRow)=>{
              rawData.push(elRow);
            })
          }
        )

        rawData.sort(function(a,b){           
          return (a.label < b.label) ? -1 : 1;
        })

        const grouped = this._tools.groupBy(rawData, data => data.label);
        
        grouped.forEach(
          x => {
            // //console.log(setArray)
            // //console.log(x);
              const setByCulture = {
                label: x[0].label,
                    backgroundColor: [],
                    data: [],
                    hoverRadius: 0
              };
              x.forEach((item)=>{
                setByCulture.backgroundColor.push(item.backgroundColor)
                setByCulture.data.push(item.data);
              })
            
            this.barChartData.datasets.push(setByCulture);
          }
        )

        let years = []
        this.graphCommData.forEach(
          x => {
            if(!years.some(elem => elem.campagne == x['campagne'])){
              years.push({campagne: x['campagne'],tonnageTotal:0});
            } 
          }
        );

        // //console.log(years);

        //Pour faire les totaux de tonnages par an
        this.graphCommData.forEach(
          x => {
            // //console.log(x);
            years.forEach(
              y => {
                // //console.log(y)
                if(x['campagne'] == y.campagne.toString()){
                  x.culture.forEach(cult => {
                    let newCount = y.tonnageTotal + cult['culture.qtect'];
                    y.tonnageTotal = newCount;
                  })
                }
              }
            )
          }
        );

        const labls = years.map(
          y => {
            return[ "CAMP 20"+ y.campagne];
          }
        )

        this.barChartData.labels = labls;
        // el.insertAdjacentHTML(
        //   'beforeend',
          
        // )
        // const canva = <HTMLCanvasElement>document.getElementById("graphCanva");
        // const graphCtx = canva.getContext('2d');

        // //console.log(this.barChartData);

        // this.barChart = new Chart(graphCtx, {
        //   type: 'bar',
        //   data: this.barChartData,
        //   options: this.options,
        // });

        this.displayGraphs();
        // this.initBubbleGraph(this.onGoingYear.toString(),"BLE");
        this.getSynthCom.unsubscribe();
        // //console.log(this.onGoingYear)
        // this.initBubbleGraph(this.onGoingYear.toString(),"BLE"); //Voir à dynamiser la culture <===== <=======
      },error => {
        this.getSynthCom.unsubscribe()
      })
  }

  barClick(evt:any){
    
    var activeElement = this.barChart.getElementAtEvent(evt);
    let datasetIndex = activeElement[0]._datasetIndex
    let dataIndex = activeElement[0]._index
    let dataObject = this.barChart.data.datasets[datasetIndex].data[dataIndex];
    // //console.log(activeElement)
    // //console.log(this.graphCommData)

    const period = activeElement[0]._view.label[0].substring(7,9);
    //console.log(period);
    this.selectedCampInGraph = '20' + period;
    //console.log(this.selectedCampInGraph)
    const culture = activeElement[0]._view.datasetLabel;
    // //console.log(culture);

    this.initBubbleGraph(period,culture);
    const tabData = [];
    this.bubbleGraphData.forEach(
      x=> {
        if(x && x != "undefined"){
          tabData.push(x.mouvts);          
        }
      }
    )
    //console.log(tabData)

    // this.barChart.update(true);
      
  }

  initBubbleGraph(campagne,culture){
    this.isGraphInit = true;
    this.bubbleGraphData = [];
    const dataInfos = []
    this.graphCommData.forEach(
      (x,index) => {
        let infos = null;
        if(x.campagne == campagne){
          x.culture.forEach((elem)=>{
            if(elem['culture.culture'] == culture){
              dataInfos.push(elem);
            }
          })
        }
      }
    )
    // //console.log(dataInfos)
    const bubbleGraphStructures = [];
    const dataContrats = [];
    dataInfos.forEach((x)=>{
      bubbleGraphStructures.push(x.structure)
    })

    bubbleGraphStructures.forEach(x=>{
      x.forEach(y => {
        dataContrats.push(y.contratdepot);
      })
    })

    dataContrats.forEach(contrat => {
      contrat.forEach(element => {
        this.bubbleGraphData.push(element)
      });
    })
    //console.log(this.bubbleGraphData)


    
    if (this.bubbleGraphData) {
      // //console.log(this.bubbleGraphData);

      const sortedByType = this._tools.groupBy(this.bubbleGraphData,data => data['contrat.produit'])

      // //console.log(sortedWthColor)


      this.bubbleGraphData = Array.from(sortedByType);
            
      //console.log(this.bubbleGraphData)
      let dataSets = [];
      let xMap = [];

      this.bubbleGraphData.forEach(
        (x,index) => {
          let type = x[0];
          //console.log(type)
          x[1].forEach(contrat => {
            const year = contrat['contratdepot.date'].substring(6,8);
            const month = contrat['contratdepot.date'].substring(3,5);
            const day = contrat['contratdepot.date'].substring(0,2);
            const dateEn = month + "/" + day + "/" + year;
            const date = new Date(dateEn).getTime();
            const prixRaw = contrat['contratdepot.prix'];
            const prix = this._tools.onlyNumbers(prixRaw);
            const qt = contrat['contratdepot.qtenet'];
            const prixPercent = (20 / 100) * prix;
  
  
            const color = new contratColorsPipe().transform(contrat['contrat.produit']);
            const name = type + '-' + new cultureByIdPipe().transform(contrat['contratdepot.clecu']) + " - CAMP 20" + this.selectedCampInGraph; 
            
            //console.log(contrat)
            const content = {
              contratId: contrat['contratdepot.contrat'],
              contratDate: contrat['contratdepot.date'],
              contratPrix: contrat['contratdepot.prix'],
              contratName: name,
              contratTitleColor: color ? color.substring(color.length-4,-1) + "1)" : null,
              contratQte: contrat['contratdepot.qtenet']
            }; 


            // const idContrat = this._tools.onlyCyphers(x['contratdepot.contrat']);
            // this.selectedBubbleidCt = idContrat;
            // //console.log(x);
            // //console.log(content);
         
            let set = 
            {
              label: "",
              backgroundColor: color,
              borderColor: color,        
              name: name,
              content: content,
              data: [{
                x: date,
                y: prix,
                r: 20
              }]
            }
  
            dataSets.push(set);
          });
        }
      )
      
      
      this.bubbleGraph = new Chart(
        <HTMLCanvasElement>document.getElementById("bubble-chart"),
        {
          type: "bubble",
          data: {
            datasets: dataSets
          },
          options: {
            
            events: ['click'],
            responsive: true,
            scales: {
              
              yAxes: [
                {
                  scaleLabel: {
                    display: true,
                    labelString: "PRIX EN EUROS"
                  },
                  ticks: {
                    suggestedMin:0
                  }
                },
              ],
              xAxes: [
                {
                  scaleLabel: {
                    display: true,
                    labelString: "CAMPAGNE " + this.selectedCampInGraph,
                    
                  },
                  type: 'time',
                    time: {
                      unit: 'month',
                      unitStepSize: 2,
                      displayFormats: {
                        'month': 'DD-MM-YYYY'
                      },
                    },
                  ticks: {
                    source: "data",
                    display: false
                  }
                }
              ],
            },
            tooltips: {
              callbacks: {
                title: function(tooltipItem, data) {
                  //console.log(tooltipItem);
                  //console.log(data);
                  //console.log(data.datasets[tooltipItem[0].datasetIndex]['content'].contratName)
                  return data.datasets[tooltipItem[0].datasetIndex]['content'].contratName;
                },
                label: function(tooltipItem, data) {
                  var dataset = data.datasets[tooltipItem.datasetIndex];
                  return dataset['content'].contratId;
                },
                afterLabel: function(tooltipItem, data) {
                  //console.log(tooltipItem);
                  var dataset = data.datasets[tooltipItem.datasetIndex];
                  //console.log(dataset['content'])
                  return  "le " + dataset['content'].contratDate + " - " + dataset['content'].contratPrix + " quantité: " + dataset['content'].contratQte + " T";
                },
                labelTextColor: function(tooltipItem, chartInstance) {
                  var dataset = chartInstance.config.data.datasets[tooltipItem.datasetIndex];
                  //console.log(dataset)
                  return dataset['content'].contratTitleColor;
                },
              },
              // backgroundColor: '#000',
              titleFontSize: 16,
              titleFontColor: '#FFF',
              bodyFontSize: 14,
              displayColors: false
            },

            // onClick: this.bubbleClick.bind(this),
            legend: {
              display: false,
            }
          }
        }
      );
    }
  }

  bubbleClick(evt){
  //   const el = document.getElementById("bubble-chart");
  //   const activePoints = this.bubbleGraph.getElementAtEvent(evt);
  //   //console.log(this.bubbleGraph.getDatasetMeta(0));
  //   //console.log(evt);
  // //console.log(activePoints);
  // //console.log(this.bubbleGraphData)
  // //console.log(this.selectedBubbleidCt)
    // if (activePoints[0]) {
    //   var chartData = activePoints[0]['_chart'].config.data;
    //   var idx = activePoints[0]['_datasetIndex'];
    //   chartData.datasets[0]['data'][0]['idct']=this.bubbleGraphData.contrats.contrat.idct;
    //   //console.log(chartData.datasets[0]['data']);

    //   var label = chartData.datasets[idx].label;
    //   var value = chartData.datasets[idx].data[0];

    //   var idct = chartData.datasets[0]['data'][0]['idct'];
    //   // //console.log(url);
    //   this._synthServ.setSyntheseBubbleDetails(idct);
    //   // alert(url);
    // }
  }

  initGraphs(){
    //console.log("INIT GRAPHES")
    const canva = <HTMLCanvasElement>document.getElementById("graphCanva");
    const graphCtx = canva.getContext('2d');
    this.barChart = new Chart(graphCtx, {
      type: 'bar',
      data: this.barChartData,
      options: this.options
    });
    this.barChart.update();
    // this.initBubbleGraph(this.onGoingYear.toString(),"BLE");
  }

  displayGraphs(){
    
    if(this.btnState == "GRAPHES" && this.onGoingYear){
      this.initGraphs();
    }
  }

  reset(){
    this.selectedCulture = {
      cultureId: null,
      campId: null,
      state: null,
      selectedStructure: [],
      selectedContrats:[]
    }
    return false;
  }
  expandCulture(cultureName,campId){
    if(this.selectedCulture.cultureId == cultureName && this.selectedCulture.campId == campId){
      this.reset();
    } else {
      //console.log(cultureName,campId)
    this.selectedCulture.campId = campId;
    this.selectedCulture.cultureId = cultureName;
    this.selectedCulture.state = "open";
    }
  }

  // expandStructure(structureId,structureArrayContrats,index){
  //   //console.log(structureArrayContrats,index)
  //   let structureDetail = { structureIndex: index, contrats: structureArrayContrats,state: "open" };
  //   this.selectedCulture.selectedStructure.push(structureDetail);
  //   //console.log(this.selectedCulture)
  // }

  // structureContratExpand(cultureName,campagne,indexContrat){
  //   //console.log(cultureName,campagne,indexContrat)    
  // }

  expandStructure(evt,rowData,campagne){
    // //console.log(campagne)
    //console.log("rowData = here => ",rowData)
    if(!this.expandedCultureRows.some(row => row == rowData['culture.culture'] + "-" + campagne)){
      this.expandedCultureRows.push(rowData['culture.culture']+ "-" + campagne)
    } else {
      const dexToDel = this.expandedCultureRows.findIndex(index => index == rowData['culture.culture'] + "-" + campagne);
      this.expandedCultureRows.splice(dexToDel,1);
    
    }
    // //console.log(this.expandedCultureRows);
    // //console.log(rowData['@attributes'])
    const tr = evt.currentTarget;
    let caret = tr.querySelector(".caret");

      rowData.structure.forEach((element,index) => {
        const id = "rowStructure-"+ rowData['culture.culture'] + "-" + campagne + "-" + index;
        // //console.log(id);
        const elStructure = document.getElementById(id);
        let elStructSpacer=null;
        // //console.log(el);
        // //console.log(elStructure);
        if(!elStructure){
          caret.style.transform = "rotate(90deg)";
          tr.insertAdjacentHTML('afterend',
            ` <tr id="rowStructure-${rowData['culture.culture'] + "-" + campagne + "-" + index}" class="table-card">
                <td class="d-flex justify-content-start align-items-center">
                  <span class="ml-3">${element['structure.nom']} <i  class="fas fa-caret-right caret ml-2"></i></span>
                </td>
                <td><span>${element['structure.qtebrut']} T</span></td>
                <td><span>${element['structure.qtenet']} T</span></td>
                <td></td>
                <td><span>${element['structure.prix']}</span></td>
                <td><span>${element['structure.date']}</span></td>
                <td><span>${element['structure.etat']}</span></td>
              </tr>
            `)    
          //Pour afficher le detail contrats si il y en a plusieurs
          const rowStruct = document.getElementById("rowStructure-"+ rowData['culture.culture'] + "-" + campagne + "-" + index); 
          // //console.log(elStructSpacer)

          //Listener Event
          rowStruct.addEventListener('click',(evnt)=>{
            const trStructure = <HTMLElement>evnt.currentTarget;
            let caret = <HTMLElement>trStructure.querySelector(".caret");
            // 
            // //console.log("element contrat ==> ",element["contrats"])
            let toLoopOn = element.contratdepot;
            //console.log(toLoopOn)
            toLoopOn.forEach((contrat,dex) => {
              //console.log(contrat)
              const detailMvmnt = contrat.mouvts;

              const elContrat = document.querySelector(".rowContrat-"+rowData['culture.culture'] + "-" + campagne + "-" + index + "-" + dex);
                // //console.log(elContrat);
                if(!elContrat){
                  caret.style.transform = "rotate(90deg)";
                  trStructure.insertAdjacentHTML('afterend',
                  ` <tr class="rowContrat-${rowData['culture.culture'] + "-" + campagne + "-" + index + "-" + dex} table-card">
                      <td class="d-flex justify-content-start align-items-center">
                        <span class="ml-5">${contrat['contratdepot.contrat']}<i  class="fas fa-caret-right caret ml-2"></i></span>
                      </td>
                      <td><span>${contrat['contratdepot.qtebrut']} T</span></td>
                      <td><span>${contrat['contratdepot.qtenet']} T</span></td>
                      <td><span>${contrat['contratdepot.surface']} ha</span></td>
                      <td><span>${contrat['contratdepot.prix']}</span></td>
                      <td><span>${contrat['contratdepot.date']}</span></td>
                      <td><span>${contrat['contratdepot.etat']}</span></td>
                    </tr>
                  `)
                  const elContrat = document.querySelector(".rowContrat-"+rowData['culture.culture'] + "-" + campagne + "-" + index + "-" + dex);
                  if(elContrat){
                    elContrat.addEventListener('click',(evt)=>{
                      //console.log(detailMvmnt)
                      const trContrat = <HTMLElement>evt.currentTarget;
                      let caret = <HTMLElement>trContrat.querySelector(".caret");
                      caret.style.transform = "rotate(90deg)";
  
                      detailMvmnt.forEach((mvt,indexMvt)=>{
                        let MvmntsEl = document.getElementsByClassName("mouvmnt-" +rowData['culture.culture'] + "-" + campagne + "-" + index + "-" + dex + "-" + indexMvt + " table-card");
                        //console.log(MvmntsEl)
                        if(MvmntsEl.length == 0){
                            trContrat.insertAdjacentHTML('afterend',
                          ` <tr class="mouvmnt-${rowData['culture.culture'] + "-" + campagne + "-" + index + "-" + dex + "-" + indexMvt} table-card">
                              <td class="d-flex justify-content-start align-items-center">
                                <span class="ml-5 pl-4">${mvt['mouvts.contrat']}</span>
                              </td>
                              <td><span>${mvt['mouvts.qtebrut']} T</span></td>
                              <td><span>${mvt['mouvts.qtenet']} T</span></td>
                              <td></td>
                              <td></td>
                              <td><span>${mvt['mouvts.date']}</span></td>
                              <td></td>
                            </tr>
                          `)
                        } else {
                          caret.style.transform = "rotate(0deg)";
                          // elContratSpacer.remove();
                          MvmntsEl[0].remove();
                        }
  
                      })
  
                    })
                  }

                } else {
                  // const elContratSpacer = this._elementRef.nativeElement.querySelector("#rowContratSpacer-" + rowData['@attributes'].nom + "-" + campagne + "-" + index + "-" + dex);
                  caret.style.transform = "rotate(0deg)";
                  // elContratSpacer.remove();
                  detailMvmnt.forEach((mvt,indexMvt)=>{
                    let MvmntsEl = document.getElementsByClassName("mouvmnt-" +rowData['culture.culture'] + "-" + campagne + "-" + index + "-" + dex + "-" + indexMvt + " table-card");
                    //console.log(MvmntsEl)
                    if(MvmntsEl.length > 0){
                      caret.style.transform = "rotate(0deg)";
                      // elContratSpacer.remove();
                      MvmntsEl[0].remove();                       
                    } 

                  })
                  
                  elContrat.remove();
                }
            });
          })
          // //console.log("rowStruct",rowStruct)
        } else {
          // const elStructSpacer = this._elementRef.nativeElement.querySelector("#spacerStructure-" + rowData['@attributes'].nom + "-" + campagne + "-" + index);
          caret.style.transform = "rotate(0deg)";
          // elStructSpacer.remove();
          elStructure.remove();
          const elContainer = document.getElementById("cultureContainer-" + rowData['culture.culture'] + "-" + campagne);
          const AllTrsInContainer = Array.from(elContainer.children);
         
          AllTrsInContainer.splice(0,2);

          setTimeout(()=>{
            AllTrsInContainer.map(
              elem => {
                elem.remove();
              }
            )
          },5)
          // //console.log(AllTrsInContainer);
          
        }
      });
  }


  ngAfterViewInit(){

  }
  
  ngDoCheck(){
    this.btnState = this._btnServ.getBtnState();
    this.viewportWidth = window.innerWidth;
  }

  ngOnDestroy(){
    this.getSynthCom.unsubscribe();
  }
}
