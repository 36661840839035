import { CanActivate,
    ActivatedRouteSnapshot,
    RouterStateSnapshot,
    Router
 } from "@angular/router";
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { AuthService } from './auth.service';
import { Location } from '@angular/common';

@Injectable()
export class LoginGuard implements CanActivate{
    constructor(private _authServ: AuthService, private _router: Router, private _loc: Location){}
    canActivate(route: import("@angular/router").ActivatedRouteSnapshot, state: import("@angular/router").RouterStateSnapshot): boolean | import("@angular/router").UrlTree | import("rxjs").Observable<boolean | import("@angular/router").UrlTree> | Promise<boolean | import("@angular/router").UrlTree> {
        this._authServ.isLoggedIn()
        const isAuth = this._authServ.isLogged.getValue();
        const url = this._loc.path();
        if(isAuth){
            // //console.log("isAuth dans login guard",isAuth, " <= déjà connecté retour page accueil");
            // this._router.navigate(['accueil']);
        } 
        return true;
    }

}