import {Chart} from 'chart.js';
import { ViewChild,Directive, Component, ElementRef, OnInit, Renderer2 } from '@angular/core';
import { TooltipDirective } from 'angular-bootstrap-md';
import { DashboardService } from 'src/app/services/dashboard.service';
import { Subscription } from 'rxjs';
import { MesReglementsService } from 'src/app/services/mes-reglements.service';
import { FilterService } from 'src/app/services/filter.service';
import { MonGesService } from 'src/app/services/mon-ges.service';

@Component({
  selector: 'app-dash-resultat-ges',
  templateUrl: './dash-resultat-ges.component.html'
})
export class DashResultatGesComponent implements OnInit {
  
  chart_resultat

  paramList;
  subscribeparam;

  constructor(private _gesServ : MonGesService, private _elmtRef : ElementRef) {
    
  }

  ngOnInit() {  
    var subscribeparam;
    var originalLineDraw = Chart.controllers.horizontalBar.prototype.draw;
    Chart.helpers.extend(Chart.controllers.horizontalBar.prototype, {
      draw: function() {
        originalLineDraw.apply(this, arguments);
        var chart = this.chart;
        var ctx = chart.chart.ctx;

        var index = chart.config.data.lineAtIndex;
        if (index) {
          var xaxis = chart.scales['x-axis-0'];
          var yaxis = chart.scales['y-axis-0'];

          ctx.save();
          ctx.beginPath();
          ctx.moveTo(xaxis.getPixelForValue(undefined, 1), yaxis.top);
          ctx.strokeStyle = '#ff0000';
          ctx.lineTo(xaxis.getPixelForValue(undefined, 1), yaxis.bottom);
          ctx.stroke();
          ctx.restore();
        }
      }
    });
    this.subscribeparam = this._gesServ.getStaticsData().subscribe(
        param =>{
          if (param) {
            //console.log(param)
            var div_chart = this._elmtRef.nativeElement.querySelector('#resultat-container').getContext('2d');
            this.chart_resultat = new Chart(div_chart, {
                type : 'horizontalBar',
                data : {
                    datasets: [{
                            data: [200],
                            label:'',
                            backgroundColor: "rgba(222,124,37,.2)"
                        },{
                            data: [300],
                            label:'',
                            backgroundColor: "rgba(209,50,56,.2)"
                        },{
                            data: [-100],
                            label:'',
                            backgroundColor: "rgba(222,124,37,.2)"
                        },{
                            data: [-300],
                            label:'',
                            backgroundColor: "rgba(253,221,0,.2)"
                        },
                        {
                            data: [-300],
                            label:'450 kg/CO2',
                            backgroundColor: "rgba(120,190,67,1)"
                        },
                        {
                            data: [-300],
                            label:'',                            
                            backgroundColor: "rgba(68,121,189,.2)"

                        } ]
                },
                options: {
                    maintainAspectRatio:false,
                    scales: {
                        yAxes:[{
                          offset : true,
                          stacked:true,
                          gridLines:{
                            display : false
                          }
                        }],
                        xAxes:[{
                          offset : true,
                          stacked:true,                          
                          ticks:{
                            reverse:true,
                            stepSize : 300,
                            min : -1000,
                            max: 500,

                          },
                          gridLines:{
                            display : false
                          }
                        }]
                    },
                    legend:{
                      display : false
                    },
                    tooltips: {
                      enabled:false
                    }
                }
            })
          }
          
        }); 
    
  }

  ngOnDestroy(){
    this.subscribeparam ? this.subscribeparam.unsubscribe():null;
  }

}
