import { Component, OnInit, ViewChild } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import { HttpClientModule } from "@angular/common/http"
import { NgForm } from "@angular/forms";
import { DashboardService } from 'src/app/services/dashboard.service';
import { LoaderService } from 'src/app/services/loader.service';
import { FilterService } from 'src/app/services/filter.service';
import { ContratsService } from 'src/app/services/contrats.service';
import { Subscription } from 'rxjs';

import {Location} from "@angular/common";
import { Router } from '@angular/router';
import { ModalDirective } from 'angular-bootstrap-md';
import { AuthService } from 'src/app/services/auth.service';
import { CookieService } from 'ngx-cookie-service';


@Component({
  selector: 'app-accueil',
  templateUrl: './accueil.component.html'
})
export class AccueilComponent implements OnInit {
  screenHeight:number;
  screenWidth:number;
  sidebarHeight:number;

  expiredDateCookieWarning;

  loaderState:boolean;
  
  @ViewChild('cookieModal',null) modal: ModalDirective;

  constructor(
    private http: HttpClient,
    private _dashServ: DashboardService,
    private _filterServ: FilterService,
    private _loaderServ: LoaderService,
    private _contratServ: ContratsService, 
    private _loc: Location,
    private _router: Router,
    private _authServ: AuthService,
    private _cookieServ: CookieService
    ) {
    }

  ngOnInit() {
    console.log(document.URL);
    console.log(this._filterServ.filterValue.getValue())
    window.scrollTo(0, 0)
    this.screenHeight = window.innerHeight;
    this.screenWidth = window.innerWidth; 
    
  }

  ngAfterViewInit(){
    if(!this._cookieServ.check('seenCookieWarning')){
      setTimeout(()=>{
        this.modal.show();
      },5000)
    }
  }

  refuseCookies(){
    this._authServ.logOut();
  }

  acceptCookies(){
    this.expiredDateCookieWarning = new Date();
    this.expiredDateCookieWarning.setMinutes( (this.expiredDateCookieWarning.getMinutes() + 87840) );// == 61 jours en minutes 
    this._cookieServ.set('seenCookieWarning','true',this.expiredDateCookieWarning);
    this.modal.hide();
  }
}
