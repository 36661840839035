import { Injectable, EventEmitter } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Subject, Subscription, BehaviorSubject } from 'rxjs';
import { FilterService } from './filter.service';
import { debounceTime, share, take, shareReplay } from 'rxjs/operators';
import { ActivatedRoute } from '@angular/router';
import { ToolsService } from './tools.service';

@Injectable({
  providedIn: 'root'
})
export class MesStocksService {

  _env = environment;
    getFilterStock:Subscription;
    getStock:Subscription;
    getFilter: Subscription;
    getMesStocksDetails:Subscription;

    public mesStocks = new Subject();
    public mesStocksDetails = new BehaviorSubject(null);
    public nbreAnalyses = new BehaviorSubject(null);
    public mesStocksAnalyses = new BehaviorSubject(null);

    public anlysesNbrEmitter: EventEmitter<any>;
    
    detailStocks;
    detailMvmnts;
    detailQualités;

    noStockToShow = new BehaviorSubject(null);

  constructor(
    private _http: HttpClient,
    private _filtServ: FilterService,
    private _activRoute: ActivatedRoute,
    private _tools: ToolsService
    ) {
      this.anlysesNbrEmitter = new EventEmitter();
    }

    

    emitAnalyseNumber(numberValue):void {
      //console.log("emit analyse nbr event triggered")
      this.anlysesNbrEmitter.emit(numberValue)
    }


  setMesStocks(){
    this.getFilterStock = this._filtServ.getFilter().pipe(debounceTime(1),share()).subscribe(
      filterContent => {
        const filterConf = this._filtServ.createFilterFromCheckedValues(filterContent);
        // //console.log(filterConf);
        const filterData = new FormData();
        filterData.append("filter",JSON.stringify(filterConf));
        this.getFilterStock ? this.getFilterStock.unsubscribe(): null;

        if(filterContent){
          this.getStock = this._http.post(this._env.BZ_API_BASE_URL + "messtocks/getstocks/format/json",filterData,{withCredentials:true}).pipe(share()).subscribe(
            stocks =>{
              //console.log(stocks);
              if(!stocks[0]['msgerreur']){
                const mesStocks = JSON.parse(stocks[0][""]);
    
                //console.log(mesStocks);
    
                const modifiedStocks = this.setPercentbars(mesStocks['MESSTOCKS']);
                // //console.log(modifiedStocks);
                this.mesStocks.next(modifiedStocks); 
                this.getFilterStock ? this.getFilterStock.unsubscribe(): null;
                this.getStock.unsubscribe();
                
              } else {
                //console.log("CASE")
                this.mesStocks.next([null,stocks[0]["msgerreur"]]);
                this.getFilterStock ? this.getFilterStock.unsubscribe(): null;
                this.getStock.unsubscribe();
                //console.log(stocks[0]["msgerreur"])
              }
            }, 
            error => {
              this.getStock.unsubscribe();
            }
          )
        }
      }
    )
  }

  getStocks(){
    return this.mesStocks.asObservable();
  }

  setMesStocksDetails(param:string){
    this.getFilter = this._filtServ.getFilter().pipe(debounceTime(5),share()).subscribe(
      filterContent => {
        const filterConf = this._filtServ.createFilterFromCheckedValues(filterContent);
        const filterData = new FormData();
        filterData.append("filter",JSON.stringify(filterConf));

        this.getFilter ? this.getFilter.unsubscribe():null;

        if(filterContent){
          this.getMesStocksDetails = this._http.post(this._env.BZ_API_BASE_URL + "messtocks/getstocks/format/json",filterData,{withCredentials:true}).pipe(debounceTime(10),share()).subscribe(
            stocks =>{
              const raw = JSON.parse(stocks[0][""]);
              const response = raw['MESSTOCKS'] ? raw['MESSTOCKS'] : null;
              if (response){
                //console.log(response)
                
                let prm = this._tools.cleanParam(param);
                //console.log(prm)

                const isCultureAvailable = response.filter(stock => stock.culture == prm);
                //console.log(isCultureAvailable);
                if(isCultureAvailable.length > 0){
                  
                    let ferme = isCultureAvailable[0]['Stockferme'] ? isCultureAvailable[0]['Stockferme'] : null;
                    let depot = isCultureAvailable[0]['depot'] ? isCultureAvailable[0]['depot'] : null;
                      //console.log(depot)
                      //console.log(ferme)
                      const payload = {};

                      payload['depot'] =  depot ?  depot : null;    
                      payload['ferme'] = ferme ? ferme : null;

                      this.mesStocksDetails.next(payload);
                } else {
                  this.mesStocksDetails.next([null,"Pas de stocks pour ce produit. Vous l'avez peut être désélectionné dans le filtre."]);
                }

              } else {
                //console.log("NO DATA")
                this.mesStocksDetails.next(null);
              }
  
              this.getMesStocksDetails.unsubscribe();
            }, 
            error => {
              this.getMesStocksDetails.unsubscribe();
            }
          )
        }
      }
    )
  }

 getStocksDetails(){
   return this.mesStocksDetails.asObservable();
 }


  setPercentbars(mesStocksValue){
    // //console.log(widthGlobalBar)
    mesStocksValue.map(x => {
      const fnd = x.stockfermeND;
      const fd = x.stockfermeD;
      const ad = x.depotsilo;
      const qtCnt = x.qtecontract;

      const totalTon = x.tonnagetotal;

      const fndPer = (100*fnd)/totalTon;
      const fdPer = (100*fd)/totalTon
      const adPer = (100*ad)/totalTon
      const cntPer = (100*qtCnt)/totalTon;
      
      //console.log("totalTon => ",totalTon);
      //console.log("fndPer=> ",fndPer)
      //console.log("fdPer=> ",fdPer)
      //console.log("adPer=> ", adPer)
      //console.log("------------------")

      x['tonnePercent'] = {
        "fndPer":fndPer,
        "fdPer":fdPer,
        "adPer":adPer,
        "cntPer": cntPer,
        "totalTon": totalTon
      }
    })
    //console.log(mesStocksValue);
    return mesStocksValue; 
  }

  getResultatsAnalyses(filterContent){
    const filterConf = this._filtServ.createFilterFromCheckedValues(filterContent);
    const filterData = new FormData();
    filterData.append("filter",JSON.stringify(filterConf));
    return this._http.post(this._env.BZ_API_BASE_URL + "messtocks/resultatsanalyses/format/json",filterData,{withCredentials:true}).pipe(share());
  }
}
