import { HashLocationStrategy, LocationStrategy, registerLocaleData  } from '@angular/common';

import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { NgModule, LOCALE_ID } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from "@angular/forms";

import { DeviceDetectorModule } from 'ngx-device-detector';
import { MDBBootstrapModule } from 'angular-bootstrap-md';
import { ChartModule, HIGHCHARTS_MODULES } from 'angular-highcharts';
import stock from 'highcharts/modules/stock.src';
import more from 'highcharts/highcharts-more.src';
import { DataTablesModule } from 'angular-datatables';
import {NgxPaginationModule} from 'ngx-pagination';
import { ScrollToModule } from '@nicky-lenaers/ngx-scroll-to';
import { ToastrModule,ToastContainerModule } from 'ngx-toastr';
import { CookieService } from 'ngx-cookie-service';
import * as fs from 'file-system';




import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AccueilComponent } from './components/accueil/accueil.component';
import { Routes, RouterModule } from '@angular/router';
import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";
import localeFr from '@angular/common/locales/fr';
registerLocaleData(localeFr);

import { HeaderComponent } from './components/header/header.component';
import { DonutComponent } from './components/graphics/donut/donut.component';
import { DashMesStocksComponent } from './components/graphics/dash-mes-stocks/dash-mes-stocks.component';
import { DashCommercialisationComponent } from './components/graphics/dash-commercialisation/dash-commercialisation.component';
import { DashInfoMarcheComponent } from './components/graphics/dash-info-marche/dash-info-marche.component';
import { DashInfoContratsComponent } from './components/graphics/dash-info-contrats/dash-info-contrats.component';
import { PageNavComponent } from './components/page-nav/page-nav.component';
import { FilterNavComponent } from './components/page-nav/filter-nav/filter-nav.component';
import { LoginComponent } from './components/login/login.component';
import { LoginLayoutComponent } from './components/loginLayout/login-layout/login-layout.component';
import { FilterContentComponent } from './components/page-nav/filter-nav/filter-content/filter-content.component';
import { DashReglementsComponent } from './components/graphics/dash-reglements/dash-reglements.component';
import { MesStocksComponent } from './components/mes-stocks/mes-stocks.component';

import { AuthGuard } from './services/auth.gard';
import { LoginGuard } from './services/login.guard';
import { errorProductName } from './services/error.product.guard';

import { whiteSpacePipe } from './pipes/whiteSpace';
import { colorCourPipe } from './pipes/color-cour';
import { TRHighltight } from './pipes/TR-highlight';
import { cultureByIdPipe } from './pipes/culturebyid';
import { contratSvgPipe } from './pipes/contratSvgPath';
import { contratColorsPipe } from './pipes/contratsColors';


import { MaCommercialisationComponent } from './components/ma-commercialisation/ma-commercialisation.component';
import { BtnRetourComponent } from './components/graphics/btn-retour/btn-retour.component';
import { PageHeadComponent } from './components/page-head/page-head.component';
import { StocksDetailComponent } from './components/mes-stocks/stocks-detail/stocks-detail.component';
import { CustomButtonComponent } from './components/graphics/custom-button/custom-button.component';
import { LoaderComponent } from './components/graphics/loader/loader.component';

import { LoaderInterceptor } from './loader.interceptor';
import { MaCommercialisationDetailComponent } from './components/ma-commercialisation/ma-commercialisation-detail/ma-commercialisation-detail.component';
import { MesReglementsComponent } from './components/mes-reglements/mes-reglements.component';
import { ReglementsDetailComponent } from './components/mes-reglements/reglements-detail/reglements-detail.component';
import { MesContratsIndexesComponent } from './components/mes-contrats-indexes/mes-contrats-indexes.component';
import { DocumentsComponent } from './components/documents/documents.component';
import { MesMessagesComponent } from './components/mes-messages/mes-messages.component';
import { MonCompteComponent } from './components/mon-compte/mon-compte.component';
import { InfosMarcheComponent } from './components/infos-marche/infos-marche.component';
import { GraphMarcheComponent } from './components/graphics/graph-marche/graph-marche.component';
import { OffresComponent } from './components/offres/offres.component';
import { SyntheseCommercialeComponent } from './components/synthese-commerciale/synthese-commerciale.component';
import { ErrorInterceptor } from './error-interceptor';
import { LostSessionComponent } from './components/errors/lost-session/lost-session.component';
import { MesLivraisonsComponent } from './components/mes-livraisons/mes-livraisons.component';
import { MesLivraisonsDetailComponent } from './components/mes-livraisons/mes-livraisons-detail/mes-livraisons-detail.component';
import { NotAllowAclComponent } from './components/errors/not-allow-acl/not-allow-acl.component';
import { MesContratsASignerComponent } from './components/mes-contrats-a-signer/mes-contrats-a-signer.component';
import { PremiereConnexionComponent } from './components/premiere-connexion/premiere-connexion.component';
import { HomeLayoutComponent } from './components/home-layout/home-layout.component';
import { ErrorSpanComponent } from './components/errors/error-span/error-span.component';
import { ConnexionInterneComponent } from './components/connexion-interne/connexion-interne.component';
import { AideComponent } from './components/aide/aide.component';
import { ResutatsAnalyseComponent } from './components/resutats-analyse/resutats-analyse.component';
import { TraductionEcheancePipe } from './pipes/traduction-echeance.pipe';
import { NotFoundComponent } from './components/not-found/not-found.component';
import { CulturesWithAccentsPipe } from './pipes/cultures-with-accents.pipe';
import { DocSvgPathPipe } from './pipes/doc-svg-path.pipe';
import { IdFromCultNamePipe } from './pipes/id-from-cult-name.pipe';
import { MatifInfoTableauPipe } from './pipes/matif-info-tableau.pipe';
import { RedirectionIeComponent } from './components/redirection-ie/redirection-ie.component';
import { MonGesComponent } from './components/mes-labelisations/mon-ges/mon-ges.component';
import { MesLabelisationsComponent } from './components/mes-labelisations/mes-labelisations.component';
import { PreQuestionnaireComponent } from './components/mes-labelisations/pre-questionnaire/pre-questionnaire.component';
import { QuestionnaireComponent } from './components/mes-labelisations/questionnaire/questionnaire.component';
import { MesDocumentsLabelComponent } from './components/mes-labelisations/mes-documents-label/mes-documents-label.component';
import {MonGesDetailComponent} from './components/mes-labelisations/mon-ges/mon-ges-detail.component';
import {MonSaiComponent} from './components/mes-labelisations/mon-sai/mon-sai.component';
import {SignatureSaiComponent} from './components/mes-labelisations/mon-sai/signature/signature.component';
import {MonSaiDetailComponent} from './components/mes-labelisations/mon-sai/mon-sai-detail.component';
import {LabelisationNavigationComponent} from './components/mes-labelisations/labelisation-navigation.component';
import {SignatureLabelisationComponent} from './components/mes-labelisations/signature-labelisation.component';
import {QuestionnaireSaiComponent} from './components/mes-labelisations/mon-sai/questionnaire/questionnaire-sai.component';
import {AxeAmeliorationSaiComponent} from './components/mes-labelisations/mon-sai/axe-amelioration/axe-amelioration-sai.component';
import { DashResultatGesComponent } from './components/graphics/dash-resultat-ges/dash-resultat-ges.component';


export function highchartsModules() {
  // apply Highcharts Modules to this array
  return [stock, more];
}



const BZ_ANGULAR_ROUTES: Routes = [

  { 
    path: "",
    component: LoginLayoutComponent,
    children: [
      {  
        path: "",
        pathMatch: "full",
        component: LoginComponent, 
        canActivate: [LoginGuard]
      },
      {
        path: "premiereconnexion/:idBzUser",
        component: PremiereConnexionComponent,
        canActivate: [LoginGuard]
      },
      {
        path: "bz-ie-redirection",
        component: RedirectionIeComponent,
        canActivate: [LoginGuard]
      },
    ], 
  },
  { 
    path: "",
    component: HomeLayoutComponent,
    children: [
      {
        path: "accueil",
        pathMatch: 'full',
        component: AccueilComponent,
        canActivate: [AuthGuard]
      },
      {
        path: "mes-messages",
        component: MesMessagesComponent,
        canActivate: [AuthGuard]
      },
      {
        path: "mes-documents",
        component: DocumentsComponent,
        canActivate: [AuthGuard]
      },
      {
        path: "mon-compte",
        component: MonCompteComponent,
        canActivate: [AuthGuard]
      },
      {
        path: "mes-stocks",
        component: MesStocksComponent,
        canActivate: [AuthGuard]
      },
      {
        path: "mes-stocks/detail/:produit",
        component: StocksDetailComponent,
        canActivate: [AuthGuard,errorProductName]
      },
      {
        path: "ma-commercialisation",
        component: MaCommercialisationComponent,
        canActivate: [AuthGuard]
      },
      {
        path: "ma-commercialisation/detail/:produit",
        component: MaCommercialisationDetailComponent,
        canActivate: [AuthGuard]
      },
      {
        path: "mes-reglements",
        component: MesReglementsComponent,
        canActivate: [AuthGuard]
      },
      {
        path: "mes-reglements/detail/:produit",
        component: ReglementsDetailComponent,
        canActivate: [AuthGuard]
      },
      {
        path: "mes-contrats-a-signer",
        component: MesContratsASignerComponent,
        canActivate: [AuthGuard]
      },
      {
        path: "mes-contrats-indexes",
        component: MesContratsIndexesComponent,
        canActivate: [AuthGuard]
      },
      {
        path: "infos-de-marche",
        component: InfosMarcheComponent,
        canActivate: [AuthGuard]
      },
      {
        path: "offres-bz",
        component: OffresComponent,
        canActivate: [AuthGuard]
      },
      {
        path: "ma-synthese-commerciale",
        component: SyntheseCommercialeComponent,
        canActivate: [AuthGuard]
      },
      {
        path: "mes-livraisons-et-enlevements",
        component: MesLivraisonsComponent,
        canActivate: [AuthGuard]
      },
      {
        path: "mes-livraisons-et-enlevements/detail/:produit",
        component: MesLivraisonsDetailComponent,
        canActivate: [AuthGuard]
      },
      {
        path: "lost_session",
        component: LostSessionComponent,
        canActivate: [AuthGuard]
      },
      {
        path: "aide",
        component: AideComponent,
        canActivate: [AuthGuard]
      },
      {
        path: "mes-resultats-analyses",
        component: ResutatsAnalyseComponent,
        canActivate: [AuthGuard]
      },
      {
        path: "mes-labelisations/mon-ges",
        component: MonGesComponent,
        canActivate: [AuthGuard]
      },
      {
        path: "mes-labelisations/mon-ges/pre-questionnaire",
        component: PreQuestionnaireComponent,
        canActivate: [AuthGuard]
      },
      {
        path: "mes-labelisations/mon-ges/pre-questionnaire/:statut_code",
        component: PreQuestionnaireComponent,
        canActivate: [AuthGuard]
      },      
      {
        path: "mes-labelisations/mon-ges/questionnaire",
        component: QuestionnaireComponent,
        canActivate: [AuthGuard]
      },      
      {
        path: "mes-labelisations/mon-ges/documentaire",
        component: MesDocumentsLabelComponent,
        canActivate: [AuthGuard]
      },      
      {
        path: "mes-labelisations",
        component: MesLabelisationsComponent,
        canActivate: [AuthGuard]
      },      
      {
        path: "mes-labelisations/mon-sai/questionnaire",
        component: QuestionnaireSaiComponent,
        canActivate: [AuthGuard]
      },
      {
        path: "mes-labelisations/mon-sai",
        component: MonSaiComponent,
        canActivate: [AuthGuard]
      },
      {
        path: "mes-labelisations/mon-ges/signature",
        component: SignatureLabelisationComponent,
        canActivate: [AuthGuard]
      },
      {
        path: "mes-labelisations/mon-sai/signature",
        component: SignatureSaiComponent,
        canActivate: [AuthGuard]
      },
      {
        path: "mes-labelisations/mon-sai/axe-amelioration-sai",
        component: AxeAmeliorationSaiComponent,
        canActivate: [AuthGuard]
      },
      {
        path: '404', component: NotFoundComponent
      },
      {
        path: '**', redirectTo: '/404'
      }
      
    ],
    canActivate: [LoginGuard]  
  },


  
  
  
]

@NgModule({
  declarations: [
    AppComponent,
    AccueilComponent,
    AxeAmeliorationSaiComponent,
    HeaderComponent,
    DonutComponent,
    DashMesStocksComponent,
    DashCommercialisationComponent,
    DashInfoMarcheComponent,
    DashInfoContratsComponent,
    PageNavComponent,
    FilterNavComponent,
    LoginComponent,
    LoginLayoutComponent,
    FilterContentComponent,
    DashReglementsComponent,
    MesStocksComponent,
    whiteSpacePipe,
    colorCourPipe,
    contratSvgPipe,
    contratColorsPipe,
    TRHighltight,
    cultureByIdPipe,
    MaCommercialisationComponent,
    BtnRetourComponent,
    PageHeadComponent,
    StocksDetailComponent,
    CustomButtonComponent,
    LoaderComponent,
    MaCommercialisationDetailComponent,
    MesReglementsComponent,
    ReglementsDetailComponent,
    MesContratsIndexesComponent,
    DocumentsComponent,
    MesMessagesComponent,
    MonCompteComponent,
    InfosMarcheComponent,
    GraphMarcheComponent,
    OffresComponent,
    SyntheseCommercialeComponent,
    LostSessionComponent,
    MesLivraisonsComponent,
    MesLivraisonsDetailComponent,
    NotAllowAclComponent,
    MesContratsASignerComponent,
    PremiereConnexionComponent,
    HomeLayoutComponent,
    ErrorSpanComponent,
    ConnexionInterneComponent,
    AideComponent,
    ResutatsAnalyseComponent,
    TraductionEcheancePipe,
    NotFoundComponent,
    CulturesWithAccentsPipe,
    DocSvgPathPipe,
    IdFromCultNamePipe,
    MatifInfoTableauPipe,
    RedirectionIeComponent,
    MonGesComponent,
    PreQuestionnaireComponent,
    QuestionnaireComponent,
    MesDocumentsLabelComponent,
    MesLabelisationsComponent,
    MonGesDetailComponent,
    MonSaiComponent,
    MonSaiDetailComponent,
    LabelisationNavigationComponent,
    SignatureLabelisationComponent,
    DashResultatGesComponent,
    QuestionnaireSaiComponent,
    SignatureSaiComponent
  ],
  imports: [
    BrowserAnimationsModule,
    BrowserModule,
    DataTablesModule,
    AppRoutingModule,
    MDBBootstrapModule.forRoot(),
    RouterModule.forRoot(BZ_ANGULAR_ROUTES),
    DeviceDetectorModule.forRoot(),
    ScrollToModule.forRoot(),
    HttpClientModule,
    FormsModule, 
    ReactiveFormsModule.withConfig({warnOnNgModelWithFormControl: 'never'}),
    ChartModule,
    NgxPaginationModule,
    ToastrModule.forRoot({ positionClass: 'inline',enableHtml:true,closeButton:true,timeOut: 7000 }),
    ToastContainerModule
  ],
  providers: [
    AuthGuard,
    LoginGuard,
    CookieService,
    errorProductName,
    { provide: LocationStrategy, useClass: HashLocationStrategy },
    { provide: HTTP_INTERCEPTORS, useClass: LoaderInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    { provide: HIGHCHARTS_MODULES, useFactory: highchartsModules },
    { provide: LOCALE_ID, useValue: 'fr-FR'}
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
