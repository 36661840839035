import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'culturesWithAccents'
})
export class CulturesWithAccentsPipe implements PipeTransform {

  transform(value: any, ...args: any[]): any {
    let result = null;
        switch (value) {
            case "BLE": 
                result = "BLÉ";
                break;
            case "BLE DUR": 
                result = "BLÉ DUR";
                break;
            case "ORGE": 
                result = "ORGE";
                break;
            case "MAIS": 
                result = "MAÏS";
                break;
            case "AVOINE": 
                result = "AVOINE";
                break;
            case "COLZA": 
                result = "COLZA";
                break;
            case "POIS": 
                result = "POIS";
                break;
            case "FEVEROLE": 
                result = "FÉVEROLE";
                break;
            case "POIS CHICHE": 
                result = "POIS CHICHE";
                break;
            case "SOJA": 
                result = "SOJA";
                break;
        
            default:
                break;
        }
        // //console.log(result);
        return result;
    }
}
