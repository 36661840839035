import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Subject, Subscription } from 'rxjs';
import { FilterService } from './filter.service';
import { debounceTime, share } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class MesLivraisonsService {

  mesLivraisons = new Subject();

  getFilterLiv: Subscription;
  getLivs: Subscription;

  constructor(private _http: HttpClient, private _filtServ: FilterService) { }
  _env = environment;
  
  setLivraisons(){
    this.getFilterLiv = this._filtServ.getFilter().pipe(debounceTime(5),share()).subscribe(
      filterContent => {
         const filterConf = this._filtServ.createFilterFromCheckedValues(filterContent);
        const filterData = new FormData();
        filterData.append("filter",JSON.stringify(filterConf));

        this.getFilterLiv ? this.getFilterLiv.unsubscribe():null;

        if(filterContent){
          this.getLivs =  this._http.post(this._env.BZ_API_BASE_URL + "meslivraisons/getlivraisons/format/json",filterData,{withCredentials:true}).pipe(debounceTime(5),share()).subscribe(
            livraisons => {
              if(!livraisons[0].msgerreur){
                const livs = JSON.parse(livraisons[0][""]);
                //console.log(livs);
                const livsAdapt = livs["MESLIVRAISONS"];
                // //console.log(livsAdapt);
                this.mesLivraisons.next(livsAdapt);
              } else {
                this.mesLivraisons.next([null,livraisons[0]["msgerreur"]])
                this.getFilterLiv ? this.getFilterLiv.unsubscribe(): null;
                this.getLivs.unsubscribe();
              }
              this.getLivs.unsubscribe();
            }, error => { 
              this.getLivs.unsubscribe();
            }
          )
        }
      }
    )
    // return this._http.get(this._env.BZ_API_BASE_URL + "meslivraisons/getlivraisons/format/json").subscribe(
    //   livraisons => {
    //     // //console.log(livraisons)
    //     this.mesLivraisons.next(livraisons);
    //   }
    // )
  }

  getLivraisons(){
    return this.mesLivraisons.asObservable();
  }
}
