import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Subject, Subscription } from 'rxjs';

import { environment } from "../../environments/environment"; 
import { share, refCount, debounceTime } from 'rxjs/operators';
import { FilterService } from './filter.service';

@Injectable({
  providedIn: 'root'
})
export class MesReglementsService {

  constructor(private _http: HttpClient,private _filtServ: FilterService) { }

  getFilterReg:Subscription;
  getRegs:Subscription;

  _env = environment;
  private mesReglements = new Subject<any>();


  setReglements(){
    this.getFilterReg = this._filtServ.getFilter().pipe(debounceTime(1),share()).subscribe(
      filterContent => {
        const filterConf = this._filtServ.createFilterFromCheckedValues(filterContent);
        const filterData = new FormData();
        this.getFilterReg ? this.getFilterReg.unsubscribe():null;

        if(filterContent){
          
          filterData.append("filter",JSON.stringify(filterConf));
          this.getRegs =  this._http.post(this._env.BZ_API_BASE_URL + "mesreglements/getreglements/format/json",filterData,{withCredentials:true}).pipe(share()).subscribe(
            reglements => {
              if(!reglements[0].msgerreur){
                const reg = JSON.parse(reglements[0][""]);
                //console.log(reg)
                const regAdapt = reg["MESREGLEMENTS"];
                //console.log(regAdapt);
                this.mesReglements.next(regAdapt);
                this.getRegs.unsubscribe();
                  
              } else {
                //console.log("CASE")
                this.mesReglements.next([null,reglements[0]["msgerreur"]]);
                this.getFilterReg ? this.getFilterReg.unsubscribe(): null;
                this.getRegs.unsubscribe();

              }
              
            }, error => {
              this.getRegs.unsubscribe();
            }            
                    
          )
        }
      }
    )
  }

  getReglements(){
    return this.mesReglements.asObservable();
  }


  //Cette fonction permet de palier au bug d'affichage en double triple des donuts
  emptyReglements(){
    this.mesReglements.next("");
  }
}
