import {Chart} from 'chart.js';
import { ViewChild,Directive, Component, ElementRef, OnInit, Renderer2 } from '@angular/core';
import { TooltipDirective } from 'angular-bootstrap-md';
import { DashboardService } from 'src/app/services/dashboard.service';
import { Subscription } from 'rxjs';
import { MesReglementsService } from 'src/app/services/mes-reglements.service';
import { FilterService } from 'src/app/services/filter.service';

@Component({
  selector: 'app-dash-reglements',
  template: `<h5 class="text-center m-0 mb-2 main-subtitle"  style="min-height:20px;color:#878787">MES <span class="text-darkgreen" style="font-weight: 400">RÈGLEMENTS</span></h5>
            <div class="p-4 m-0" style="min-height:200px;" id="donut-container">
              <div *ngIf="mesReglements" class="d-flex flex-column justify-content-around align-items-start" 
                   style="grid-area: next;display:grid;align-content:center;justify-items:center"
              >
                <div class="d-flex flex-row my-1 text-center mt-4" style="color:#878787;line-height:1em">
                  Prochains règlements
                </div>

                <div class="d-flex flex-row w-100 justify-content-center card-warning my-1 text-center" style="font-weight:bolder;line-height: 1em;color:#C31D2B;">
                  {{ mesReglements[0].ProchaineEcheance }}
                </div>
                <div class="d-flex flex-row w-100 justify-content-center mb-1 text-center" style="color:#878787;line-height:1em">
                  Montant
                </div>
                <div class="d-flex flex-row w-100 justify-content-center card-warning my-1 text-center" style="font-weight:bolder;color:#C31D2B;line-height: 1em">
                
                  {{ mesReglements[0].montantestime }} €
                </div>
              </div>

              <div *ngIf="mesReglements" class="d-flex flex-column justify-content-center align-items-center w-100" style="grid-area: btnR">
                <div [routerLink]="'/mes-reglements'" class="badge badge-pill p-1 bt-hov" style="background-color:#83BA21;">                                                                                     
                  <span class="mx-3">VOIR PLUS</span>
                </div>
              </div>
              <div *ngIf="mesReglements" class="disclaimer my-2" style="display:grid;grid-area: discl;grid-column: 1 / -1;justify-self:flex-start">               
                <span style="font-size:0.6em;color:#878787">*Du total contractualisé avec BZ Collecte</span>
              </div>              
            </div>
            <div *ngIf="!mesReglements && noRegToShowMessage" class="row d-flex justify-content-center align-items-center w-100">
                <div class="card align-items-center justify-content-center p-4 w-100" style="min-height:286px">
                    <p class="text-culture custom-danger">{{noRegToShowMessage}}</p>
                </div>
            </div>`,
  styles: [
            ` @media screen and (max-width: 420px){
                  #donut-container{
                    display: grid;
                    justify-content:center;
                    align-content:center;
                    justify-items:center;
                    align-items:center;
                    grid-template-columns:100%;
                    grid-template-rows: 150px 117px 117px 60px 20px;
                    grid-template-areas: "dnt"
                                          "infos" 
                                          "next" 
                                          "btnR " 
                                          "discl";

                    .canva-infos{
                      #reg-infos{
                        margin-top: 0 !important;
                        font-size:100%;
                      }
                    }
                  }
              }

              @media screen and (min-width: 420px){
                #donut-container { 
                  display: grid; 
                  grid-template-columns:40% 30% 30%;
                  grid-template-rows: 80% 15% 5%;
                  grid-template-areas: "dnt infos next" 
                                        ". btnR ." 
                                        "discl . .";
                  justify-content:center;
                  align-content:center;
                  justify-items:center;
                } 
                .canva-infos{
                  #reg-infos{
                    margin-top: 0 !important;
                    font-size:100%;
                  }
                }
              }
            `
]

})
export class DashReglementsComponent implements OnInit {
  
    getReglements: Subscription;
    mesReglements;
    filtListener:Subscription;
    noRegToShowMessage;
  constructor(
    private _elementRef : ElementRef,
    private _dashServ: DashboardService,
    private _regServ: MesReglementsService,
    private _filtServ: FilterService) {
      this.filtListener = this._filtServ.filterChangeEmitter.subscribe(
        newFilter =>  {
          //console.log("listener Dash-mes-stocks")
          this.ngOnInit()
        }
      )
    }
  ngOnInit() {
  
    this._regServ.setReglements();
    this.getReglements = this._regServ.getReglements().subscribe(
      reglements => {
        if(reglements[0]){
          this.mesReglements = reglements;
          //console.log(this.mesReglements)
          const paid = this.mesReglements[0].montantregle;
          const leftToPay = this.mesReglements[0].montantavenir;
  
          const paidLabel = paid + " € PAYÉ";
          const lTPLabel = leftToPay + " € À PAYER";
          const el = this._elementRef.nativeElement.querySelector('#donut-container');
          el.style.display = 'grid';
          const infoEl = <HTMLElement>el.querySelector(".canva-infos")
          const formerCanvaEl = <HTMLElement>el.querySelector(".canvas-container");
          formerCanvaEl ? formerCanvaEl.remove():null;
          infoEl ? infoEl.remove():null;
          el.classList.add('card');
  
          el.insertAdjacentHTML(
              'afterbegin', 
                  '<div class="canvas-container p-0" style="grid-area: dnt;width:300px;display:grid;align-content:center;justify-items:center;">'+
                    '<canvas style="background: transparent url(/assets/icons/dashboard-green-svg/04-reglements.svg) no-repeat center center;background-size: 50% 50%" class="canvas"></canvas>'+
                  '</div>'+
                  '<div class="canva-infos" style="display:grid;align-content:center;justify-items:flex-start">' +
                    '<div id="reg-infos" class="d-flex justify-content-start align-items-start" style="font-size:0.9em">'+
                      '<i class="fas fa-square mr-2" style="color:#aacd69;font-size:1.2em"></i>'+
                      '<span class="text-legend" style="position:relative;color:#878787"> '+ paid +' € <br><span class="smaller-legend" style="position:relative">PAYÉ<i class="fas fa-asterisk" style="font-size:0.3em;position:absolute;top:-2px;right:-10px"></i></span></span>'+
                    '</div>'+
                    '<div id="reg-to-pay" class="d-flex justify-content-start align-items-start" style="font-size:0.9em">'+
                      '<i class="fas fa-square mr-2" style="color:#83BA21;font-size:1.2em"></i>'+
                    '<span class="text-legend" style="color:#878787">'+ leftToPay +' € <br><span class="smaller-legend" style="position:relative"> À PAYER<i class="fas fa-asterisk" style="font-size:0.3em;position:absolute;top:-2px;right:-10px"></i></span></span>'+
                    '</div>'+
                  '</div>'                 
          );
          let canva = el.querySelector('.canvas');
          // //console.log(canva) 
          
  
          let donutCtx = canva.getContext('2d');
  
          var data = {
                labels: [
                  paidLabel,
                  lTPLabel
                ],
                datasets: [
                    {
                        "data": [paid, leftToPay], 
                        "backgroundColor": [
                            "#AADB52",
                            "#83B920"
                        ]
                    }
                  ]
          }; 
          
          var chart = new Chart(
            donutCtx,
            {
                "type": 'doughnut',
                "data": data,
                "options": {
                    "cutoutPercentage": 65,
                    "animation": {
                        "animateRotate": true
                    },
                    "legend": { 
                      "display": false
                  },
                  "responsive":true,
                  tooltips: {
                    callbacks: {
                      label: function(tooltipItem, data) {
                        //console.log(tooltipItem);
                        //console.log(data);
                        // const value = tooltipItem.value;
                        return data.labels[tooltipItem.index];
                      },
                    }
                  }
                }
            }
          );   
          this.getReglements.unsubscribe();
        } else {
          this.noRegToShowMessage = "Pas de données";
          const el = this._elementRef.nativeElement.querySelector('#donut-container');
          el.style.display = 'none';
          this.mesReglements = null;
          this.getReglements.unsubscribe();
        }

          
        },error =>{
          this.getReglements.unsubscribe();
        }
        
    )
   
  }

  ngOnDestroy(){
    this.filtListener ? this.filtListener.unsubscribe():null;
  }

}
