import { Component, Inject, OnInit, AfterContentInit,Pipe, PipeTransform, ElementRef, ViewChild} from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Location, DOCUMENT } from '@angular/common';
import { takeWhile, skipWhile, switchMap } from 'rxjs/operators';
import { Subscription, BehaviorSubject, interval, pipe } from 'rxjs';
import { DashboardService } from 'src/app/services/dashboard.service';
import { ModalDirective } from 'angular-bootstrap-md';
import { FilterService } from 'src/app/services/filter.service';
import { ErrorService } from 'src/app/services/error.service';
import { MonGesService } from 'src/app/services/mon-ges.service';

import { Router, NavigationEnd } from '@angular/router';
import { ToastrService } from 'ngx-toastr';


@Component({
  selector: 'app-mon-ges-detail',
  templateUrl: './mon-ges-detail.component.html'
})


export class MonGesDetailComponent implements OnInit, AfterContentInit {

/*viewportWidth: any;*/
//Déclaration des variables local

getMaListeHistorique: Subscription;
gesListHistoSimu;
filterListener:Subscription;
noGesToShowMessage;
BtnSimuBool: boolean = true;
NextRoute;
localCampagneActive;
procedureSignatureActive;
paramList
getMaListSimuQuestion: Subscription;
listePalierGes
position_curseur
phraseResultatGes
subscribeParam
  graphVisible
  graphInitialise




@ViewChild('resultatcontainer',null) myDiv: ElementRef;
@ViewChild('cursor',null) cursor: ElementRef;


  constructor(private _filterServ: FilterService, private _elmtRef : ElementRef,private _gesServ: MonGesService, private _toaster: ToastrService) {
    //console.log("listener mon-ges")
    console.log("construction details")
  }

    EtapeEligibilite(tiersHistoSimu){
      console.log(tiersHistoSimu.statut_code);
      console.log(this.paramList.Tiers);
console.log(this.paramList.Tiers.attente_signature_TblLabeTiers);
console.log(this.paramList.Tiers.dossier_signe);


      if (tiersHistoSimu.statut_code == "GEPAC") {
        this.NextRoute = "/mes-labelisations/mon-ges/pre-questionnaire/GEPA";
      }else if (tiersHistoSimu.statut_code == "INCOMP") {
        this.NextRoute = "/mes-labelisations/mon-ges/questionnaire/";
      }else if (tiersHistoSimu.statut_code == "NOPRIM"){
        this.NextRoute = "/mes-labelisations/mon-ges/questionnaire/";
      }else if (this.paramList.Tiers.attente_signature_TblLabeTiers == true && this.paramList.Tiers.dossier_signe == 0){
        this.NextRoute = "/mes-labelisations/mon-ges/signature";
      }else if(this.paramList.Tiers.dossier_signe == 1){
        this.NextRoute = "/mes-labelisations/mon-ges/questionnaire/";
      }else{
        this.NextRoute = "/mes-labelisations/mon-ges/documentaire/";
      }
      //console.log(this.NextRoute);

      return tiersHistoSimu;
    }



onResize(event){
/*  if(this.listePalierGes && this.myDiv){
    //récupère la width disponible pour le graph
    var widthGraph = this.myDiv.nativeElement.getBoundingClientRect().width
    if(widthGraph > 0){

      //récupère la valeur max des paliers GES
      var max : number = Number(this.listePalierGes[0].min_TblLabeScorGesReel) + 300
      //récupère la valeur min des paliers GES
      var min : number = Number(this.listePalierGes[this.listePalierGes.length-1].max_TblLabeScorGesReel) - 300
      //récupère les valeur disponible entre min et max 
      var res :number = Number(max) - Number(min)

      //si la valeur est supérieur a la valeur maxi alors set la position du curseur a 0
      if(this.paramList.Tiers.Resultat_GES.valeur_ges_reelle_TblLabeResuGes >= max){
        this.position_curseur = 0 - (this.cursor.nativeElement.offsetWidth / 2)
        //si la valeur est inférieur au minimum alors set la position du curseur a la valeur max
      }else if(this.paramList.Tiers.Resultat_GES.valeur_ges_reelle_TblLabeResuGes <= min){
        this.position_curseur = widthGraph - (this.cursor.nativeElement.offsetWidth / 2)
      }else{
        //récupère la valeur valeur ges tiers
        var valeur_tiers = Number(this.paramList.Tiers.Resultat_GES.valeur_ges_reelle_TblLabeResuGes) - max
        if(valeur_tiers < 0){
          //récupère la valeur positive
          valeur_tiers = valeur_tiers* (-1)
        }
        //calcul le pourcentage ges du tiers et place le curseur en fonction de ce pourcentage
        this.position_curseur = (widthGraph * valeur_tiers/res) - (this.cursor.nativeElement.offsetWidth / 2)
      } 
    }
  }*/
}


  ngAfterContentInit(){
   
   interval(0).pipe(skipWhile(res => this.myDiv === undefined && !this.listePalierGes), takeWhile(res=>!this.graphInitialise && this.myDiv !== undefined)).subscribe(res=>{
     if(this.myDiv.nativeElement.getBoundingClientRect().width> 0){
       this.graphInitialise = true
       this.onResize(null)
     }
     
   })
  }

  subscribe_param

  ngOnInit() {
    window.scrollTo(0, 0);
    console.log("init");

    //console.log("ngOnInit -----> setGesSimuHisto");
    
    this._gesServ.campagneActive.subscribe(
      campagneActive =>{
        this.localCampagneActive = campagneActive;
      }
    )

    
    //met a jour quand on detecte un changement de structure
     this.subscribe_param = this._gesServ.getStructureSelectionnee().pipe(switchMap(res => this._gesServ.getReloadedStaticsData(res))).subscribe(
        (p) =>{
          var param = JSON.parse(p[0][""]).params[0]
          if(param){
            this.graphVisible = param.Tiers.Resultat_GES.eec_gCO2eq_kgMS_TblLabeResuGes == 0 && param.Tiers.Resultat_GES.esca_gCO2eq_kgMS_TblLabeResuGes == 0 && param.Tiers.Resultat_GES.valeur_ges_reelle_TblLabeResuGes == 0 ? false  : true; 
            this.paramList = param
            console.log(param)
            this.subscribeParam ? this.subscribeParam.unsubscribe():null;
            if(param.Tiers.Resultat_GES){
              if(param.Tiers.Resultat_GES.Score_ges == 0){
                this.phraseResultatGes = "À la lecture de votre score, vous n'êtes pas éligible à une prime."
              }else if(!param.Tiers.attente_signature_TblLabeTiers){
                this.phraseResultatGes = "Afin de valider votre score, insérez maintenant vos documents justificatifs dans l'espace dédié."
              }else{
                this.phraseResultatGes = ""
              }
            }
            this.remplirGes()
            
          }
          
        }
      )


  }

  returnBackgroundColorPalier(){
    if(this.listePalierGes){
      if(this.paramList){
        return this.listePalierGes[this.paramList.Tiers.Resultat_GES.Score_ges].couleur_TblLabeScorGesReel  
      }     
      
    }
  }




  

  remplirGes(){
    this._gesServ.setGesSimuHisto();

    
    console.log("la")
    this.getMaListeHistorique = this._gesServ.getGesSimuHisto().subscribe(
      tiersHistoSimu => {
        console.log(tiersHistoSimu)
        //console.log(tiersHistoSimu);
        if(tiersHistoSimu[0] == null && tiersHistoSimu[1] == "Aucune simulation d'éligibilité effectuée."){
          this.noGesToShowMessage = tiersHistoSimu[1];
          this.gesListHistoSimu = null;
          this.getMaListeHistorique.unsubscribe();
        } else {
          this.noGesToShowMessage = null;
          this.gesListHistoSimu = this.EtapeEligibilite(tiersHistoSimu[0]);
          if (this.gesListHistoSimu["statut"] == "Non primable") {
            this.BtnSimuBool = false;
          }
          
          this.getMaListeHistorique.unsubscribe();
        }  
        this.getMaListeHistorique ? this.getMaListeHistorique.unsubscribe():null;
      },error => {
        this.getMaListeHistorique.unsubscribe();
      }
    )

    var t = this._gesServ.getPalierResultatGes().subscribe(res=>{
      if(res){
        this.listePalierGes = res;
        this.onResize(null)
        t.unsubscribe();  
      }
      
    })
  }

  test_edi(){
    this._gesServ.getfileEDI()
  }
  ngOnDestroy(){
    this.filterListener ? this.filterListener.unsubscribe(): null;
    this.getMaListeHistorique ? this.getMaListeHistorique.unsubscribe() : null;
    this.subscribeParam ? this.subscribeParam.unsubscribe():null;
  }
}


