import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { AuthService } from 'src/app/services/auth.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ValidationService } from '../../services/validation.service';
import { LoaderService } from 'src/app/services/loader.service';
import { ToastrService } from 'ngx-toastr';
import { ToastrStateService } from 'src/app/services/toastr-state.service';

@Component({
  selector: 'app-premiere-connexion',
  templateUrl: './premiere-connexion.component.html'
})
export class PremiereConnexionComponent implements OnInit {

  firstConnexionForm:FormGroup;

  idBzUser;
  screenWidth;

  isSubmit = false;

  isMdpVisible = 0;

  errorServerMessage=null;

  
  shortPass = 'trop court';
  badPass = 'trop simple';
  goodPass = 'bon';
  strongPass = 'fort';
  middlePass="moyen";
  samePassword = 'login et mot de passe identique.';
  resultStyle = "";
  result=0;
  score = null;

  passTestMessage;

  constructor(
    private _fb: FormBuilder,
    private _authServ: AuthService,
    private _activRoute: ActivatedRoute, 
    private loaderService: LoaderService,
    private _toaster: ToastrService,
    private _toasterState: ToastrStateService,
    private _router: Router) { }

  ngOnInit() {
    this.idBzUser = this._activRoute.snapshot.paramMap.get("idBzUser");
    //console.log("OK premiere CO", this.idBzUser)

    this.firstConnexionForm = this._fb.group({
      email: ["",[Validators.required,Validators.email]],
      tiersCode: ["",[Validators.required]], //@todo mettre un pattern voir formats des code tiers <=
      mdp: ["",[Validators.required, Validators.pattern(/^(?=.[a-zA-Z0-9_@./#&+-?!]{8,15}$)\D*\d/)]],///[a-zA-Z0-9_@./#&+-]{9,16}$/
      confirmation: ["",[Validators.required]],
      idBzUser: [this.idBzUser ? this.idBzUser : "",[]]
    },
    {
      validator: [
        ValidationService.match('mdp','confirmation')
      ]
    })
    
  }

  connectToBZ(){

    this.isSubmit = true;
    if(!this.firstConnexionForm.valid && this.firstConnexionForm.touched){
      //console.log(this.firstConnexionForm)
      return;

    } else {
      //console.log(this.firstConnexionForm.value)
      this.loaderService.isLoading.next(true);
      this._authServ.firstConnexion(this.firstConnexionForm.value).subscribe(
        response => {
          //console.log(response);
          //Si  response contient quelquechose c'est forcément une erreur pour cette méthode
          if(response['success']){
            this._toaster.success(response['success'])
            this._router.navigate([''])
            
          } else {
            this.errorServerMessage = response['success'][0];
            this._toaster.error(response['error'])
          }
        }
      )
    }
  }

  showMdp(){
    this.isMdpVisible = this.isMdpVisible == 0  ? 1 : 0;
  }

  checkPasswordStrength(evt){
   const currentPassword = evt.target.value;
    // //console.log(this.teststrength(currentPassword,this.shortPass))
    this.passTestMessage = this.teststrength(currentPassword);
    //console.log(this.passTestMessage)
  }
  ngDoCheck(){
    this.screenWidth = window.innerWidth;
  }

  teststrength(password){
    this.score = 0; 
    
    //password < 8
    if (password.length < 9 ) {this.result=0;return this.shortPass; }
    
  //  //password length
    this.score += password.length * 4;
    this.score += ( this.checkRepetition(1,password).length - password.length ) * 1;
    this.score += ( this.checkRepetition(2,password).length - password.length ) * 1;
    this.score += ( this.checkRepetition(3,password).length - password.length ) * 1;
    this.score += ( this.checkRepetition(4,password).length - password.length ) * 1;
    //console.log(this.score)
    
    //password has 3 numbers
    if (password.match(/(.*[0-9].*[0-9].*[0-9])/)){ this.score += 5;} 
      
    //password has 2 symbols
    if (password.match(/(.*[!,@,#,$,%,^,&,*,?,_,~])/)){ this.score += 5 ;}
                                  //.*[!,@,#,$,%,^,&,*,?,_,~] deuxiemme symbole
      
    //password has Upper and Lower chars
    if (password.match(/([a-z].*[A-Z])|([A-Z].*[a-z])/)){  this.score += 10;} 
      
    //password has number and chars
    if (password.match(/([a-zA-Z])/) && password.match(/([0-9])/)){  this.score += 15;} 

    //password has number and symbol
    if (password.match(/([!,@,#,$,%,^,&,*,?,_,~])/) && password.match(/([0-9])/)){  this.score += 15;} 
      
    //password has char and symbol
    if (password.match(/([!,@,#,$,%,^,&,*,?,_,~])/) && password.match(/([a-zA-Z])/)){this.score += 15;}
      
    //password is just a numbers or chars
    if (password.match(/^\w+$/) || password.match(/^\d+$/) ){ this.score -= 10;}
      
    //verifying 0 < this.score < 100
    if ( this.score < 0 ){this.score = 0;this.result=0} 
    if ( this.score > 100 ){  this.score = 100;this.result=1} 
      
    if (this.score < 34 ){this.result=0; return this.badPass;}
    if (this.score < 50 ){this.result=1; return this.middlePass;} 
    if (this.score < 80 ){this.result=1;return this.goodPass;}

    return this.strongPass;
    
  };

       checkRepetition = function(pLen,str) {
        var res = "";
          for (var i=0; i<str.length ; i++ ) 
          {
              var repeated=true;
              
              for (var j=0;j < pLen && (j+i+pLen) < str.length;j++){
                  repeated=repeated && (str.charAt(j+i)==str.charAt(j+i+pLen));
                  }
              if (j<pLen){repeated=false;}
              if (repeated) {
                  i+=pLen-1;
                  repeated=false;
              }
              else {
                  res+=str.charAt(i);
              }
          }
          return res;
       };

}
