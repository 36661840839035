import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { FilterService } from 'src/app/services/filter.service';
import { MessageService } from 'src/app/services/message.service';
import { Subscription } from 'rxjs';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { ToastrStateService } from 'src/app/services/toastr-state.service';
import { ModalDirective } from 'angular-bootstrap-md';

@Component({
  selector: 'app-mes-messages',
  templateUrl: './mes-messages.component.html',
  styleUrls: ['./mes-messages.component.scss']
})
export class MesMessagesComponent implements OnInit {
  viewportWidth: number;
  messages;
  messagesDev;
  messagesToList;
  stateByRow = [];
  getMessages:Subscription;

  sendMessageSub:Subscription;
  modalForm: FormGroup;

  errorObjet;
  errorMail;
  errorMessage;

  p: number =1;
  @ViewChild('frame',null) messageModal: ModalDirective ;

  constructor(private _filtServ: FilterService, private _messageServ: MessageService,private _toaster:ToastrService, private _elemRef: ElementRef) {
    
  }


  ngOnInit() {
    window.scrollTo(0, 0);
    this.modalForm = new FormGroup({
      objet: new FormControl("",{
        validators: [Validators.required]
      }),
      to: new FormControl("",{
        validators: [Validators.required,Validators.email]
      }),
      message: new FormControl("",{
        validators: [Validators.required]
      })
    })

    if(!this._messageServ.messagesDests.getValue() || !this._messageServ.mesMessages.getValue() || !this._messageServ.messagesNonLusNbr.getValue()){
      this._messageServ.setAllMessages().subscribe(
        response => {
          //console.log(response)
          this._messageServ.messagesDests.next(response[1]);
          this.messagesToList = this._messageServ.messagesDests.getValue();
          const raw = JSON.parse(response[2]);
          //console.log(raw);
          const messages = raw.notifications.notification.message.map(
            not => {
              not['id']= not['@attributes'].id;
              not['date']= not['@attributes'].date;
              not['label']= not['@attributes'].label;
              not['nom']= not['@attributes'].nom;
              not['pjointe']= not['@attributes'].pjointe;
              not['extract']= not.contenu.substring(0,150);
              not['type']= not['@attributes'].type;
              not['state'] = not['@attributes']['lu'] != "" ? "LU" : "NON-LU";
              return not;
            }
          )
          this._messageServ.mesMessages.next(messages); 
          this.messages = this._messageServ.mesMessages.getValue();
          this._messageServ.messagesNonLusNbr.next(parseInt(raw['notifications']['@text']))
        }
      )
    } else {
      this.messagesToList = this._messageServ.messagesDests.getValue();
      this.messages = this._messageServ.mesMessages.getValue();
    }
  }

  readMessage(message){
    //console.log(this._messageServ.mesMessages.getValue());
    if(message.state == "NON-LU"){
      this._messageServ.readMessage(message['id']).subscribe(
        response => {
          //console.log(response);
          if(response == "OK-message-read"){
            const mess = message;
            mess.state = "LU";
            const currentMess = this._messageServ.mesMessages.getValue();
            const dex = currentMess.findIndex(ind => ind.id == message.id);
            currentMess.splice(dex,mess);
            this._messageServ.mesMessages.next(currentMess); 
            this.messages = this._messageServ.mesMessages.getValue();
            const currNbr = this._messageServ.messagesNonLusNbr.getValue();
            this._messageServ.messagesNonLusNbr.next(currNbr-1);
          }
          //console.log(response);
        }
      )
    }
  }

  submitMessage(){
    //console.log(this.modalForm.value)
    //console.log(this.modalForm)
    this.errorObjet = null;
    this.errorMail = null;
    this.errorMessage = null;
    if (this.modalForm.status == "INVALID") {
      if (this.modalForm.controls.objet.invalid) {
        if (this.modalForm.controls.objet.errors.required) {
          this.errorObjet = "  * Vous devez entrer un objet";       
        }    
      } 
      if (this.modalForm.controls.to.invalid){
        if (this.modalForm.controls.to.errors.required) {
          this.errorMail = "  * Vous devez entrer un email";       
        }
        if (this.modalForm.controls.to.errors.email) {
          this.errorMail = "  * Format d'email non valide";       
        }
      } 
      if(this.modalForm.controls.message.invalid){
        if (this.modalForm.controls.message.errors.required) {
          this.errorMessage = "  * Vous devez entrer un message";       
        }
      } 
      return false;
    } else {

      this.sendMessageSub = this._messageServ.sendemail(this.modalForm.controls.to.value,this.modalForm.controls.objet.value,this.modalForm.controls.message.value).subscribe(
        (response) =>{
          if(response['error']!=0){
            //console.log(response)
            this._toaster.error(response['msg']);
            this.sendMessageSub ? this.sendMessageSub.unsubscribe() : null; 
         } else if (response['error']==0){
           this._toaster.success(response['msg']);
           this.messageModal.hide();

          this.sendMessageSub ? this.sendMessageSub.unsubscribe() : null; 
         }
        }, 
        error => {
          //console.log(error);
          this.sendMessageSub ? this.sendMessageSub.unsubscribe() : null; 
        }
      )
    }
  }

  cancelMessage(){
    this.modalForm.reset();
  }

  expandRow(rowIdentifiant:string){

      //tr fixe message
      // const parentId = "fix-tr-" + rowIdentifiant;
      // const parentEl = document.getElementById(parentId);
      // const AllTds = Array.from(parentEl.children);
      // AllTds.forEach((td:HTMLElement,index)=>{
      //   td.style['border-bottom-left-radius'] = '0px';
      // })
      // parentEl.borderTopLeftRadius = 
      //trmessageContent
      const elId = "collapsable-tr-" + rowIdentifiant;
      const el = document.getElementById(elId);
      el.style.display = el.style.display == 'table-row' ? 'none' : 'table-row';
      // border-top-left-radius: 0px !important; 
      // border-top-right-radius: 0px !important;
  }

  rotate(evt){
    const el = evt.currentTarget;
    let caret = el.querySelector(".caret");
    caret.style.transform = caret.style.transform ==  "rotate(180deg)" ? "rotate(0deg)" : "rotate(180deg)";
  }

  ngDoCheck(){
    this.viewportWidth = window.innerWidth;
  }

  ngOnDestroy(){
    this.sendMessageSub ? this.sendMessageSub.unsubscribe() : null; 
    this.getMessages ? this.getMessages.unsubscribe() : null; 
  }

}
