import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "TRHighltight"
})
export class TRHighltight implements PipeTransform {
  transform(value: string,selected: string): any {
      //console.log("pipeValue ==> ", value)
      //console.log("pipeSelected ==> ", selected)
    let result = null;
    if (value == selected) {   
      result = `<span class="highlighted">${value}</span>`;
    } else {
      result = `<span>${value}</span>`;
    }
    return result;
  }
}