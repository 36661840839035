import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { MaCommercialisationService } from 'src/app/services/ma-commercialisation.service';
import { FilterService } from 'src/app/services/filter.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ButtonService } from 'src/app/services/button.service';
import { ToolsService } from 'src/app/services/tools.service';
import { DownloadService } from 'src/app/services/download.service';
import { ToastrService } from 'ngx-toastr';
import { environment } from 'src/environments/environment';
import { CookieService } from 'ngx-cookie-service';
import { UserService } from 'src/app/services/user.service';
import { AuthService } from 'src/app/services/auth.service';
import { ContratsService } from 'src/app/services/contrats.service';

@Component({
  selector: 'app-ma-commercialisation-detail',
  templateUrl: './ma-commercialisation-detail.component.html',
  styleUrls: ['./ma-commercialisation-detail.component.scss']
})
export class MaCommercialisationDetailComponent implements OnInit {
  getComm: Subscription;
  getCamp: Subscription;
  listenerSub: Subscription;
  dlSub: Subscription;
  verifyUserRole:Subscription;
  
  commercialisationDetail;
  commercialisationSelected;
  btnState;

  contratSelected;
  contratSelectedTDoc
  typeContratSelected;
  detailsContrats;

  qualitesSelected;
  detailsSelected;

  noComToShowMessage;

  viewportWidth
  p: number = 1; //page de depart pagination
  selectedCamp;
  _env = environment;
  param = this._activRoute.snapshot.paramMap.get("produit");

  constructor(
    private _maComServ: MaCommercialisationService, private _dlServ: DownloadService,
    private _filtServ: FilterService, private _toaster: ToastrService,
    private _activRoute: ActivatedRoute,
    private _btnServ: ButtonService,private _tools: ToolsService,
    private _router: Router, private _cookieServ: CookieService,
    private _userServ: UserService, private _authServ: AuthService,
    private _contratServ: ContratsService 
  ) {  
    this.listenerSub = this._filtServ.filterChangeEmitter.subscribe(newFilter => {
      //console.log("listener commercialisation");
      this.ngOnInit();
    })
  }

  ngOnInit() {
    window.scrollTo(0, 0)
    if(this._userServ.userRole.getValue() == null){
      this.verifyUserRole = this._userServ.getUserDetail().subscribe(
        response => {

          this._userServ.userRole.next(response['compte']['structure'][0]['profil']);
          if(this._cookieServ.get('userProfile') != this._userServ.userRole.getValue()){
            this._toaster.error("Votre rôle ne correspond pas à celui enregistré dans notre base de données. Retour Login");
            setTimeout(() => {
              this._authServ.logOut();
              this._router.navigate(['']);              
            }, 2000);
          }
        },
        error => {
          //console.log(error)
          this.verifyUserRole ? this.verifyUserRole.unsubscribe() : null;
        }
      )
    } else {
      if(this._cookieServ.get('userProfile') != this._userServ.userRole.getValue()){
        this._toaster.error("Votre rôle ne correspond pas à celui enregistré dans notre base de données. Retour Login");
        setTimeout(() => {
          this._authServ.logOut();
          this._router.navigate(['']);              
        }, 2000);
      }
    }

    const testProductName = this._env.BZ_PRODUCTS_NAMES.filter(prodName => prodName == this.param);
      //console.log(testProductName)
      if(testProductName.length == 0){
        this._router.navigate(['404'])
        return;
      } else {
        //console.log("init com detail")
        this.getCamp = this._filtServ.selectedCamp.subscribe(
          year => {
            //console.log(year)
            this.selectedCamp  = year;
          }
          )
          let prm = this._activRoute.snapshot.paramMap.get("produit");
          const param = this._tools.cleanParam(prm);
          //console.log(param)
          this._maComServ.setCommercialisation();
          this.getComm = this._maComServ.getCommercialisation().subscribe(
          macommerce =>{
            //console.log(macommerce)
            if(macommerce[0] && macommerce[1] != "pas de données"){

                const myDetail = macommerce.filter((com)=>{
                return com.culture === param;
              })

              //console.log(myDetail)
              if(myDetail.length > 0){
                this.commercialisationDetail = myDetail;
                macommerce.forEach(element => {
      
                  if(element.culture === param){
                    this.noComToShowMessage = null;
                    this.commercialisationSelected = element.typecontrat;
                    //console.log(this.commercialisationSelected)
                    this.contratSelected = this.commercialisationSelected[0].contrat[0]['contrat.id'];
                    this.selectContrat(this.commercialisationSelected[0].contrat[0]['contrat.id'],this.commercialisationSelected[0].contrat[0].tdoc)
                    this.detailsSelected = 
                    { 
                      'type': this.commercialisationSelected[0].contrat[0]['contrat.typecontrat'],
                      'frais': this.commercialisationSelected[0].contrat[0]['contrat.fraisstockage'],
                      'observation': this.commercialisationSelected[0].contrat[0]['contrat.observation']
                    }
                    this.qualitesSelected = this.commercialisationSelected[0].contrat[0]['qualites'];
                    this.detailsContrats = this.commercialisationSelected[0].contrat;
                    //console.log(this.commercialisationSelected);
                    this.getComm.unsubscribe();
                  }
                })
              } else {
                //console.log("CASE NO DATA SELECTED")
                this.noComToShowMessage = "pas de données";
                this.commercialisationSelected = null;
                this.contratSelected = null;
                this.detailsSelected = null;
                this.qualitesSelected = null;
                this.getComm.unsubscribe();
              }
            } else {
              //console.log("CASE NO DATA")
              this.noComToShowMessage = macommerce[1];
              this.commercialisationDetail = null;
              this.contratSelected = null;
              this.detailsSelected = null;
              this.qualitesSelected = null;
              this.getComm.unsubscribe();
            }
          }
        )
      }    
  }

  ngDoCheck(){
    this.btnState = this._btnServ.getBtnState();
    //console.log(this.btnState)
    this.viewportWidth = window.innerWidth;
  }

  selectContrat(contratId:string,contratTdoc:string){
    //console.log(contratTdoc)
    this.contratSelected = contratId;
    this.contratSelectedTDoc = contratTdoc;
  }

  setQualites(qualites){
    // //console.log(qualites)
    this.qualitesSelected = qualites;
  }

  setDetailsContrat(type,frais,observation){
    this.detailsSelected = {
      'type': type,
      'frais': frais,
      'observation': observation
    }
  }

  selectFirst(evt){
    const firstDex = (evt - 1) * 3;;
    //console.log(this.detailsContrats[firstDex])
    this.selectContrat(this.detailsContrats[firstDex]['contrat.id'],this.detailsContrats[firstDex]['tdoc'])
    this.setQualites(this.detailsContrats[firstDex].qualites)
    this.setDetailsContrat(this.detailsContrats[firstDex]['contrat.typecontrat'],this.detailsContrats[firstDex]['contrat.fraisstockage'],this.detailsContrats[firstDex])
    // this.showDetails(this.resutats[0]['analyses'][firstDex]['analyses.identifiant'])
  }

  reset(){
    //console.log(this.commercialisationDetail)
    //console.log(this._maComServ.typeContratComSelected.getValue());
    this.p = 1;

    const detail = this.commercialisationDetail[0].typecontrat.filter(
      detailObj => detailObj['typecontrat.libelle'] == this._maComServ.typeContratComSelected.getValue()
    );
    this.commercialisationSelected = detail;
    //console.log(detail)
    this.contratSelected = detail[0].contrat[0]['contrat.id'];
    this.qualitesSelected = detail[0].contrat[0]['qualites'];
    this.detailsContrats = detail[0].contrat;
    //console.log(this.contratSelected)
    //console.log(this.detailsContrats)
    this.detailsSelected = { 'type': detail[0].contrat[0]['contrat.typecontrat'],'frais': detail[0].contrat[0]['contrat.fraisstockage'],
      'observation': detail[0].contrat[0]['contrat.observation']
    };

  }

  ngOnDestroy(){
    //console.log("unsub com detail")
    this.getCamp ? this.getCamp.unsubscribe():null;
    this.getComm ?  this.getComm.unsubscribe():null;
    this.listenerSub ?  this.listenerSub.unsubscribe():null;
  }

  downloadDoc(docId,docTDoc){ 
    //console.log(docId,docTDoc)
    if(!docId || !docTDoc){
      this._toaster.error("Aucun type ou nom de document trouvé! Echec du téléchargement")
    } else {
      this.dlSub = this._dlServ.downloadDocument(docId,docTDoc).subscribe(
        response => {
            //console.log(response)
            if(response['type'] == "application/download"){
              let dataType = response['type'];
              let binaryData = [];
              binaryData.push(response);
              let downloadLink = document.createElement('a');
              //console.log(downloadLink)
              downloadLink.href = window.URL.createObjectURL(new Blob(binaryData, {type: dataType}));
              downloadLink.setAttribute('download',docTDoc + "-" + docId);
              document.body.appendChild(downloadLink);
              downloadLink.click();
            } else {
              this._toaster.error("Aucun document trouvé! Echec du téléchargement")
            }
          
        }, 
        error => {
          //console.log(error);
        }
      )
    }
  }

  blockContrat(contratId){
    if(this._cookieServ.get('userProfile') != this._userServ.userRole.getValue()){
      this._toaster.error("Votre rôle ne correspond pas à celui enregistré dans notre base de données. Retour Login");
      setTimeout(() => {
        this._authServ.logOut();
        this._router.navigate(['']);              
      }, 2000);
    } else {
      //console.log(contratId)
      this._contratServ.contratIndexesFromCommercialisation.next({contratId: contratId,culture: this.param})
      this._router.navigate(['mes-contrats-indexes']);
    }
    // //console.log("coucou")

  }


}
