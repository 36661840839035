import { Component, OnInit } from '@angular/core';
import { MesStocksService } from 'src/app/services/mes-stocks.service';
import { Subscription } from 'rxjs';
import { FilterService } from 'src/app/services/filter.service';
import { share, debounceTime } from 'rxjs/operators';

@Component({
  selector: 'app-resutats-analyse',
  templateUrl: './resutats-analyse.component.html',
  styleUrls: ['./resutats-analyse.component.scss']
})
export class ResutatsAnalyseComponent implements OnInit {


  getMesResultatsAnalyse:Subscription;
  filterListener:Subscription;
  getCamp:Subscription;


  nbrAnalyses;
  resutats;
  noAnalyseToshow;

  selectedCamp;
  p: number = 1;

  selected;
  selectedDetail;

  constructor(private _stockServ: MesStocksService,private _filtServ: FilterService) {
    this.filterListener = this._filtServ.filterChangeEmitter.subscribe(
      newFilter =>  {
        //console.log("JEE passe!")
        this.ngOnInit()
      }
    )
   }
 
   ngOnInit() {
    window.scrollTo(0, 0);
    this.getCamp = this._filtServ.selectedCamp.subscribe(
      year => {
        //console.log(year)
        this.selectedCamp  = year;
      }
    )

    if(this._filtServ.filterValue.getValue()){
      this.getMesResultatsAnalyse = this._stockServ.getResultatsAnalyses(this._filtServ.filterValue.getValue()).subscribe(
        response => {
          //console.log(response)

          if(response[0][""] == "" && response[0]['erreur'] == "0" &&  response[0]['msgerreur'] == "pas de données"){
            this.resutats = null;
            this.noAnalyseToshow = "Aucun résultat d'analyse à afficher."
          } else {
            const res = JSON.parse(response[0][""]);
            this.nbrAnalyses = res['MesAnalyses'][0].nb;
            this._stockServ.mesStocksAnalyses.next(res['MesAnalyses'])
            this._stockServ.nbreAnalyses.next(res['MesAnalyses'][0].nb);
            this.resutats = this._stockServ.mesStocksAnalyses.getValue();
            this.showDetails(this.resutats[0]['analyses'][0]['analyses.identifiant'])
            //console.log(this.resutats)
            // //console.log(this._stockServ.mesStocksAnalyses.getValue())
            
            this.getMesResultatsAnalyse.unsubscribe();
          }
        },
        error => {
          this.getMesResultatsAnalyse.unsubscribe();
        }
      )

    } 
//     else {
//       this._stockServ.nbreAnalyses.subscribe(
//         nbre => {
//           this.nbrAnalyses = nbre;
//         }
//       );
//       this.resutats = this._stockServ.mesStocksAnalyses.getValue();
//       if(this.resutats){
//         this.showDetails(this.resutats[0]['analyses'][0]['analyses.identifiant'])
//       }
//      }

     
     
    
    //console.log(this._stockServ.mesStocksAnalyses.getValue())
  }


  selectFirst(evt){
    const firstDex = evt <= 1 ? 0 : evt;
    this.showDetails(this.resutats[0]['analyses'][firstDex]['analyses.identifiant'])
  }

  showDetails(selectedId){
    this.selected = selectedId;
    const dex = this.resutats[0]['analyses'].findIndex(elem => elem['analyses.identifiant'] == selectedId);
    // //console.log(dex)
    this.selectedDetail = this.resutats[0]['analyses'][dex].qualites;
    //console.log(this.selectedDetail)
  }

  ngOnDestroy(){
    this.getMesResultatsAnalyse ? this.getMesResultatsAnalyse.unsubscribe():null;
    this.getCamp ? this.getCamp.unsubscribe():null;
    this.filterListener ? this.filterListener.unsubscribe():null;
  }

}
