import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "spacePipe"
})
export class whiteSpacePipe implements PipeTransform {
  transform(value: string): any {
    const regexp = /\s+/g;
    const result = value.replace(regexp, "");
    return result;
  }
}