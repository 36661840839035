import { Component, OnInit, ElementRef, Renderer2 } from '@angular/core';
import { DashboardService } from 'src/app/services/dashboard.service';
import { Subscription } from 'rxjs';
import { Produit } from 'src/app/models/produit';
import { ErrorService } from 'src/app/services/error.service';
import { MesStocksService } from 'src/app/services/mes-stocks.service';
import { FilterService } from 'src/app/services/filter.service';


@Component({
  selector: 'app-dash-mes-stocks',
  templateUrl: './dash-mes-stocks.component.html'
})


export class DashMesStocksComponent implements OnInit {
  
  stocks;
  barWidth: Number;
  isSetBarsOk: boolean = false;

  getMesStocks:Subscription;
  filtListener: Subscription;
  isAclError:string;
  count=0;

  noStockMessage;

  constructor(
    private dashServ: DashboardService,
    private _elementRef : ElementRef,
    private renderer: Renderer2,
    private _errorServ: ErrorService,
    private _stockServ: MesStocksService,
    private _filtServ: FilterService) {
      this.filtListener = this._filtServ.filterChangeEmitter.subscribe(
        newFilter =>  {
          //console.log("listener Dash-mes-stocks")
          this.ngOnInit()
        }
      )
    }


  ngOnInit() {  
    // //console.log("init mes stocks dashboard")
    this._stockServ.setMesStocks();
      this.getMesStocks = this._stockServ.getStocks().subscribe(
        stocks => {
          //console.log(stocks);
          if(stocks[0] == null && stocks[1] == "pas de données"){
            //console.log("pas de données CASE !")
            this.noStockMessage = "Pas de données";
            this.stocks = null;
            this.getMesStocks.unsubscribe();
          } else {
            this.stocks = stocks;
            this.getMesStocks.unsubscribe();
          }
        },
        error => {
          this.getMesStocks.unsubscribe();
        }
      )  
  }
  
  ngOnDestroy(){
    this.getMesStocks ? this.getMesStocks.unsubscribe():null;
    this.filtListener ? this.filtListener.unsubscribe():null;
  }

  ngDoCheck(){
    this.isAclError = this._errorServ.getMessage();
  }

}
