import { Component, OnInit } from '@angular/core';
import { FilterService } from 'src/app/services/filter.service';
import { MesReglementsService } from 'src/app/services/mes-reglements.service';
import { Subscription } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { ButtonService } from 'src/app/services/button.service';
import { ToolsService } from 'src/app/services/tools.service';
import { DownloadService } from 'src/app/services/download.service';
import { ToastrService } from 'ngx-toastr';
import { ErrorService } from 'src/app/services/error.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-reglements-detail',
  templateUrl: './reglements-detail.component.html'
})
export class ReglementsDetailComponent implements OnInit {

  constructor(
    private _filtServ: FilterService, 
    private _regServ: MesReglementsService, 
    private _activRoute: ActivatedRoute, 
    private _btnServ: ButtonService, 
    private _tools: ToolsService,
    private _toaster: ToastrService,
    private _downloadServ: DownloadService,
    private _routerActi: ActivatedRoute,
    private _router: Router,
    private _errorServ: ErrorService) {
      this.btnState = this._btnServ.getBtnState();
      this.filtRegListener = this._filtServ.filterChangeEmitter.subscribe(
        newFilter =>  {
          this.ngOnInit()
        }
      )
    }
    _env = environment;   
    reglementsAvenir;
    reglementsEffectues;
    
    btnState;
    
    getReglements: Subscription;
    dlDocuments: Subscription;
    getCamp:Subscription;
    filtRegListener:Subscription; 
    
    selectedReglement;
    selectedFacture;
    detail = [];
    factures;
    p: number = 1;

    selectedCamp;

    noRegToShowMessage;
    param = this._routerActi.snapshot.paramMap.get("produit");
    
    ngOnInit() {
    window.scrollTo(0, 0)
    this._errorServ.ErrorMode.next(false);
    const testProductName = this._env.BZ_PRODUCTS_NAMES.filter(prodName => prodName == this.param);
    //console.log(testProductName)
    if(testProductName.length == 0){
      this._errorServ.ErrorMode.next(true);
      this._router.navigate(['404'])
      return;
    } else {
      this.getCamp = this._filtServ.selectedCamp.subscribe(
        year => {
          //console.log(year)
          this.selectedCamp  = year;
        }
      )
      this._regServ.setReglements();
      const param = this._activRoute.snapshot.paramMap.get("produit");
      const safeParam = this._tools.cleanParam(param);
      //console.log(safeParam);
      this.getReglements = this._regServ.getReglements().subscribe(
        reglements => {
          //console.log(reglements);
  
          if(reglements[0] != null){
            
            const myDetail = reglements[0].culture.filter((liv)=>{
              return liv['cult.culture'] === param;
            })
  
            if(myDetail.length > 0){
              this.noRegToShowMessage = null;
              this.detail = []
              reglements[0].culture.map(
                regObj =>{
                  // //console.log(regObj['cult.culture']);
                  if(regObj['cult.culture']== safeParam){
                    if(regObj.AVENIR){
                      this.reglementsAvenir = regObj.AVENIR;
                      this.selectedReg(this.reglementsAvenir[0]['avenir.cle']+"0");
                      this.setDetail(this.reglementsAvenir[0])
                    }
    
                    if(regObj.effectue){
                      this.reglementsEffectues = regObj.effectue;
                      //console.log(this.reglementsAvenir)
                      //console.log(this.reglementsEffectues)
                      this.selectFacture(this.reglementsEffectues[0])
                    }
                  }          
                } 
              )
            } else {
              //console.log("CASE HERE")
              this.reglementsAvenir = null;
              this.reglementsEffectues = null;
              this.selectedReglement = null;
              this.detail = [];
              this.selectedFacture = null;
              this.noRegToShowMessage = "pas de données";
            }
          } else {
            //console.log("CASE HERE")
            this.reglementsAvenir = null;
            this.reglementsEffectues = null;
            this.selectedReglement = null;
            this.detail = [];
            this.selectedFacture = null;
            this.noRegToShowMessage = "pas de données";
          }
        },
        error => {
          //console.log(error);
        }
      )

    }

  }

  selectedReg(selected){
    this.selectedReglement = selected;
    //console.log(this.selectedReglement);
  }

  selectFacture(facture){
    this.selectedFacture = facture;
    //console.log(this.selectedFacture );
  }

  setDetail(reg){
    this.detail = [];
    //console.log(reg)
    //console.log(reg['AVENIRDETAIL'])
    const detailObj = reg['AVENIRDETAIL'];
    if(detailObj){
      detailObj.forEach(element => {
        const detailObj = {
          numContrat: element['avenirdetail.contrat'],
          typeContrat: element['avenirdetail.type'],
          tonnes: element['avenirdetail.tonnes'],
          prix: element['avenirdetail.prix'],
          tdoc: element['avenirdetail.tdoc'],
          docname: element['avenirdetail.docname']
        };
        this.detail.push(detailObj);
        //console.log(this.detail);
      });
    }

  }

  downloadDetailsContrat(details){
    //console.log(details)
    if(details){
      details.forEach(element => {
        this.dlDocuments = this._downloadServ.downloadDocument(element.numContrat,element.tdoc).subscribe(
          response => {
            //console.log(response);
            if(response['type'] == "application/download"){
              let dataType = response['type'];
              let binaryData = [];
              binaryData.push(response);
              let downloadLink = document.createElement('a');
              //console.log(downloadLink)
              downloadLink.href = window.URL.createObjectURL(new Blob(binaryData, {type: dataType}));
              downloadLink.setAttribute('download',element.numContrat);
              document.body.appendChild(downloadLink);
              downloadLink.click();
            } else {
                this._toaster.error("Erreur télechargement du contrat " + element.numContrat)
  
            // this.mvmntFilename.next(fileName);
                this.dlDocuments.unsubscribe();
            }
          },
          error => {
            this.dlDocuments.unsubscribe();
          } 
        )
      });      
    }
  }

  downloadFacture(){
    this.dlDocuments = this._downloadServ.downloadDocument(this.selectedFacture['effectue.tdocname'],this.selectedFacture['effectue.tdoc']).subscribe(
      response => {
        //console.log(response);
        //console.log(response);
          if(response['type'] == "application/download"){
            let dataType = response['type'];
            let binaryData = [];
            binaryData.push(response);
            let downloadLink = document.createElement('a');
            //console.log(downloadLink)
            downloadLink.href = window.URL.createObjectURL(new Blob(binaryData, {type: dataType}));
            downloadLink.setAttribute('download',this.selectedFacture['effectue.tdocname']);
            document.body.appendChild(downloadLink);
            downloadLink.click();
            this.dlDocuments.unsubscribe();
          } else {
            this._toaster.error("Aucun document trouvé! Echec du téléchargement")
          }

        // this.mvmntFilename.next(fileName);
        this.dlDocuments.unsubscribe();
      },
      error => {
        this.dlDocuments.unsubscribe();
      } 
    )
  }

  selectFirst(evt,from){
    const firstDex = (evt - 1) * 3;
    if(from == 'avenir'){
      this.selectedReg(this.reglementsAvenir[firstDex]['avenir.cle']+"0");
      this.setDetail(this.reglementsAvenir[firstDex])
    }
    
    // this.showDetails(this.resutats[0]['analyses'][firstDex]['analyses.identifiant'])
  }

  ngDoCheck(){
    this.btnState = this._btnServ.getBtnState();
  }

  ngOnDestroy(){
    this.filtRegListener ? this.filtRegListener.unsubscribe():null;
    this.getCamp ? this.getCamp.unsubscribe():null;
    this.getReglements ? this.getReglements.unsubscribe():null;
  }

}
