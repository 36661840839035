import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { FilterService } from 'src/app/services/filter.service';
import { OffresService } from 'src/app/services/offres.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-offres',
  templateUrl: './offres.component.html'
})
export class OffresComponent implements OnInit {
  
  constructor(private _filtServ: FilterService, private _offreServ: OffresService,private elref: ElementRef) { }

  @ViewChild ('wrapperOffres',null) container: ElementRef;
  getOffres:Subscription;
  offres = this._offreServ.offresSubj.getValue();

  offresUntouched = true;
  
  ngOnInit() {
    window.scrollTo(0, 0)
  }


  show(destination){
    this.offresUntouched = false;
    //console.log(destination)
    this._offreServ.showOffre(destination);
    setTimeout(() => {
      this.container.nativeElement.scrollIntoView({behavior: "smooth", block: "end", inline: "nearest"});
    }, 200);

  }

  ngOnDestroy(){
    //console.log("unsub offre");
    // this.getOffres.unsubscribe();
  }
}
