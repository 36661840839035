import { Injectable, EventEmitter } from '@angular/core';
import { BehaviorSubject, Subject, Subscription } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class FilterService {

  private isExpanded = new BehaviorSubject<string>("closed");
  public  filterState = this.isExpanded.asObservable();
  
  public filter = new Subject<any>(); // Faire un modèle filtre
  public filterValue = new BehaviorSubject<any>(null);

  public selectedCamp = new BehaviorSubject<any>(null);

  private getInitFilter: Subscription;

  public filterChangeEmitter: EventEmitter<any>;

  _env = environment;
  
  constructor(private _http: HttpClient) {
    this.filterChangeEmitter = new EventEmitter();
  }   

  emitFilterEvent(filterValue):void {
    //console.log("emit filter action",filterValue)
    this.filterChangeEmitter.emit(filterValue)
  }

  changeFilterState(state: string) {
    state = state == "closed" ? "expanded" : "closed";
    // //console.log(state);
    this.isExpanded.next(state);
  }

  getFilterState(){
    return this.isExpanded;
  }
  
  initFilter(){
    //console.log("init filtrer");
    //get du filtre depuis l'api ou depuis le service lui mm si il n'est pas null (boilerplate)
    if(!this.filterValue.getValue()){
      this.getInitFilter = this._http.get(this._env.BZ_API_BASE_URL + "nxfilter/getfilter/format/json",{withCredentials: true}).subscribe(
        filter =>{
          // //console.log(filter)
          const filtConf = this.createFilterFromCheckedValues(filter);
          this.selectedCamp.next(filtConf['camp']);
          this.filterValue.next(filter);
          this.emitFilterEvent(this.filterValue.getValue())
          this.getInitFilter.unsubscribe();
        },
        error => {
          //console.log(error);
          this.getInitFilter.unsubscribe();
        } 
      )
    } 
  }

  getFilter(){
    //renvoye le filtre 
    // //console.log("filterValue dans le filtService",this.filterValue.getValue())
    
    return this.filterValue.asObservable();
  }

  setFilter(filterConf){
    // //console.log("set filter")
    // //console.log(filterConf);
    const filtConf = this.createFilterFromCheckedValues(filterConf);
    this.selectedCamp.next(filtConf['camp']);
    this.filterValue.next(filterConf);
    this.emitFilterEvent(filterConf);
    
  }


  // modifyFilter(){

  // }

  recordNewFilter(){
    const filter = this.createFilterFromCheckedValues(this.filterValue);
    //console.log(filter);
    const filterData = new FormData();
    filterData.append("filter",JSON.stringify(filter));
    this._http.post(this._env.BZ_API_BASE_URL + 'nxfilter/getfilter/format/json',filterData,{withCredentials:true}).subscribe(
      response => {
        //console.log(response);
      }
    )
    // enregistre la selection quand click sur "enrigstrer"
  }

  createFilterFromCheckedValues(rawFilterObj){
    const filter = {};
    if(rawFilterObj){
      rawFilterObj.camps.camp.map(
        x=>{
          if(x['@attributes'].checked == 1){
            filter['camp'] = parseInt(x['@attributes'].idcamp); 
          }
        }
      )
      const values = []; 
      rawFilterObj.cultures.culture.forEach(element => {
        // //console.log(element);
          if(element['@attributes'].checked == 1){
            values.push(element['@attributes'].clecu);
          }
      })
      filter['cultures'] = values.join(";")
  
      const structures = [];
      // //console.log(typeof(rawFilterObj.structures.structure))
      if(Array.isArray(rawFilterObj.structures.structure)){ 
        rawFilterObj.structures.structure.forEach(element => {
          // //console.log(element);
            if(element['@attributes'].checked == 1){
              structures.push(element['@attributes'].idti);
            }
        })
      } else {
        
        structures.push(rawFilterObj.structures.structure['@attributes'].idti);
      }
      
      // if(structures.length == 0){
      //   cons
      // }

  
      filter['structures'] = structures.join(";")
      filter['modify'] = false;
  
      return filter;
    }

  }
}
