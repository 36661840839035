import { CanActivate,
    ActivatedRouteSnapshot,
    RouterStateSnapshot,
    Router,
    ActivatedRoute
   } from "@angular/router";

import { Observable } from 'rxjs';
import { Location } from '@angular/common';
import { Injectable } from '@angular/core';
import { AuthService } from './auth.service';
import { environment } from 'src/environments/environment';

@Injectable()
export class errorProductName implements CanActivate{

    _env = environment;
    param = this._activRoute.snapshot.paramMap.get("produit");

    constructor(private _authServ: AuthService, private _router: Router, private _activRoute: ActivatedRoute, private _loc: Location){}

    canActivate(route: import("@angular/router").ActivatedRouteSnapshot, state: import("@angular/router").RouterStateSnapshot): boolean | import("@angular/router").UrlTree | import("rxjs").Observable<boolean | import("@angular/router").UrlTree> | Promise<boolean | import("@angular/router").UrlTree> {

        //console.log(this.param)
        let result = true;

        // const testProductName = this._env.BZ_PRODUCTS_NAMES.filter(prodName => prodName == this.param);
        // //console.log(testProductName,"CASE NO PRODUCT 404")
        // if(testProductName.length == 0 ){
        //     result = false;
        //     this._router.navigate(['404'])
        // } else {
        //     result = true;
        // }


    return result;
}
}