import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'matifInfoTableau'
})
export class MatifInfoTableauPipe implements PipeTransform {

  transform(value: string): any {
    let result = null;
    let text = value.substring(0,11);
    let year = value.substring(value.length-2)
    let valToTranslate = value.substring(12)
    // //console.log(text);
    // //console.log(year);
    // //console.log(valToTranslate)

    if(valToTranslate.startsWith("Jan")){
      result = text + "Janvier/" + year;
    } else if(valToTranslate.startsWith("Feb")){
      result = text + "Février " + year;
    } else if(valToTranslate.startsWith("Mar")){
      result = text + "Mars " + year;
    } else if(valToTranslate.startsWith("Apr")){
      result = text + "Avril" + year;
    } else if(valToTranslate.startsWith("May")){
      result = text + "Mai" + year;
    } else if(valToTranslate.startsWith("Jun")){
      result = text + "Juin " + year;
    } else if(valToTranslate.startsWith("Jul")){
      result = "Juillet " + year;
    } else if(valToTranslate.startsWith("Aug")){
      result = text + "Aôut " + year;
    } else if(valToTranslate.startsWith("Sep")){
      result = text + "Septembre " + year;
    } else if(valToTranslate.startsWith("Oct")){
      result = text + "Octobre " + year;
    } else if(valToTranslate.startsWith("Nov")){
      result = text + "Novembre " + year;
    } else if(valToTranslate.startsWith("Dec")){
      result = text + "Décembre " + year;
    }

    return result;
  }

}
