import { Component, OnInit, ViewChild} from '@angular/core';
import { FormsModule, ValidationErrors, FormGroup, ValidatorFn, NgForm, AbstractControl, FormControl, Validators, ReactiveFormsModule } from '@angular/forms';
import { Subscription, BehaviorSubject, interval } from 'rxjs';
import { FilterService } from 'src/app/services/filter.service';
import { UserService } from 'src/app/services/user.service';
import { MonSaiService } from 'src/app/services/mon-sai.service';
import { Router, NavigationEnd } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { ModalDirective, MDBModalService} from 'angular-bootstrap-md';
import { map } from 'rxjs/operators';


@Component({
  selector: 'app-questionnaire-sai',
  templateUrl: './questionnaire-sai.component.html'
})


export class QuestionnaireSaiComponent implements OnInit {

  textIntroVisibility = false;
  textConclusionVisibility = false;
  nombreTotalQuestion = 0;
  nombreTotalReponses = 0;
  paramList;
  listeQuestion;
  themeObjSelection;
  numeroTheme;
  pourcentageAvancementQuestion : number = 0;
  listeAxes;
  info_user;

  resultatSai;

  countEssentiel;
  countIntermediaire;
  countAvance;

  //toutes les questions dans la même thématique sont sur N/A automatiquement
  liste_question : Array<number> = [184,185, 187,188,189,190]

  complementQuestionAffiche;

  @ViewChild("exigenceModal",null) exigenceModal:ModalDirective;
  @ViewChild("finQuestionnaireModal",null) finQuestionnaireModal:ModalDirective;

  constructor(private _filterServ: FilterService,private _saiServ: MonSaiService, private _toaster: ToastrService, private _rooter : Router, private _userServ: UserService) {
    //console.log("listener mon-sai")
   
  }


  selectComplementAffiche(complement)
  {
    var texte = complement.Complements[0].exigences_TblLabeQuesCompSai
    texte = texte.replace(/\.(\s|$)/g, '.<br/>');
    texte = texte.replace(/-/g, '<br/> >');
    texte = texte.replace(/([0-9].)/g,'<br/> ');
    //texte.replace(/\.(?=\s)/g, '<br/>');

    complement.Complements[0].exigences_TblLabeQuesCompSai = texte

    this.complementQuestionAffiche = complement
    this.exigenceModal.show();
  }

  ngOnInit() {
    window.scrollTo(0, 0);
    //console.log("ngOnInit -----> sai detail");
    this.info_user = this._userServ.getUserConnected().userName.replace("+"," ").split(" ")
    this._saiServ.getStaticsData().subscribe(
        param =>{
          if(param){

            this.paramList = JSON.parse(param[0][""]).params[0]
          }
          
        }
      )
    this.remplirQuestion()
    this.remplirAxesAmelioration()
  }

async remplirAxesAmelioration() {
  try {
    const axe = await this._saiServ.getAxeAmelioration();
    if (axe) {
      this.listeAxes = axe.params;
      console.log(this.listeAxes);
    }
  } catch (error) {
    console.error(error);
  }
}


  remplirQuestion(){
    this._saiServ.setQuestionSai();
    this._saiServ.getQuestionSai().subscribe(
      question =>{
        if(question){
          this.listeQuestion=question
          this.calculerScore(this.listeQuestion)
          console.log(this.listeQuestion)
          this.toFormGroup()
          this.selectTheme(-1);
        }
        
      }
    )
  }

  toFormGroup(){
    //ajoute une form a l'objet
    for (const theme of this.listeQuestion){
      //si la liste des sous thématique est nulle alors il y a une liste de question
      if(theme.SousThematique == null){
        theme.form = this.questionToControl(theme.Tbl_LabelisationQuestionnaire, theme)  
      }else{ //alors il y a une liste de sous thématique il faut récupérer les questions en dessous
        for(const sous_theme of theme.SousThematique){
          sous_theme.form = this.questionToControl(sous_theme.Tbl_LabelisationQuestionnaire, sous_theme)
        }
      }      
    }    
  }

  questionToControl(listeQuestion, theme){
    var group: any = {};
    
    
    //liste des thématique dont les questions doivent etre désactivée lorsque la premiere question est sur non
    const liste_thematiquespec : Array<number> = [10, 12, 22, 24, 25,  30, 31, 32]
        let premiere_question;
        for (let q = 0; q < listeQuestion.length; q++){
          const question = listeQuestion[q]
          var rep: any = {};

          if (!premiere_question && (this.liste_question.indexOf(question.id_TblLabeQues) != -1 || question.id_TblLabeQues == 186)){
            premiere_question = question
            premiere_question.question_TblLabeQues = premiere_question.question_TblLabeQues.split("?")[0] + " ?"
          }
          //si c'est le deuxieme theme de protection culture alors la premiere question est la question du theme precedent
          if(!premiere_question && theme.id_ResLabeQues == 25){
            for(const t of this.listeQuestion){
              if(t.id_ResLabeQues == 13){
                for (const st of t.SousThematique){
                  if(st.id_ResLabeQues == 24){
                    for(const q of st.Tbl_LabelisationQuestionnaire){
                      if(q.id_TblLabeQues == 186){
                        premiere_question = q;
                        break;
                      }
                    }
                  }
                  if(premiere_question){
                    break;
                  }
                }  
              }
              if(premiere_question){
                break;
              }                
            }
          }

          //pour chaque réponse ajoute un control a la form
          if(question.SousQuestion == null){
            //si on a une premiere question c'est qu'il faut rendre non visible les N/A
            if(!premiere_question){
              question.visibleNA = true
            }else{
              question.visibleNA = false
            }
            for(let i = 0; i < question.Tbl_LabelisationQuestionnaireReponses.length; i++){
              const reponse = question.Tbl_LabelisationQuestionnaireReponses[i];
              rep[reponse.id_TblLabeQuesRepo] = new FormControl({value : reponse.reponse_temp});
              
              
              rep[reponse.id_TblLabeQuesRepo].valueChanges.subscribe(change=>{
                for (const r of question.Tbl_LabelisationQuestionnaireReponses){
                  //partie radio button
                  if (r.id_TblLabeQuesRepo != reponse.id_TblLabeQuesRepo) {
                    r.reponse_temp = ''
                  }else{ 
                    r.reponse_temp = change;                 
                  }
                }
                //si c'est réponse Non
                if(reponse.reponse_TblLabeQuesRepo == "Non"){
                  
                  //si la question est dans la liste des question a gérer
                  if(this.liste_question.indexOf(question.id_TblLabeQues) != -1){
                    for(const ques of listeQuestion){
                      //pour toutes les question dans la liste hors mis celle qui vient d'etre modifiée
                      if (this.liste_question.indexOf(ques.id_TblLabeQues) == -1){
                        console.log(ques)
                        if(ques.Tbl_LabelisationQuestionnaireReponses){ //si y a pas de sous question
                          for(const rep of ques.Tbl_LabelisationQuestionnaireReponses){
                            if(rep.reponse_TblLabeQuesRepo == "N/A"){
                              rep.reponse_temp = rep.id_TblLabeQuesRepo
                              //parcourt les controls de la question e les désactivée
                              
                            }else{
                              rep.reponse_temp = ""
                            }
                          }  
                        }else{ //si y a des sous question
                          for(const sous_ques of ques.SousQuestion){
                            for(const rep of sous_ques.Tbl_LabelisationQuestionnaireReponses){
                              if(rep.reponse_TblLabeQuesRepo == "N/A"){
                                rep.reponse_temp = rep.id_TblLabeQuesRepo
                                
                              }else{
                                rep.reponse_temp = ""
                              }
                              

                            }
                          }
                        }
                        ques.displayed = false
                      }
                    }
                  }else if(question.id_TblLabeQues == 186){ //question 186
                    //il faut rechercher les question de la thématique d'apres pour les mettre sur N/A
                    for(const t of this.listeQuestion){
                      if(t.id_ResLabeQues == 13){
                        for(const sous_t of t.SousThematique){                        
                          for(const q of sous_t.Tbl_LabelisationQuestionnaire){
                            if (q.id_TblLabeQues != 186){
                              if(q.Tbl_LabelisationQuestionnaireReponses){ //si y a pas de sous question
                                for(const rep of q.Tbl_LabelisationQuestionnaireReponses){
                                  if(rep.reponse_TblLabeQuesRepo == "N/A"){
                                    rep.reponse_temp = rep.id_TblLabeQuesRepo
                                  }else{
                                    rep.reponse_temp = ""
                                  }
                                }  
                              }else{ //si y a des sous question
                                for(const sous_ques of q.SousQuestion){
                                  for(const rep of sous_ques.Tbl_LabelisationQuestionnaireReponses){
                                    if(rep.reponse_TblLabeQuesRepo == "N/A"){
                                      rep.reponse_temp = rep.id_TblLabeQuesRepo
                                    }else{
                                      rep.reponse_temp = ""
                                    }
                                  } 
                                }
                              }
                              q.displayed = false;
                            }
                          }
                        }
                        break;
                      }

                    }
                  }
                  
                }else{//alors reponse oui
                  //si la question est dans la liste des question a gérer
                  if(this.liste_question.indexOf(question.id_TblLabeQues) != -1){
                    for(const ques of listeQuestion){
                      //pour toutes les question dans la liste hors mis celle qui vient d'etre modifiée
                      if (this.liste_question.indexOf(ques.id_TblLabeQues) == -1){
                        ques.displayed = true

                        //si pas sous question
                        if(!ques.SousQuestion){
                          for(const rep of ques.Tbl_LabelisationQuestionnaireReponses){
                            rep.reponse_temp = "";
                          }
                        }else{// si sous question
                          for(const sq of ques.SousQuestion){
                            for(const rep of sq.Tbl_LabelisationQuestionnaireReponses){
                              rep.reponse_temp = "";
                            }
                          }
                        }
                        
                      }  
                    }
                  }else if(question.id_TblLabeQues == 186){ //c'est la question 186
                    for(const t of this.listeQuestion){
                      
                      if(t.id_ResLabeQues == 13){

                        for(const sous_t of t.SousThematique){                        
                          for(const q of sous_t.Tbl_LabelisationQuestionnaire){

                            if (q.id_TblLabeQues != 186){
                              if(q.Tbl_LabelisationQuestionnaireReponses){ //si y a pas de sous question
                                for(const rep of q.Tbl_LabelisationQuestionnaireReponses){
                                  rep.reponse_temp = ""
                                }  
                              }else{ //si y a des sous question
                                for(const sous_ques of q.SousQuestion){
                                  for(const rep of sous_ques.Tbl_LabelisationQuestionnaireReponses){
                                    rep.reponse_temp = ""
                                  } 
                                }
                              }
                              q.displayed = true;
                            }
                          }
                        }
                        break;
                      }
                    }
                  }
                }
              })
            }
          }else{ //il y a des sous questions
            for(const q of question.SousQuestion){
              if(!premiere_question){
                q.visibleNA = true
              }else{
                q.visibleNA = false
              }
              for(let i = 0; i < q.Tbl_LabelisationQuestionnaireReponses.length; i++){
                const reponse = q.Tbl_LabelisationQuestionnaireReponses[i];
                rep[reponse.id_TblLabeQuesRepo] = new FormControl({value : reponse.reponse_temp});
                rep[reponse.id_TblLabeQuesRepo].valueChanges.subscribe(change=>{
                  for (const r of q.Tbl_LabelisationQuestionnaireReponses){
                    //partie radio button
                    if (r.id_TblLabeQuesRepo != reponse.id_TblLabeQuesRepo) {
                      r.reponse_temp = ''
                    }else{ 
                      r.reponse_temp = change;                   
                    }
                  }
                  //si c'est réponse Non
                  if(reponse.reponse_TblLabeQuesRepo == "Non"){

                    for(const ques of listeQuestion){
                      if (this.liste_question.indexOf(ques.id_TblLabeQues) != -1){
                        for(const rep of ques.Tbl_LabelisationQuestionnaireReponses){
                          if(rep.reponse_TblLabeQuesRepo == "N/A"){
                            rep.reponse_temp = rep.id_TblLabeQuesRepo
                          }else{
                            rep.reponse_temp = ""
                          }
                        }
                      }
                    }
                  }
                })
              }  
            }            
          }
          question.displayed = true;
          //on rend non visible par défaut toutes les question qui dépendent d'autres questions              
          if(liste_thematiquespec.indexOf(theme.id_ResLabeQues) != -1) {
            let rep : string = "";
            //récupère la réponse de la premiere question
            for(const r of premiere_question.Tbl_LabelisationQuestionnaireReponses){
              if(r.reponse_temp != ""){
                rep = r.reponse_TblLabeQuesRepo;
                break;
              }
            }
            if(rep == "Non" || rep ==""){
              //si c'est différent de la première question d'une thématique
              if (this.liste_question.indexOf(question.id_TblLabeQues) == -1 && question.id_TblLabeQues != 186){
                //recherche la premiere question de la thématique
                question.displayed = false;
              }  
            }
            
          }
          
          group[question.id_TblLabeQues] = new FormGroup(rep)
        
        }
      
    //pour chaque question ajout une form
    group['thematique'] = new FormControl(theme.id_ResLabeQues);
    return new FormGroup(group);
  }

  validReponseQuestionnaire(question): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      let valid = false;
      let id_reponse = control.value;
      if(question.SousQuestion){//s'il y a des sous questions
        for(const sq of question.SousQuestion){
          for(const rep of sq.Tbl_LabelisationQuestionnaireReponses){
            if(rep.id_TblLabeQuesRepo == id_reponse){
              if(rep.reponse_TblLabeQuesRepo == "Oui" && rep.reponse_temp != ''){
                valid = true
              }
              if(rep.reponse_TblLabeQuesRepo == "Non" && rep.reponse_temp != ''){
                valid = true
              }
              if(!sq.visibleNA){
                valid = true
              }
              /*if(rep.reponse_TblLabeQuesRepo == 'Oui' || rep.reponse_TblLabeQuesRepo == 'Non' || !sq.visibleNA){
                valid = true;
              } */ 
              break;
            }
            
          }
        }
      }else{ //s'il n'y a pas de sous question
        for(const rep of question.Tbl_LabelisationQuestionnaireReponses){
          if(rep.id_TblLabeQuesRepo == id_reponse){
            if(rep.reponse_TblLabeQuesRepo == "Oui" && rep.reponse_temp != ''){
                valid = true
              }
              if(rep.reponse_TblLabeQuesRepo == "Non" && rep.reponse_temp != ''){
                valid = true
              } 
              if(!question.visibleNA){
                valid = true
              }
              break;
          }
          
          
        }
        if(valid){
          if(this.themeObjSelection){
            for(const rep of question.Tbl_LabelisationQuestionnaireReponses){
              this.themeObjSelection.form.controls[rep.id_TblLabeQues].controls[rep.id_TblLabeQuesRepo].setErrors(null);  
            }
            
          }
        }
      }
      
      console.log(this.themeObjSelection)
      
      return question.displayed && !valid  ? {reponseEmpty: true} : null;
    };
  }

  ngOnDestroy(){
    
  }

  pagePrecedente() {
    if(this.sousThemeSelectionne && this.numSousTheme > 0){
      this.selectSousTheme(this.numeroTheme, this.numSousTheme - 1)
    }else{
      if(this.numeroTheme == -2){
      this.textConclusionVisibility = true;
      console.log("test text completion");
      console.log(this.listeQuestion.length);
      this.selectTheme(this.listeQuestion.length - 1,true) 
    }else{
      this.selectTheme(this.numeroTheme - 1,true)      
    }
    }
    
  }

  sousThemeSelectionne
  numSousTheme
  selectTheme(num, precedent? : boolean){
    //Si on est sur la page d'information on la passe
    if(num == -1){
      this.textIntroVisibility = false;
      this.textConclusionVisibility = true;
    }else if(num == -2){
      this.textConclusionVisibility = false;
      this.textIntroVisibility = true;
      this.calculerResult(this.listeQuestion)
    }else{
      this.textIntroVisibility = true;
      this.textConclusionVisibility = true;
    }

    if(num == this.listeQuestion.length){
      this.finQuestionnaireModal.config.backdrop = 'static'   
      this.calculerScore(this.listeQuestion)      
      this.textConclusionVisibility = false;
      this.textIntroVisibility = true;
      this.finQuestionnaireModal.show();     
    }

    //si on arrive a la fin de la liste des sous theme
    this.numeroTheme = num;
    this.themeObjSelection = this.listeQuestion[num];
    this.sousThemeSelectionne = false

    //si le prochain theme a des sous themes alors selectionne le premier sous theme
    if(this.themeObjSelection){
      if(this.themeObjSelection.SousThematique){
        if (precedent){//si précédent
            this.selectSousTheme(num,this.themeObjSelection.SousThematique.length -1);
          
        }else{
            this.selectSousTheme(num,0);
        }
        
      }   
    }
  }

  
  selectSousTheme(numT, numST){
    if(!this.textIntroVisibility){      
      this.textIntroVisibility = true;
    }
    if(!this.textConclusionVisibility){      
      this.textConclusionVisibility = true;
    }
    if(numST < 0){
      numST = 0
    }
    this.numSousTheme = numST;
    this.numeroTheme = numT;
    this.themeObjSelection = this.listeQuestion[numT].SousThematique[numST]
    this.sousThemeSelectionne = true
  }

  touteQuestionRepondue;

  calculerScore(questions){
    this.nombreTotalQuestion = 0;
    this.nombreTotalReponses = 0;
    this.touteQuestionRepondue = true
    for(const theme of questions){ //chaque thématique
      theme.countQuestion = {
           'nbQuestion' : 0,
           'nbReponse' : 0
        }
      if(theme.SousThematique){ //s'il y a des ous thémtique
        
        for(const sous_thematique of theme.SousThematique){ //chaque sous thématique
          sous_thematique.countQuestion = {
           'nbQuestion' : 0,
           'nbReponse' : 0
        }
          this.parcourtQuestion(sous_thematique)
          theme.countQuestion.nbQuestion += sous_thematique.countQuestion.nbQuestion
          theme.countQuestion.nbReponse += sous_thematique.countQuestion.nbReponse
        }
      }else{
        this.parcourtQuestion(theme)
      }
      if(theme.countQuestion.nbQuestion != theme.countQuestion.nbReponse){
        this.touteQuestionRepondue = false;
      }
    }
  }

    calculerResult(questions){
    this.countEssentiel = {
       'nbQuestion' : 0,
       'nbOui' : 0,
       'nbNon' : 0,
       'nbNa' : 0
    }
    this.countIntermediaire = {
       'nbQuestion' : 0,
       'nbOui' : 0,
       'nbNon' : 0,
       'nbNa' : 0
    }
    this.countAvance = {
       'nbQuestion' : 0,
       'nbOui' : 0,
       'nbNon' : 0,
       'nbNa' : 0
    }

    for(const theme of questions){ //chaque thématique
      if(theme.SousThematique){ //s'il y a des ous thémtique
        for(const sous_thematique of theme.SousThematique){ //chaque sous thématique
          this.parcourtQuestionResult(sous_thematique)
        }
      }else{
        this.parcourtQuestionResult(theme)
      }
    }
    let questionEssentielleRepondue = this.countEssentiel.nbNa + this.countEssentiel.nbOui + this.countEssentiel.nbNon == this.countEssentiel.nbQuestion
    let questionIntermediaireRepondue = this.countIntermediaire.nbNa + this.countIntermediaire.nbOui + this.countIntermediaire.nbNon == this.countIntermediaire.nbQuestion
    let questionAvanceRepondue = this.countAvance.nbNa + this.countAvance.nbOui + this.countAvance.nbNon == this.countAvance.nbQuestion

    //si toutes les questions son répondue
    if(questionEssentielleRepondue && questionIntermediaireRepondue && questionAvanceRepondue ){
      if((this.countEssentiel.nbOui + this.countEssentiel.nbNa) / this.countEssentiel.nbQuestion == 1){
        const res_intermediaire = (this.countIntermediaire.nbOui + this.countIntermediaire.nbNa) / this.countIntermediaire.nbQuestion
        const res_avance = (this.countAvance.nbOui + this.countAvance.nbNa) / this.countAvance.nbQuestion
        if( res_intermediaire == 1 && res_avance >= 0.75){
          this.resultatSai = 'Or'
        }else if(res_intermediaire >= 0.75 && res_avance >= 0.5){
          this.resultatSai = 'Argent'
        }else if(res_intermediaire >= 0.5 && res_avance >= 0){
          this.resultatSai = 'Bronze'
        }else{
          this.resultatSai = 'Non éligible'
        }
      }else{
        this.resultatSai = 'Non éligible'
      }
    }else{
      this.resultatSai = 'Aucun résultat'
    }
    console.log(this.resultatSai)
    console.log("this.resultatSai")
    console.log("textConclusionVisibility")
    console.log(this.textConclusionVisibility)


  }
  parcourtQuestion(theme){    
    for(const question of theme.Tbl_LabelisationQuestionnaire){//parcurt des questions
      if(question.SousQuestion){
        for(const sous_question of question.SousQuestion){
          this.parcourtReponse(sous_question, theme)
        }
      }else{
        this.parcourtReponse(question, theme)
      }
    }
  }

    parcourtQuestionResult(theme){
    for(const question of theme.Tbl_LabelisationQuestionnaire){//parcurt des questions
      if(question.SousQuestion){
        for(const sous_question of question.SousQuestion){
          this.parcourtReponseResult(sous_question)
        }
      }else{
        this.parcourtReponseResult(question)
      }
    }
  }

    parcourtReponseResult(question){
    let count;
    if(question.Complements){
      switch (question.Complements[0].id_ResLabeSaiNiv) {
        case 2://essentiel
          count = this.countEssentiel
          this.countEssentiel.nbQuestion += 1

          break;
        case 3://intermédiaire
          count = this.countIntermediaire
          this.countIntermediaire.nbQuestion += 1

          break;
        case 1: //avancé
          count = this.countAvance
          this.countAvance.nbQuestion += 1
          break;
        
        default:
          // code...
          break;
      }
    }
    
    if(count){


      for(const reponse of question.Tbl_LabelisationQuestionnaireReponses){//parcourt des réponses
        if(reponse.reponse_temp != ""){//récupération de la réponse
          switch (reponse.reponse_TblLabeQuesRepo) {
            case 'Oui':
              count.nbOui += 1
              break;
            case 'Non':
              count.nbNon += 1
              break;
            case 'N/A':
              count.nbNa += 1
              break;          
            default:
              // code...
              break;
          }
        }
      }
    }
  }

  parcourtReponse(question, theme){
    let count;
    if(question.Complements){
      switch (question.Complements[0].id_ResLabeSaiNiv) {
        case 2://essentiel
          count = theme.countQuestion
          theme.countQuestion.nbQuestion += 1
          this.nombreTotalQuestion += 1
          break;
        case 3://intermédiaire
          count = theme.countQuestion
          theme.countQuestion.nbQuestion += 1
          this.nombreTotalQuestion += 1
          break;
        case 1: //avancé
          count = theme.countQuestion
          theme.countQuestion.nbQuestion += 1
          this.nombreTotalQuestion += 1
          break;
        
        default:
          // code...
          break;
      }
    }
    
    if(count){


      for(const reponse of question.Tbl_LabelisationQuestionnaireReponses){//parcourt des réponses
        if(reponse.reponse_temp != ""){//récupération de la réponse
          switch (reponse.reponse_TblLabeQuesRepo) {
            case 'Oui':
              count.nbReponse += 1
              this.nombreTotalReponses += 1
              break;
            case 'Non':
              count.nbReponse += 1
              this.nombreTotalReponses += 1
              break;
            case 'N/A':
              count.nbReponse += 1
              this.nombreTotalReponses += 1
              break;          
            default:
              // code...
              break;
          }
        }
      }
    }
  }

  regist() {
    let prochain_sousTheme;
    let rep_premiereQuestion
    if(this.textIntroVisibility && this.textConclusionVisibility){
      const form = this.themeObjSelection.form;
      var questionnaireFini = false
      //si la thématique slectionnée est égale a la derniere thématique de la liste
      if(this.listeQuestion[this.numeroTheme].SousThematique){
        var taille_sousTheme = this.listeQuestion[this.numeroTheme].SousThematique.length
        var taille_theme = this.listeQuestion.length
        if(this.themeObjSelection.id_ResLabeQues == (this.listeQuestion[taille_theme-1].SousThematique[taille_sousTheme-1].id_ResLabeQues)){
          questionnaireFini = this.touteQuestionRepondue;
        }  
      }
      console.log(questionnaireFini);

      //on vérifie si toutes les questions ont bien des réponses

      //si c'est le deuxieme theme de protection culture alors la premiere question est la question du theme precedent
      if(this.themeObjSelection.id_ResLabeQues == 25){
        for(const t of this.listeQuestion){
          if(t.id_ResLabeQues == 13){
            for (const st of t.SousThematique){
              if(st.id_ResLabeQues == 24){
                for(const q of st.Tbl_LabelisationQuestionnaire){
                  if(q.id_TblLabeQues == 186){
                    for(const rep of q.Tbl_LabelisationQuestionnaireReponses){
                      if(rep.reponse_temp != ''){
                        rep_premiereQuestion = rep;
                        break;
                      }
                      
                    }
                    
                  }
                }
              }
              if(rep_premiereQuestion){
                break;
              }
            }  
          }
          if(rep_premiereQuestion){
            break;
          }                
        }
      }

      for(const ques of this.themeObjSelection.Tbl_LabelisationQuestionnaire){
        //récupère la premiere réponse
        

        if(this.liste_question.indexOf(ques.id_TblLabeQues) != -1 || ques.id_TblLabeQues == 186){
          for(const rep of ques.Tbl_LabelisationQuestionnaireReponses){
            if(rep.reponse_temp != ''){
              rep_premiereQuestion = rep;
            }
          }
          
        }else{
          if(rep_premiereQuestion){          
            if(rep_premiereQuestion.reponse_TblLabeQuesRepo == 'Oui'){
              let reponse_renseigne
              if(ques.SousQuestion){
                for(const sq of ques.SousQuestion){
                  reponse_renseigne = false
                  for(const rep of sq.Tbl_LabelisationQuestionnaireReponses){
                    if(rep.reponse_temp != ''){
                      reponse_renseigne = true

                    }
                  }
                  if(!reponse_renseigne){
                    this._toaster.error("Toutes les réponses n'ont pas été renseignées.")
                    return;
                  }
                }
              }else{
                for(const rep of ques.Tbl_LabelisationQuestionnaireReponses){
                  if(rep.reponse_temp != ''){
                    reponse_renseigne = true              
                  }
                }
                if(!reponse_renseigne){
                  this._toaster.error("Toutes les réponses n'ont pas été renseignées.")
                  return;
                }
              }
              
              
            }
          }
        }
      }
    
    //récupère les objets qui contiennent la form
      if(form.value.thematique != 1){
        let theme_save;
        for (const theme of this.listeQuestion){
          if(theme.id_ResLabeQues == form.value.thematique){
              theme_save = theme 
              break;
           }else{
             if (theme.SousThematique){
               for (const sous_theme of theme.SousThematique){
                 //refait un tour pour récupérer le prochain sous theme
                  if(theme_save){
                    prochain_sousTheme = sous_theme
                    break; 
                  }
                  if(sous_theme.id_ResLabeQues == form.value.thematique){
                    theme_save = sous_theme
                    
                  } 
                  
                }
             }
           }
          
          if(theme_save){
            break;
          }    
        }
        console.log(theme_save)
        if(theme_save){

          this._saiServ.saveQuestionnaireSai(theme_save, questionnaireFini);
          
          if(prochain_sousTheme && rep_premiereQuestion && rep_premiereQuestion.id_TblLabeQues == 186){ //si c'est le theme 186 alors sauvegarde le prochain sous theme aussi
            this._saiServ.saveQuestionnaireSai(prochain_sousTheme, questionnaireFini);
          }
        }
      }
    }else{
      this.textIntroVisibility = true;
      this.textConclusionVisibility = true;
    }
    if(!questionnaireFini){
      //si on connait le prochain sous theme
      if(typeof prochain_sousTheme != 'undefined' && (!rep_premiereQuestion || rep_premiereQuestion.id_TblLabeQues != 186 || rep_premiereQuestion.reponse_TblLabeQuesRepo == 'Oui')){
        console.log(prochain_sousTheme)
        this.themeObjSelection = prochain_sousTheme;
        this.numSousTheme += 1
        this.sousThemeSelectionne = true
      }else{
        this.selectTheme(this.numeroTheme +1)  
      }
      
      window.scrollTo(0, 0);
      this.calculerScore(this.listeQuestion)
    }else{
      let questionComplet = true
      //recalcul le score pour voir si toutes les questions sont répondues
      this.calculerScore(this.listeQuestion)
      for (const theme of this.listeQuestion){
        if(theme.countQuestion.nbReponse < theme.countQuestion.nbQuestion){
          this._toaster.error("Le questionnaire n'est pas complet.")
          questionComplet = false
          break;
        }
      }
      if(questionComplet && this.textIntroVisibility){
        this.finQuestionnaireModal.config.backdrop = 'static'
   
        console.log("test louis nav test")
        this.selectTheme(-2)
        this.finQuestionnaireModal.show();  
      }
      
    }

  }

    
}


