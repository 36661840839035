import { Component, OnInit, ElementRef } from '@angular/core';
import { FilterService } from 'src/app/services/filter.service';
import { Subscription } from 'rxjs';
import { MesLivraisonsService } from 'src/app/services/mes-livraisons.service';
import { ToolsService } from 'src/app/services/tools.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ButtonService } from 'src/app/services/button.service';
import { ToastrService } from 'ngx-toastr';
import { DownloadService } from 'src/app/services/download.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-mes-livraisons-detail',
  templateUrl: './mes-livraisons-detail.component.html'
})
export class MesLivraisonsDetailComponent implements OnInit {
  
  getLivraisons:Subscription;
  listenerSub:Subscription;
  getCamp:Subscription;
  dlSub:Subscription;
  
  mesLivraisons;
  livraisonsAvenir;
  livraisonsEffectuees;
  btnState;
  selectedLivraison;
  detail;
  voyagesToShow;
  noVoyagesMessage;

  selectedEntreeEffectuee;
  selectedLivraisonEffectuee;
  selectedLivraisonEffectueeTdoc;
  selectedDetailEffectuee;
  mvmtsEffectueToShow;

  selectedCamp;
  
  noLivsAVenir;
  noLivsEffectuees;
  viewportWidth;

  noLivToShowMessage;
  p: number = 1; //page de depart pagination
  _env = environment;
  param = this._activRoute.snapshot.paramMap.get("produit");
  constructor(
    private _filtServ: FilterService,private _livraisonServ: MesLivraisonsService, 
    private _tools: ToolsService,private _elementRef:ElementRef,
    private _activRoute: ActivatedRoute,private _btnServ: ButtonService,
    private _toaster: ToastrService,private _dlServ: DownloadService,private _router: Router) { 

      this.btnState = this._btnServ.getBtnState();
      this.listenerSub = this._filtServ.filterChangeEmitter.subscribe(newFilter => {
        this.ngOnInit();
      })
      
    }
    
    ngOnInit() {
      window.scrollTo(0, 0)
      const testProductName = this._env.BZ_PRODUCTS_NAMES.filter(prodName => prodName == this.param);
      //console.log(testProductName)
      if(testProductName.length == 0){
        this._router.navigate(['404'])
        return;
      } else {
        this.getCamp = this._filtServ.selectedCamp.subscribe(
          year => {
            //console.log(year)
            this.selectedCamp  = year;
          }
        )
        this._livraisonServ.setLivraisons();
        const param = this._activRoute.snapshot.paramMap.get("produit");
        const safeParam = this._tools.cleanParam(param);
        this.getLivraisons = this._livraisonServ.getLivraisons().subscribe(
          livraisons => {
          //  //console.log(livraisons)
            
            //console.log(livraisons)
            if(livraisons[0] != null){
    
              const myDetail = livraisons[0].cult.filter((liv)=>{
              return liv['cult.culture'] === param;
              })
              //console.log( myDetail )
              if(myDetail.length > 0){
                this.mesLivraisons = livraisons[0].cult;
                
                this.mesLivraisons.forEach(
                  liv =>{
                    // //console.log(liv);
                    if(liv['cult.culture'] == safeParam){
                      this.livraisonsAvenir = liv.avenir;
                      this.livraisonsEffectuees = liv.effectuees;
                      //console.log(this.livraisonsAvenir)
                      //console.log(this.livraisonsEffectuees)
                      // this.livraisonsEffectuees = liv.effectuees;
                      // //console.log(this.livraisonsEffectuees);
                      if(this.livraisonsAvenir){
                        this.selectLivraison(liv.avenir[0]['avenir.contrat']);
                        this.setDetail(liv.avenir[0]);
                        if(liv.avenir[0].avenirdetail){
                          this.noVoyagesMessage = null;
                          this.setVoyages(liv.avenir[0].avenirdetail)
                        } else {
                          this.noVoyagesMessage = "Pas de bons de voyage associés à ce contrat";
                        }
                      }
                      
                      if(this.livraisonsEffectuees){
                        //console.log(this.livraisonsEffectuees)
                        this.selectEntreeEffectuee(0);
                        this.selectMvtEffectuee(this.livraisonsEffectuees[0].mouvts[0]['mouvts.identifiant'],this.livraisonsEffectuees[0].mouvts[0])
                      } else {
                        this.selectedDetailEffectuee = null;
                      }
                      this.getLivraisons.unsubscribe();
                    }          
                  } 
                )
              } else {
                this.noLivToShowMessage = "pas de données";
                this.mesLivraisons = null;
                this.livraisonsAvenir = null;
                this.livraisonsEffectuees = null;
                this.detail = null;
                this.getLivraisons.unsubscribe();
                //console.log("NO LIVRAISON A AFFICHER CAR PRODUIT DETAIL D2SELECTIONN2")
              }
            } else {
              this.mesLivraisons = null;
              this.livraisonsAvenir = null;
              this.livraisonsEffectuees = null;
              this.detail = null;
              this.noLivToShowMessage = "pas de données";
                this.getLivraisons.unsubscribe();
              //console.log("NO LIVRAISON A AFFICHER")
            }
          },
          error => {
            //console.log(error);
          }
        )
    }
  }

  ngDoCheck(){
    this.btnState = this._btnServ.getBtnState();
    //console.log(this.btnState)
    this.viewportWidth = window.innerWidth;
  }

  selectFirst(dex,from){
    const first = (dex - 1) * 3;
    if(from == 'avenir'){
      this.selectLivraison(this.livraisonsAvenir[first]['avenir.contrat']);
      this.setDetail(this.livraisonsAvenir[first]);
    }
    if(from == 'effectue'){
      this.selectMvtEffectuee(this.livraisonsEffectuees[first].mouvts[0]['mouvts.identifiant'],this.livraisonsEffectuees[first].mouvts[0])
    }


  }

  selectLivraison(livName){
    //console.log(livName)
    this.selectedLivraison = livName;
  }

  setDetail(livObj){
    //console.log(livObj)
    this.detail = {
      adresse: livObj['avenir.adresse'],
      conditionsExec: livObj['avenir.condition']
    };
    // //console.log(this.detail);
  }

  setVoyages(livObj){
    //console.log(livObj.avenirdetail)
    if(livObj.avenirdetail){
      this.noVoyagesMessage = null;
      this.voyagesToShow = livObj.avenirdetail;
    } else {
      this.noVoyagesMessage = "Pas de bons de voyage associés à ce contrat"
    }
  }

  selectMvtEffectuee(livEffId,mvt){
    //console.log(livEffId)
    //console.log(mvt)
    this.selectedLivraisonEffectuee = livEffId;
    this.selectedLivraisonEffectueeTdoc = mvt['mouvts.tdoc'];
    this.selectedDetailEffectuee = mvt.qualites;
    //console.log(this.selectedDetailEffectuee)
  }

  downloadDoc(docId,docTDoc){ 
    //console.log(docId,docTDoc)
    if(!docId || !docTDoc){
      this._toaster.error("Aucun type ou nom de document trouvé! Echec du téléchargement")
    } else {
      this.dlSub = this._dlServ.downloadDocument(docId,docTDoc).subscribe(
        response => {
            //console.log(response)
            if(response['type'] == "application/download"){
              let dataType = response['type'];
              let binaryData = [];
              binaryData.push(response);
              let downloadLink = document.createElement('a');
              //console.log(downloadLink)
              downloadLink.href = window.URL.createObjectURL(new Blob(binaryData, {type: dataType}));
              downloadLink.setAttribute('download',docTDoc + "-" + docId);
              document.body.appendChild(downloadLink);
              downloadLink.click();
            } else {
              this._toaster.error("Aucun document trouvé! Echec du téléchargement")
            }
          
        }, 
        error => {
          //console.log(error);
        }
      )
    }
  }

  changeBtnState(){
    this.btnState == "À VENIR" ? this.btnState = "EFFECTUÉES" : this.btnState = "À VENIR";
  }

  selectEntreeEffectuee(index){
    this.selectedEntreeEffectuee = this.livraisonsEffectuees[index];
    this.selectMvtEffectuee(this.selectedEntreeEffectuee['mouvts'][0]['mouvts.identifiant'],this.selectedEntreeEffectuee['mouvts'][0])
    //console.log(this.selectedEntreeEffectuee)
  }

  setDetailEffectuee(livObj){
    //console.log(livObj)
    this.selectedDetailEffectuee = livObj.qualites;
    // //console.log(this.detail);
  }

  ngOnDestroy(){
    this.listenerSub ? this.listenerSub.unsubscribe():null;
    this.getLivraisons ? this.getLivraisons.unsubscribe():null;

  }

}