import { Component, OnInit, ElementRef } from '@angular/core';
import { FilterService } from 'src/app/services/filter.service';
import { MesLivraisonsService } from 'src/app/services/mes-livraisons.service';
import { Subscription } from 'rxjs';
import {Chart} from 'chart.js';
import { ToolsService } from 'src/app/services/tools.service';

@Component({
  selector: 'app-mes-livraisons',
  templateUrl: './mes-livraisons.component.html'
})
export class MesLivraisonsComponent implements OnInit {
  getMesLivraisons: Subscription;
  filtListener: Subscription;
  getCamp:Subscription;
  mesLivraisons=null;
  canva;
  noLivShowMessage;
  selectedCamp;

  viewportWidth;
  count = 0;
  constructor(private _filtServ: FilterService, private _livraisonServ: MesLivraisonsService, private _tools: ToolsService,private _elementRef:ElementRef) { 
    this.filtListener = this._filtServ.filterChangeEmitter.subscribe(newFilter => this.ngOnInit())
  }

  ngOnInit() {
    window.scrollTo(0, 0);
    this.getCamp = this._filtServ.selectedCamp.subscribe(
      year => {
        //console.log(year)
        this.selectedCamp  = year;
      }
      )
      this._livraisonServ.setLivraisons();
      this.getMesLivraisons = this._livraisonServ.getLivraisons().subscribe(
        livraisons => {
          //console.log(livraisons)

          if(livraisons[0]){
            this.mesLivraisons = livraisons[0].cult;
            this.getMesLivraisons.unsubscribe();
            //console.log(this.mesLivraisons)
            if(this.mesLivraisons){
              this.mesLivraisons.forEach(
                liv => {
                  const imgName = this._tools.lowercase(liv['cult.culture']);
                  const containerNameEl = "container-liv-" + this._tools.lowercase(liv['cult.culture']);
      
                  setTimeout(()=>{
                    const el = document.getElementById(containerNameEl);
                    // //console.log(el)
                    if(el){

                      const canvaEl = document.getElementById('canva-'+liv['cult.culture']);

                      if(!canvaEl){
                        el.insertAdjacentHTML(
                          'beforeend',
                          '<canvas  id="canva-'+liv['cult.culture']+'" style="background: transparent url(/assets/icons/produits/green-prod-svg/'+ imgName +'.svg) no-repeat center center;background-size: 50% 50%;width:270px!important" class="canvas'+imgName+' w-100 h-100 "></canvas>'
                        )
          
                        let canva = document.querySelector('.canvas'+imgName);
                        // let last = nodes[nodes.length- 1];
                        this.canva = canva;
                  
                        //console.log(this.canva) 
                        
                        let donutCtx = this.canva.getContext('2d');
                            var data = {
                              labels: [
                                liv['cult.qteeffectuee']+ "T EFFECTUÉES",
                                liv['cult.qteavenir']+ "T À VENIR"
                              ],
                              datasets: [
                                  {
                                      "data": [liv['cult.qteeffectuee'], liv['cult.qteavenir']], 
                                      "backgroundColor": [
                                          "#AADB52",
                                          "#84A755"
                                      ]
                                  }
                                ]
                            }; 
                        
                        new Chart(
                          donutCtx,
                          {
                              "type": 'doughnut',
                              "data": data,
                              "options": {
                                  "cutoutPercentage": 65,
                                  "animation": {
                                      "animateRotate": true
                                  },
                                  tooltips: {
                                    callbacks: {
                                      label: function(tooltipItem, data) {
                                        //console.log(tooltipItem);
                                        //console.log(data);
                                        // const value = tooltipItem.value;
                                        return data.labels[tooltipItem.index];
                                      },
                                    }
                                  },
                                  "legend": { 
                                    "display": false
                                },
                                "responsive": false,
                              }
                          }
                        );                    
                      }

                    }
                    },500)
                    this.count = 0;
                }
              )

            }
          } else {
            this.mesLivraisons = null;
            // const el = this._elementRef.nativeElement.querySelector('#donut-container');
            // el.style.display = 'none';
            this.noLivShowMessage = livraisons[1];
            //console.log(this.noLivShowMessage);
            this.mesLivraisons = null;
            this.getMesLivraisons ? this.getMesLivraisons.unsubscribe():null;
          }
          
        }
      )
  }

  ngOnDestroy(){
    this.filtListener ? this.filtListener.unsubscribe():null;
    this.getCamp ? this.getCamp.unsubscribe():null;
  }

  ngDoCheck(){
    this.viewportWidth = window.innerWidth;
  }

}
