import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject, Subscription } from 'rxjs';
import { DocumentService } from './document.service';

@Injectable({
  providedIn: 'root'
})
export class ResearchService {

  constructor(private _docServ: DocumentService) {
     
  }

  private getAllDocs: Subscription;

  private allDocumentsSubj = new BehaviorSubject<any>(null);
  private searchResultSubj = new BehaviorSubject<any>(null);

  private searchResult = [];
  private allDocuments;

  setSearchResult(searchValue,docType){
    this.searchResult = [];
    this.allDocumentsSubj.next(this._docServ.getDocuments())
    this.allDocuments = this._docServ.getDocuments();
    
    if(this.allDocuments){
      this.allDocuments = this.allDocuments.documents.document;
        this.allDocuments.map(
          x=>{
            if(docType != "TOUT"){
              if(x.type == docType){
                if(x.num.includes(searchValue)){
                  // //console.log(x);
                  this.searchResult.push(x);
                }
              }
            } else {
              this.searchResult = this.allDocuments;
            }
          }
        )
    }
  }

  getSearchresult(){
    return this.searchResult;
  }
}
