import { Component, OnInit,Pipe} from '@angular/core';

import { Subscription, BehaviorSubject, interval } from 'rxjs';
import { FilterService } from 'src/app/services/filter.service';
import { MonSaiService } from 'src/app/services/mon-sai.service';
import { Router, NavigationEnd } from '@angular/router';

@Component({
  selector: 'app-mon-sai',
  templateUrl: './mon-sai.component.html'
})


export class MonSaiComponent implements OnInit {

localCampagneActive;
  constructor(private _filterServ: FilterService,private _saiServ: MonSaiService) {
    console.log("MonSaiComponent")
  }

  ngOnInit() {
   window.scrollTo(0, 0);
    //console.log("ngOnInit -----> setGesSimuHisto");
    this._saiServ.campagneActive.subscribe(
      campagneActive =>{
        this.localCampagneActive = campagneActive;
      }
    )     
/*    ////console.log(this.localCampagneActive[0]['params']);
    //récupération de la liste d'essai de simulation d'éligibilité
    this._gesServ.setGesSimuHisto();
    this.getMaListeHistorique = this._gesServ.getGesSimuHisto().subscribe(
      tiersHistoSimu => {
        if(tiersHistoSimu[0] == null && tiersHistoSimu[1] == "Aucune simulation d'éligibilité éffectuée."){
          this.noGesToShowMessage = tiersHistoSimu[1];
          this.gesListHistoSimu = null;
          this.getMaListeHistorique.unsubscribe();
        
        } else {
          this.noGesToShowMessage = null;
          this.gesListHistoSimu = this.EtapeEligibilite(tiersHistoSimu[0]);
          if (this.gesListHistoSimu["statut"] == "Non primable") {
            this.BtnSimuBool = false;
          }
          this.getMaListeHistorique.unsubscribe();
        }  
      },error => {
        this.getMaListeHistorique.unsubscribe();
      }
    ) */  
  }

  ngOnDestroy(){
    
  }
}