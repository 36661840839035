import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { FilterService } from './filter.service';
import { Subject, Subscription } from 'rxjs';
import { share, publishReplay, refCount } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AnalyseCommService {

  constructor(private _http:HttpClient,private _filtServ: FilterService) { }

  _env = environment;
  synthGraphData = new Subject();
  synthBubbleData = new Subject();
  bubbleDetail = new Subject();
  synthArrayData = new Subject();


  getFilterSC:Subscription;
  getAC:Subscription;

  getFilterSB: Subscription;
  getFilterBD: Subscription;
  getFilterAD: Subscription;
  

  getBubbles:Subscription;
  getBubbleDetails:Subscription;

  getSynthAD:Subscription;

  setSyntheseCom(){
    this.getFilterSC = this._filtServ.getFilter().subscribe(
      filterContent => {
         const filterConf = this._filtServ.createFilterFromCheckedValues(filterContent);
        // //console.log(filterContent);
        // //console.log(filterConf);
        const filterData = new FormData();
        filterData.append("filter",JSON.stringify(filterConf));
        this.getAC =  this._http.post(this._env.BZ_API_BASE_URL + "nxsynthese/getsynthesecommerciale/format/json",filterData,{withCredentials:true}).subscribe(
            synthData => {
              //console.log(synthData);
              this.synthGraphData.next(synthData['MaSynthese']);  
              this.getAC.unsubscribe();
              this.getFilterSC.unsubscribe();
            }, error => {
              //console.log(error);
              // this.getAC.unsubscribe();
              // this.getFilterSC.unsubscribe();
            }
          )          
      }
    )
    // this.getFilterSC = this._filtServ.getFilter().subscribe(
    //   filterContent => {
    //      const filterConf = this._filtServ.createFilterFromCheckedValues(filterContent);
    //     // //console.log(filterContent);
    //     // //console.log(filterConf);
    //     const filterData = new FormData();
    //     filterData.append("filter",JSON.stringify(filterConf));
    //     this.getAC =  this._http.post(this._env.BZ_API_BASE_URL + "transaction/analysecom/format/json",filterData,{withCredentials:true}).subscribe(
    //         synthData => {
    //           //console.log(synthData);
    //           this.synthGraphData.next(synthData);   
    //           this.getAC.unsubscribe();
    //           this.getFilterSC.unsubscribe();
    //         }, error => {
    //           //console.log(error);
    //           this.getAC.unsubscribe();
    //           this.getFilterSC.unsubscribe();
    //         }
    //       )          
    //   }
    // )
  }

  getSyntheseComm(){
    return this.synthGraphData.asObservable();
  }

  setSyntheseBubbles(){
    //Cette méthode sur l'api requiert 3 params $camp:number;$idcu:number; $idti:number;
    //Valable jusqu'à ce que je comprenne comment et d'ou il les récupère pour envoyer la requête sur l'appli en place
    //Ici je fais un post, dans l'appli en place, il la récupère en GET en passant les parametres dans l'url,
    //Cependant je ne trouve pas cette requête avec le param idti
    //En parler avec Bruno dès que possible
    //?idcu=1900?camp=19?idti=0

    this.getFilterSB = this._filtServ.getFilter().subscribe(
      filterContent => {
        const filterConf = this._filtServ.createFilterFromCheckedValues(filterContent);
        const formData = new FormData();
        formData.append("idcu","1900");
        formData.append("idti","0");
        formData.append("camp","19");
        formData.append("filter",JSON.stringify(filterConf));

        this.getBubbles = this._http.post(this._env.BZ_API_BASE_URL + "transaction/analysecomcontrats/format/json",formData,{withCredentials:true})
          .subscribe(
            contrats => {
              // //console.log(contrats);
              this.synthBubbleData.next(contrats);
              this.getBubbles.unsubscribe();
              this.getFilterSB.unsubscribe();
            }, error => {
              //console.log(error);
              this.getBubbles.unsubscribe();
              this.getFilterSB.unsubscribe();
            }
          )
      }
    )
  }

  getSyntheseBubbles(){
    return this.synthBubbleData.asObservable();
  }

  setSyntheseBubbleDetails(id){

    this.getFilterBD = this._filtServ.getFilter().subscribe(
      filterContent => {
         const filterConf = this._filtServ.createFilterFromCheckedValues(filterContent);
        // //console.log(filterContent);
        // //console.log(filterConf);
        const formData = new FormData();
        formData.append(id,"idct")
        formData.append("filter",JSON.stringify(filterConf));

        this.getBubbleDetails = this._http.post(this._env.BZ_API_BASE_URL + "transaction/analysecomtableau/format/json",formData,{withCredentials:true})
          .subscribe(
            contrats => {
              //console.log(contrats);
              this.synthBubbleData.next(contrats);
              this.getBubbleDetails.unsubscribe();
              this.getFilterBD.unsubscribe();
            }, error => {
              //console.log(error);
              this.getBubbleDetails.unsubscribe();
              this.getFilterBD.unsubscribe();
            }
          )
      }
    )
  }

  setSynthArrayData(){
    this.getFilterAD = this._filtServ.getFilter().subscribe(
      filterContent => {
         const filterConf = this._filtServ.createFilterFromCheckedValues(filterContent);
        // //console.log(filterContent);
        // //console.log(filterConf);
        const formData = new FormData();
        formData.append("filter",JSON.stringify(filterConf));

        this.getSynthAD = this._http.post(this._env.BZ_API_BASE_URL + "transaction/analysecomsynthese",formData,{withCredentials:true}).subscribe(
          (data:string) => {
            //console.log(JSON.parse(data))
            this.synthArrayData.next(JSON.parse(data));
            this.getSynthAD.unsubscribe();
            this.getFilterAD.unsubscribe()
          }, 
          error => {
            this.getSynthAD.unsubscribe();
            this.getFilterAD.unsubscribe()
          }
        )
      }
    )
  }

  getSynthArrayData(){
    return this.synthArrayData.asObservable();
  }
}
