import { Component, OnInit, Input } from '@angular/core';
import { Produit } from 'src/app/models/produit';
import { DashboardService } from 'src/app/services/dashboard.service';
import { Subscription } from 'rxjs';
import { map, share, debounceTime, take } from 'rxjs/operators';
import { MaCommercialisationService } from 'src/app/services/ma-commercialisation.service';
import { FilterService } from 'src/app/services/filter.service';

@Component({
  selector: 'app-dash-commercialisation',
  templateUrl: './dash-commercialisation.component.html'
})
export class DashCommercialisationComponent implements OnInit {

  commercialisation;
  getMaCommerce: Subscription;
  setMaCommerce: Subscription;
  filterListener: Subscription;

  noComToShowMessage;

  constructor(private _comServ: MaCommercialisationService,private _filtServ: FilterService) { 
    this.filterListener = this._filtServ.filterChangeEmitter.subscribe(newFilter => {
      console.log("listener dash-commercialisation");
      this.ngOnInit();
    })
  }

  ngOnInit() { 
    this._comServ.setCommercialisation();
      this.getMaCommerce = this._comServ.getCommercialisation().subscribe(
            commerce => {
              console.log(commerce);
              if(commerce[0] == null && commerce[1] == "pas de données"){
                console.log("pas de données CASE !")
                this.noComToShowMessage = "Pas de données";
                this.commercialisation = null;
                this.getMaCommerce.unsubscribe();
              } else {
                this.commercialisation = commerce;
                this.getMaCommerce.unsubscribe();
              }
              
            },error => {
              this.getMaCommerce.unsubscribe();
            }
          )
    // console.log(this.commercialisation)
  }


  ngOnDestroy(){
    this.filterListener ? this.filterListener.unsubscribe():null; 
    this.getMaCommerce ? this.getMaCommerce.unsubscribe():null;
  }

  

}
