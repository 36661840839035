import { Injectable, EventEmitter } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ToastrStateService {

  public toasterEventEmitter: EventEmitter<any>;
  public toasterState = new BehaviorSubject(null);
  public toasterContent = new BehaviorSubject(null);

  
  constructor() {
    this.toasterEventEmitter = new EventEmitter();
   }

   emitToasterEvent(toastrContent):void {
    //console.log("EMIT TOASTER: ",toastrContent);
    this.toasterEventEmitter.emit(toastrContent)
  }

  fillToasterSubj(toastrContent){
    this.toasterContent.next(toastrContent)
  }
}
