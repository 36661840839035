import { Component, OnInit, ViewChild } from '@angular/core';
import { LoaderService } from 'src/app/services/loader.service';
import { Location } from '@angular/common';
import { ToastContainerDirective, ToastrService } from 'ngx-toastr';
import { ToastrStateService } from 'src/app/services/toastr-state.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-login-layout',
  templateUrl: './login-layout.component.html'
})
export class LoginLayoutComponent implements OnInit {

  url:string;
  isLoading;
  listenerSub:Subscription;

  toasterMessage = this._toasterState.toasterContent.getValue();

  @ViewChild(ToastContainerDirective,null) toastContainer: ToastContainerDirective; 

  constructor(private loaderService: LoaderService,private _loc: Location,private toastrService: ToastrService,private _toasterState: ToastrStateService) { 
    this.loaderService.isLoading.next(true);
    this.isLoading = this.loaderService.isLoading.getValue();
    this.listenerSub = this._toasterState.toasterEventEmitter.subscribe(response => {
      //console.log(response)
      if(response.type == "error"){
        this.toastrService.error(response.message)
      } else if (response.type == "success"){
        this.toastrService.success(response.message)
      } else if (response.type == "info"){
        this.toastrService.info(response.message)
      }
      this.displayToasterMessage();
    })
  }

  ngOnInit() {
    this.toastrService.overlayContainer = this.toastContainer;

    if(this.toasterMessage){
      if(this.toasterMessage.type == "error"){
        this.toastrService.error(this.toasterMessage.message)
      } else if (this.toasterMessage.type == "success"){
        this.toastrService.success(this.toasterMessage.message)
      } else if (this.toasterMessage.type == "info"){
        this.toastrService.info(this.toasterMessage.message)
      }

      this._toasterState.toasterContent.next(null)
    }
    // this.toastrService.success('in div');
    setTimeout(()=>{
      this.loaderService.isLoading.next(false);
      this.isLoading = this.loaderService.isLoading.getValue();
    },2000)

    
  }

  displayToasterMessage(){
    if(this._toasterState.toasterState.getValue() != null){
      const toasterResponse = this._toasterState.toasterState.getValue();
      //console.log(toasterResponse)
      
    }
  }

  ngOnDestroy(){
    this.listenerSub ? this.listenerSub.unsubscribe():null;
  }

  

}
