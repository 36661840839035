import { Component, OnInit } from '@angular/core';
import { FilterService } from 'src/app/services/filter.service';
import { UserService } from 'src/app/services/user.service';
import { Subscription } from 'rxjs';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-mon-compte',
  templateUrl: './mon-compte.component.html',
  styleUrls: ['./mon-compte.component.scss']
})
export class MonCompteComponent implements OnInit {
  viewportWidth: any;
  getDetail: Subscription;
  detailsCompte;
  adresses;
  structures;

  constructor(private _filterServ: FilterService,private _userserv: UserService, private _toaster: ToastrService) { 
  }


  

  ngOnInit() {
    window.scrollTo(0, 0);
    let execsAddr = [];
    let structs=[];

    this.getDetail = this._userserv.getUserDetail().subscribe(
      user=>{
        // //console.log("log réponse compte => ",user)
        this.detailsCompte = user['compte'];
        // //console.log(this.detailsCompte)

        //Cas ou il y a plusieurs structures 
        if(Array.isArray(this.detailsCompte.structure)){
          //On passe le tableau direct dans les structures
          structs = this.detailsCompte.structure;
          this.structures = structs;
          //console.log(this.structures)
        } else {
          structs.push(this.detailsCompte.structure);
          this.structures = structs;
        }

        // Ici on récupère forcément un tableau de structures avec des addr d'execution attachées à chacune
        if (this.structures.length > 0) {
          this.structures.forEach(structure => {
            // On teste si la clé execution est un tableau
            if (Array.isArray(structure.execution)) {
              structure.execution.forEach(addrObj => {
                if (addrObj && addrObj.idexec) { // Vérification si addrObj et addrObj.idexec existent
                  const dex = execsAddr.findIndex(ind => ind.idexec == addrObj.idexec);
                  if (dex == -1) {
                    execsAddr.push(addrObj);
                  }
                } else {
                  console.warn('addrObj ou addrObj.idexec est undefined:', addrObj);
                }
              });
            } else if (structure.execution && structure.execution.idexec) { // Vérification si structure.execution et structure.execution.idexec existent
              const dex = execsAddr.findIndex(ind => ind.idexec == structure.execution.idexec);
              if (dex == -1) {                  
                execsAddr.push(structure.execution);
              }
            } else {
             // console.warn('structure.execution ou structure.execution.idexec est undefined:', structure.execution);
            }
          });
          this.adresses = execsAddr;
        }     
      },
      error => {
        //console.log(error)
      }
    );
  }

  modifyMdp(){
    this._userserv.modifyPassword(this.detailsCompte['userMail']).subscribe(
      response => {
        //console.log(response);
        if(response['type'] == 'error'){
          this._toaster.error(response['message'])
        } else {
          this._toaster.success(response['message'])
        }
      },
      error => {
        //console.log(error);
      }
    )
  }

  ngDoCheck(){
    this.viewportWidth = window.innerWidth;
  }

}
