import { Component, OnInit, ElementRef, ViewChildren } from '@angular/core';
import { Subscription } from 'rxjs';
import { DashboardService } from 'src/app/services/dashboard.service';
import { MesReglementsService } from 'src/app/services/mes-reglements.service';

import {Chart} from 'chart.js';
import { ToolsService } from 'src/app/services/tools.service';
import { FilterService } from 'src/app/services/filter.service';

@Component({
  selector: 'app-mes-reglements',
  templateUrl: './mes-reglements.component.html'
})
export class MesReglementsComponent implements OnInit {

  getReglements:Subscription;
  filtRegListener:Subscription;
  getCamp:Subscription;

  mesReglements;
  canva:any;

  viewportWidth;
  noRegToShowMessage;

  selectedCamp;

  constructor(private _regServ: MesReglementsService,
              private _dashServ: DashboardService,
              private _elementRef : ElementRef, 
              private _tools: ToolsService,
              private _filtServ: FilterService) 
  {
    this.filtRegListener = this._filtServ.filterChangeEmitter.subscribe(
      newFilter =>  {
        //console.log("re init mes reglements")
        this.ngOnInit()
      }
    )
  }
  
  ngOnInit() {
    window.scrollTo(0, 0)
    this.getCamp = this._filtServ.selectedCamp.subscribe(
      year => {
        //console.log(year)
        this.selectedCamp  = year;
      }
      )
      this._regServ.emptyReglements();
      this._regServ.setReglements();
      this.getReglements = this._regServ.getReglements().subscribe(
        reglements => {
          //console.log("mes Reglements component =>",reglements);
          if(reglements[0]){//
            
            this.mesReglements = [];
            this.mesReglements = reglements[0].culture;
            this.getReglements.unsubscribe();

            this.mesReglements.forEach(
              reg => {
                const imgName = this._tools.lowercase(reg['cult.culture']);
                const containerNameEl = "container-reg-" + this._tools.lowercase(reg['cult.culture']);
                // const el = this._elementRef.nativeElement.querySelector(containerNameEl)
                setTimeout(()=>{
                  let el = null;
                  el = document.getElementById(containerNameEl);
                  //console.log("element by ID : ",el) 
                  if(el && !el.querySelector('.canvas')){
                    el.insertAdjacentHTML(
                      'beforeend',
                      '<canvas style="background: transparent url(/assets/icons/produits/green-prod-svg/'+ imgName +'.svg) no-repeat center center;background-size: 50% 50%;width:270px!important" class="canvas  w-100 h-100"></canvas>'
                    )
                      
                    this.canva= el.querySelector('.canvas');
                    // let last = nodes[nodes.length- 1];
                    // this.canva = last;
              
                    // //console.log(nodes) 
                    
                    let donutCtx = this.canva.getContext('2d');
                        var data = {
                          labels: [
                            reg['cult.montantregle']+ "€ PAYÉ",
                            reg['cult.montantavenir']+ "€ À PAYER"
                          ],
                          datasets: [
                              {
                                  "data": [reg['cult.montantregle'], reg['cult.montantavenir']], 
                                  "backgroundColor": [
                                      "#AADB52",
                                      "#84A755"
                                  ]
                              }
                            ]
                        }; 
                    
                    var chart = new Chart(
                      donutCtx,
                      {
                          "type": 'doughnut',
                          "data": data,
                          "options": {
                              "cutoutPercentage": 65,
                              "animation": {
                                  "animateRotate": true
                              },
                              tooltips: {
                                callbacks: {
                                  label: function(tooltipItem, data) {
                                    //console.log(tooltipItem);
                                    //console.log(data);
                                    // const value = tooltipItem.value;
                                    return data.labels[tooltipItem.index];
                                  },
                                }
                              },
                              "legend": { 
                                "display": false
                            },
                            "responsive": false
                          }
                      }
                    );                
                  }
                },500)
                // //console.log(containerNameEl);
                // //console.log("imgName => ",imgName)
              }
            )
          } else {
            this.noRegToShowMessage = reglements[1];
            this.mesReglements = null;
            this.getReglements.unsubscribe();
          }
        }
        )
  }

  ngOnDestroy(){
    this.filtRegListener ? this.filtRegListener.unsubscribe():null;
    this.getCamp ? this.getCamp.unsubscribe():null;
    this.getReglements ? this.getReglements.unsubscribe():null;
  }

  ngDoCheck(){
    this.viewportWidth = window.innerWidth;
  }
}
