import { Component, OnInit, ElementRef } from '@angular/core';
import { Subscription, BehaviorSubject, pipe } from 'rxjs';
import { DashboardService } from 'src/app/services/dashboard.service';
import { FilterService } from 'src/app/services/filter.service';
import { ErrorService } from 'src/app/services/error.service';
import { MesStocksService } from 'src/app/services/mes-stocks.service';
import { LoaderService } from 'src/app/services/loader.service';
import { Router, NavigationEnd } from '@angular/router';
import { Location } from '@angular/common';
import { share, debounceTime, take } from 'rxjs/operators';

@Component({
  selector: 'app-mes-stocks',
  templateUrl: './mes-stocks.component.html'
})
export class MesStocksComponent implements OnInit {

  getMesStocks:Subscription;
  getMesStocksFromFilt:Subscription;
  getMesResultatsAnalyse:Subscription;
  getCamp:Subscription;

  selectedCamp;

  filterListener:Subscription;
  stocks;
  viewPortWidth;
  isAclError:string;
  getStockCount:number = 0;
  filterValue = new BehaviorSubject(null);
  url = this._loc.path();

  noStockMessage;
  filterState;
  constructor(
    private _dashServ: DashboardService,
    private _filtServ: FilterService,
    private _errorServ:ErrorService, 
    private _stockServ: MesStocksService,
    private _loader: LoaderService,
    private _router: Router,
    private _loc: Location,
    public el:ElementRef
  ) {
    this.filterListener = this._filtServ.filterChangeEmitter.subscribe(
      newFilter =>  {
        //console.log("listener mes-stocks")
        this.ngOnInit()
      }
    )
  }

  ngOnInit(){
    window.scrollTo(0, 0);
      if(this._filtServ.filterValue.getValue()){

        this._stockServ.setMesStocks();
        this.getCamp = this._filtServ.selectedCamp.subscribe(
          year => {
            //console.log(year)
            this.selectedCamp  = year;
          }
        )

        this._filtServ.getFilterState().subscribe(
          state => {
            // //console.log("state dans page-nav => ",state);
            this.filterState = state;
          }
        )
        this.getMesStocks = this._stockServ.getStocks().subscribe(
          stocks => {
            // //console.log(stocks)
            if(stocks && stocks[0] == null && stocks[1] == "pas de données"){
              //console.log("pas de données CASE !")
              this.noStockMessage = stocks;
              this.stocks = null;
              this.getMesStocks.unsubscribe();
            } else {
              this.stocks = stocks;
              //console.log(this.stocks)
              this.getMesStocks.unsubscribe();
              this.getStockCount = 0;
            }
          },
          error => {
            // //console.log(error);
            this.getMesStocks.unsubscribe();
          }
        ).add(
            getAnalyses => {
              //console.log("add !!!")
              if(this._filtServ.filterValue.getValue()){
                
                this.getMesResultatsAnalyse = this._stockServ.getResultatsAnalyses(this._filtServ.filterValue.getValue()).subscribe(
                  response => {
                    //console.log(response)
                    if(response[0][""] == "" && response[0]['erreur'] == "0" &&  response[0]['msgerreur'] == "pas de données"){
                      this._stockServ.nbreAnalyses.next(0)
                    } else {
                      const res = JSON.parse(response[0][""]);
                      //console.log(res)
                      this._stockServ.nbreAnalyses.next(res['MesAnalyses'][0].nb);
                      this._stockServ.mesStocksAnalyses.next(res['MesAnalyses'])
                      // this._stockServ.emitAnalyseNumber(this._stockServ.nbreAnalyses.getValue())
                      // //console.log(this._stockServ.mesStocksAnalyses.getValue()
                      this.getMesResultatsAnalyse.unsubscribe();
                    }
                  },
                  error => {
                    this.getMesResultatsAnalyse.unsubscribe();
                  }
                )
              } 
            }
          )
      }
  }
  
  ngAfterViewChecked() {  
    
  }

  ngOnChanges(){
    
  }

  ngDoCheck(){
    this.viewPortWidth = window.innerWidth;
    // this.isAclError = this._errorServ.getMessage();
    
    // //console.log(this.getMesStocks)
  }

  ngOnDestroy(){
    this.filterListener ? this.filterListener.unsubscribe():null;
    this.getMesStocks ? this.getMesStocks.unsubscribe():null;
  }
  

}
