import { Component, OnInit } from '@angular/core';
import { DashboardService } from 'src/app/services/dashboard.service';
import { ContratsService } from 'src/app/services/contrats.service';

@Component({
  selector: 'app-dash-info-contrats',
  templateUrl: './dash-info-contrats.component.html'
})
export class DashInfoContratsComponent implements OnInit {

  contratsToSign;
  datas;
  constructor(private dashServ: DashboardService,private _contratServ: ContratsService) { }

  ngOnInit() {
    console.log(this._contratServ.contratsNbr.getValue())
    if(!this._contratServ.contratsAsigner.getValue()){
      this._contratServ.getMesContratsAsigner().subscribe(
        resp => {
          console.log(resp)
          if(resp){
            if(resp[0]){
              const contrats:any = Array.from([resp])        
              this.contratsToSign = contrats[0].length;
            } else {
              this.contratsToSign = 0;
              console.log("CASE", this.contratsToSign)
            }
          }
        }
      )

    } else {
      this.contratsToSign = this._contratServ.contratsNbr.getValue();
    }
    this.datas = this.dashServ.getUser();
    // console.log(this.contrats)
  }

}
