import { Component, OnInit,Pipe, PipeTransform, ViewChild} from '@angular/core';

import { Location } from '@angular/common';
import { Subscription, BehaviorSubject, interval } from 'rxjs';
import { DashboardService } from 'src/app/services/dashboard.service';
import { FilterService } from 'src/app/services/filter.service';
import { ErrorService } from 'src/app/services/error.service';
import { MonGesService } from 'src/app/services/mon-ges.service';
import { Router, NavigationEnd } from '@angular/router';
import { ToastrService } from 'ngx-toastr';


@Component({
  selector: 'app-mon-ges',
  templateUrl: './mon-ges.component.html'
})


export class MonGesComponent implements OnInit {

/*viewportWidth: any;*/
//Déclaration des variables local

getMaListeHistorique: Subscription;
nbClic: number;
gesListHistoSimu;
filterListener:Subscription;
noGesToShowMessage;
BtnSimuBool: boolean = true;
NextRoute;
localCampagneActive;
procedureSignatureActive;
getMaListSimuQuestion: Subscription;


  constructor(private _filterServ: FilterService,private _gesServ: MonGesService, private _toaster: ToastrService) {
    //console.log("listener mon-ges")
   
  }

    EtapeEligibilite(tiersHistoSimu){
      //console.log(tiersHistoSimu);
      if (tiersHistoSimu.statut_code == "GEPAC") {
        this.NextRoute = "/mes-labelisations/mon-ges/pre-questionnaire/GEPA";
      }else if (tiersHistoSimu.statut_code == "INCOMP") {
        this.NextRoute = "/mes-labelisations/mon-ges/questionnaire/";
      }else{
        this.NextRoute = "/mes-labelisations/mon-ges/documentaire/";
      }

      return tiersHistoSimu;
    }


  ngOnInit() {
    window.scrollTo(0, 0);
    //console.log("ngOnInit -----> setGesSimuHisto");
    this._gesServ.campagneActive.subscribe(
      campagneActive =>{
        this.localCampagneActive = campagneActive;
      }
      )     
    ////console.log(this.localCampagneActive[0]['params']);
    //récupération de la liste d'essai de simulation d'éligibilité
    this._gesServ.setGesSimuHisto();
    this.getMaListeHistorique = this._gesServ.getGesSimuHisto().subscribe(
      tiersHistoSimu => {
        //console.log(tiersHistoSimu);
        if(tiersHistoSimu[0] == null && tiersHistoSimu[1] == "Aucune simulation d'éligibilité éffectuée."){
          //console.log("pas de données CASE !")
          this.noGesToShowMessage = tiersHistoSimu[1];
          this.gesListHistoSimu = null;
          this.getMaListeHistorique.unsubscribe();
        
        } else {
          this.noGesToShowMessage = null;
          this.gesListHistoSimu = this.EtapeEligibilite(tiersHistoSimu[0]);
          if (this.gesListHistoSimu["statut"] == "Non primable") {
            this.BtnSimuBool = false;
          }
          //console.log(this.gesListHistoSimu);
          this.getMaListeHistorique.unsubscribe();
        }  
      },error => {
        this.getMaListeHistorique.unsubscribe();
      }
    )
////console.log(this.gesListHistoSimu);

    this.nbClic = 0;
  }

  test_edi(){
    this._gesServ.getfileEDI()
  }
  ngOnDestroy(){
    this.filterListener ? this.filterListener.unsubscribe(): null;
    this.getMaListeHistorique ? this.getMaListeHistorique.unsubscribe() : null;
    
  }
}


