import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class FilterDocumentsService {

  constructor() { }

  public filterDocumentState = new BehaviorSubject<any>(
    {
      searchText: "",
      sortBy: "DATE",
      searchOn: "NUMEROS",
      type: "ALL"  
    }
  )

  setFilterDocumentType(newType:string){
    const currentValue = this.filterDocumentState.getValue();
    // //console.log(currentValue);
    this.filterDocumentState.next(
      {
        searchText: currentValue.searchText,
        sortBy: currentValue.sortBy,
        searchOn: currentValue.searchOn,
        type: newType  
      }
    )
    //console.log(this.filterDocumentState.getValue())
  }
  setFilterDocumentSortBy(sortBy:string){
    const currentValue = this.filterDocumentState.getValue();
    // //console.log(currentValue);
    this.filterDocumentState.next(
      {
        searchText: currentValue.searchText,
        sortBy: sortBy,
        searchOn: currentValue.searchOn,
        type: currentValue.type  
      }
    )
    //console.log(this.filterDocumentState.getValue())
  }

  setFilterDocumentSearchText(searchText:string){
    const currentValue = this.filterDocumentState.getValue();
    // //console.log(currentValue);
    this.filterDocumentState.next(
      {
        searchText: searchText,
        sortBy: currentValue.sortBy,
        searchOn: currentValue.searchOn,
        type: currentValue.type  
      }
    )
    //console.log(this.filterDocumentState.getValue())
  }

  setFilterDocumentSearchOn(searchOnSelected){
    const currentValue = this.filterDocumentState.getValue();
    this.filterDocumentState.next(
      {
        searchText: currentValue.searchText,
        sortBy: currentValue.sortBy,
        searchOn: searchOnSelected,
        type: currentValue.type  
      }
    )
    //console.log(this.filterDocumentState.getValue())
  }
}
