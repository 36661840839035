import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { ActivatedRoute } from '@angular/router';
import { ToolsService } from 'src/app/services/tools.service';
import { FilterService } from 'src/app/services/filter.service';
import { Subscription } from 'rxjs';
import { MesStocksService } from 'src/app/services/mes-stocks.service';
import { MonGesService } from 'src/app/services/mon-ges.service';
import { share } from 'rxjs/operators';

@Component({
  selector: 'app-page-head',
  templateUrl: './page-head.component.html'
})
export class PageHeadComponent implements OnInit {

  pageUrl;
  param;
  spaceParam;
  viewportWidth;

  getCamp:Subscription;
  getMesResultatsAnalyse: Subscription;
  selectedCamp;
  getParam
  paramList;

  anlsNbrListener:Subscription;

  nbrAnalyses;
  constructor(private _loc: Location,private route: ActivatedRoute, private _tools: ToolsService, 
              private _filtServ: FilterService,private _stockServ: MesStocksService,private _gesServ: MonGesService) {}

  ngOnInit() {
    //console.log("init pagehead")
    this.getCamp = this._filtServ.selectedCamp.subscribe(
      year => {
        //console.log(year)
        this.selectedCamp  = year;
      }
    );
    this.getParam = this._gesServ.getStaticsData().subscribe(
        p => {
          if(p){
            this.paramList= p
          }
        }
      )
    this.pageUrl = this._loc.path();
    
    if(this.pageUrl == '/mes-stocks'){
          this._stockServ.nbreAnalyses.subscribe(
            nbre => {
              this.nbrAnalyses = nbre;
              //console.log("nbre dans page-head => ",this.nbrAnalyses)
            }
          );
    }

    this.param = this.route.snapshot.paramMap.get("produit");
    // console.group(this.param)
    if(this.param){
      this.spaceParam = this._tools.helperUrlProduct(this.param);
    }
  }

  ngDoCheck(){
    this.viewportWidth = window.innerWidth;
  }

  ngOnDestroy(){
    this.getCamp ?  this.getCamp.unsubscribe():null;
    this.getMesResultatsAnalyse ? this.getMesResultatsAnalyse.unsubscribe():null;
    this.getParam ? this.getParam.unsubscribe():null;
  }

}
