import { Component, OnInit, ViewChild } from '@angular/core';
import { LoaderService } from 'src/app/services/loader.service';
import { FilterService } from 'src/app/services/filter.service';
import { ToastContainerDirective, ToastrService } from 'ngx-toastr';
import { ToastrStateService } from 'src/app/services/toastr-state.service';
import { Subscription, BehaviorSubject } from 'rxjs';
import { ErrorService } from 'src/app/services/error.service';

@Component({
  selector: 'app-home-layout',
  templateUrl: './home-layout.component.html',
  styleUrls: ['./home-layout.component.scss']
})
export class HomeLayoutComponent implements OnInit {

  isLoading:boolean;
  listenerSub:Subscription;
  errorListener:Subscription;



  @ViewChild(ToastContainerDirective,null) toastContainer: ToastContainerDirective; 

  constructor(private loaderService: LoaderService,private _filtServ: FilterService,
              private toastrService: ToastrService,private _toasterState: ToastrStateService,
              private _errorServ: ErrorService) {
    this.isLoading = this.loaderService.isLoading.getValue();
    this._errorServ.ErrorMode.next(false);
    this._filtServ.initFilter();
    this.listenerSub = this._toasterState.toasterEventEmitter.subscribe(newFilter => {
      this.displayToasterMessage();
    })
   }
   
  ngOnInit() {
    this.toastrService.overlayContainer = this.toastContainer;
  }

  ngAfterContentChecked(){
    this.isLoading = this.loaderService.isLoading.getValue();
  }

  ngOnDestroy(){
    this.listenerSub ? this.listenerSub.unsubscribe:null;
  }

  displayToasterMessage(){
    if(this._toasterState.toasterState.getValue() != null){
      const toasterResponse = this._toasterState.toasterState.getValue();
      if(toasterResponse.type == "error"){
        this.toastrService.error(toasterResponse.message)
      } else if (toasterResponse.type == "success"){
        this.toastrService.success(toasterResponse.message)
      }
    }
  }

}
