import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Subject, BehaviorSubject } from 'rxjs';
import { Produit } from '../models/produit';

import { environment } from "../../environments/environment"; 

@Injectable({
  providedIn: 'root'
})
export class ButtonService {
  _env = environment;
  constructor() { }

  btnState = new BehaviorSubject<string>("");

  setBtnState(state){
    this.btnState.next(state);
  }

  getBtnState(){
    return this.btnState.getValue();
  }
}
