import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject, Subscription, Observable, pipe } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { MonGesService } from 'src/app/services/mon-ges.service';
import { MonSaiService } from 'src/app/services/mon-sai.service';
import { UserService } from 'src/app/services/user.service';
import { debounceTime, share, first, map, filter, switchMap } from 'rxjs/operators';
@Injectable({
  providedIn: 'root'
})
export class SignatureElectroniqueService {

  position: number[];
  _env=environment;

  headers = new HttpHeaders({
    'Content-Type': 'application/json',
    'Authorization': `Bearer ${this._env.BZ_TOKEN_YOUSIGN}`
  });
  requestOptions = { headers: this.headers };

  requestBodyPayload = {
    name: 'Procédure yousign',
    delivery_mode: 'email',
    timezone: 'Europe/Paris',
    external_id: "",
    custom_experience_id: "3c6cbba4-d2e7-4caf-8566-78a0066875b3",
  };


  constructor(private _http: HttpClient, private _gesServ : MonGesService, private _userServ : UserService,private _saiServ : MonSaiService) {

  }

  creerProcedureYousign(t : string, param, mail_proc_fini : object, phone? : string){

    return this._gesServ.getStaticsData().pipe(map(res=>{
      if (!param) {
        param = {};
      }
      param['external_id'] = t;
      delete param['pwd'];
      this.requestBodyPayload.external_id = this.transformObjectToJson(param);

      var reponse = this._http.post(this._env.YOUSIGN_BASE_URL + "signature_requests", this.requestBodyPayload, this.requestOptions)
        console.log(reponse)
        return reponse
      }))
  }

  returnRequestSignerUrl(procedureId){
    const headers = new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${this._env.BZ_TOKEN_YOUSIGN}`,
        'skip' : 'true'
     });
    const requestOptions = { headers: headers };    
     var reponse = this._http.get(`${this._env.YOUSIGN_BASE_URL}signature_requests/${procedureId}/signers`,requestOptions)
      console.log(reponse)
      return reponse   
  }

  transformObjectToJson(input: any): string {
    let jsonString = JSON.stringify(input);
    jsonString = jsonString.replace(/{/g, '_');
    jsonString = jsonString.replace(/}/g, '-');
    jsonString = jsonString.replace(/:/g, '@');
    jsonString = jsonString.replace(/,/g, '+');
    jsonString = jsonString.replace(/[^a-zA-Z0-9_\-@.%+]/g, '');
    return jsonString;
  }

  ajouterFichierProcedure(procedureId : string, file : string, nom : string){

    // create the blob object with content-type "application/pdf"               
    var blob = new Blob( [this.convertBase64ToBinary(file)], { type: "application/pdf" });
    var filename = this.replaceSlashes(nom);
    const form = new FormData();
    form.append('file', blob,filename);
    form.append('nature', 'signable_document');


    const options = {
      headers: {
        accept: 'application/json',
        authorization: `Bearer ${this._env.BZ_TOKEN_YOUSIGN}`
      }
    };

    return this._http.post(`${this._env.YOUSIGN_BASE_URL}signature_requests/${procedureId}/documents`, form, options)
  }

  ajouterLeSignataire(documentId : string, procedureId : string,signer_page : string,position_signature : string){
      this.position = position_signature.split(",").map(numStr => +numStr.trim());
      const info_user = this._userServ.getUserConnected();
      const requestBodyPayload = {
        info: {
          first_name: info_user.userFirstName.replace("+"," "),
          last_name: info_user.userLastName.replace("+"," "),
          email: info_user.userMail,
          phone_number: this.formatFrenchPhoneNumber(info_user.userTel),
          locale: 'fr',
        },
        signature_authentication_mode: 'otp_sms',
        signature_level: 'electronic_signature',
        fields: [{
          document_id: documentId,
          type: 'signature',
          height: this.position[0],
          width: this.position[1],
          page: signer_page,
          x: this.position[2],
          y: this.position[3],
        },
        {
        document_id: documentId,          
        type: 'mention',
        height: 24 ,
        page: signer_page,
        x: this.position[2],
        y: this.position[3],
        mention: "Lu et approuvé le " + new Date().toLocaleDateString("fr-FR") + " par"
      }], //"101,85,130,700"
      };
      return this._http.post(`${this._env.YOUSIGN_BASE_URL}signature_requests/${procedureId}/signers`, requestBodyPayload, this.requestOptions)
  }


/*  addMention(documentId : string,procedureId:string, signerId:string, signer_page:string,position_signature : string){
console.log("----------------------------------------------------------ddddd")
    console.log(signerId)
    console.log("----------------------------------------------------------ddddd")
      this.position = position_signature.split(",").map(numStr => +numStr.trim());    
    const requestBodyPayload = {    
        signer_id: signerId,
        document_id: documentId,
        type: 'mention',
        height: this.position[0] ,
        width: this.position[1],
        page: signer_page,
        x: this.position[2],
        y: this.position[3],
        mention: "Lu et approuvé le " + new Date().toLocaleDateString("fr-FR") + " par"
      }
    return this._http.post(`${this._env.YOUSIGN_BASE_URL}signature_requests/${procedureId}/documents/${documentId}/fields`, requestBodyPayload, this.requestOptions)

  }*/

  convertBase64ToBinary(base64 : string): Uint8Array {
    var binary = atob(base64.replace(/\s/g, ''));
    var len = binary.length;
    var buffer = new ArrayBuffer(len);
    var view = new Uint8Array(buffer);
    for (var i = 0; i < len; i++) {
        view[i] = binary.charCodeAt(i);
    }
    return view;
  }

  formatFrenchPhoneNumber(phoneNumber: string): string {
    if (phoneNumber.startsWith('0')) {
      return '+33' + phoneNumber.substring(1);
    }
    return phoneNumber;
  }

  replaceSlashes(input: string): string {
    return input.replace(/\//g, '_');
  }

  replaceUnderscores(input: string): string {
    let count = 0;
    return input.replace(/_/g, (match) => {
      count++;
      return count <= 3 ? '/' : match;
    });
  }


/*  ajouterSignatureProcedure(fileId, memberId, page_signature : number, position_signature : string){
    
    var body = {
          "file": fileId,
          "member": memberId,
          "page": page_signature,
          "position": position_signature,
          "mention": "Lu et approuvé le " + new Date().toLocaleDateString("fr-FR") + " par"
          
      }
      return this._http.post("https://api.yousign.com/file_objects", body, this.requestOptions)
  }*/


  registerDocument(proc, url, sai?){
    const options = {
        headers: {
          accept: 'application/json',
          authorization: `Bearer ${this._env.BZ_TOKEN_YOUSIGN}`
        }
      };
      //recuperation du filename du document
      this._http.get(`${this._env.YOUSIGN_BASE_URL}signature_requests/${proc["id"]}/documents`, options).subscribe(file =>{

        if(sai){
          console.log("registerDocument SAI");
        this._saiServ.getStaticsData().subscribe(param=>{
            var data = new FormData()
              data.append("params",JSON.stringify(param[0]));
              data.append("proc_id", JSON.stringify('/procedures/' + proc["id"]));
              data.append("member_id", JSON.stringify('/members/' + proc["signers"][0]["id"]));
              data.append("file_id", JSON.stringify('/files/' + proc["documents"][0]["id"]));
              data.append("nom_fichier", JSON.stringify(this.replaceUnderscores(file[0]["filename"])));
              this._http.post(this._env.BZ_API_BASE_URL  + url + "/format/json", data,{withCredentials:true}).subscribe()
          })
        }else{

          console.log("registerDocument ges / contrat");
          this._gesServ.getStaticsData().pipe(filter(val => val)).subscribe(param=>{
            var data = new FormData()
              data.append("params",JSON.stringify(param));
              data.append("proc_id", JSON.stringify('/procedures/' + proc["id"]));
              data.append("member_id", JSON.stringify('/members/' + proc["signers"][0]["id"]));
              data.append("file_id", JSON.stringify('/files/' + proc["documents"][0]["id"]));
              data.append("nom_fichier", JSON.stringify(this.replaceUnderscores(file[0]["filename"])));
              this._http.post(this._env.BZ_API_BASE_URL  + url + "/format/json", data,{withCredentials:true}).subscribe()
          })        
        }
    })
  }

  startProcedure(procedureId){
    return this._http.post(`${this._env.YOUSIGN_BASE_URL}signature_requests/${procedureId}/activate`, null, this.requestOptions);
  }


  recupererProcedureSignee(proc){
     
    const headers = new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${this._env.BZ_TOKEN_YOUSIGN}`,
        'skip' : 'true'
     });
    const requestOptions = { headers: headers };
    var formData = new FormData();
    
    var subscribe_params
     subscribe_params = this._gesServ.getStaticsData().subscribe(
        para => {
          if(para){

            subscribe_params ? subscribe_params.unsubscribe():null;
            console.log(para);
            formData.append("params", JSON.stringify(para));
            this._http.get(`${this._env.YOUSIGN_BASE_URL}signature_requests/${proc["id"]}`, requestOptions).subscribe(
                procSigne =>{
                  let pS: any = {};
                  pS = procSigne;
                  if (pS.status == 'done') {
                    proc.status = 'done';
                  }
                }
              )
          }
        }
      )
    
  }


}
