import { Component, OnInit } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { ErrorService } from 'src/app/services/error.service';

@Component({
  selector: 'app-not-allow-acl',
  templateUrl: './not-allow-acl.component.html',
  styleUrls: ['./not-allow-acl.component.scss']
})
export class NotAllowAclComponent implements OnInit {

  constructor(private _errorServ: ErrorService) {
    
   }
  message;

  ngOnInit() {

  }

  ngDoCheck(){
    this.message = this._errorServ.getMessage();
  }

}
