import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { FilterService } from './filter.service';
import { Subscription, Subject, BehaviorSubject } from 'rxjs';
import { environment } from 'src/environments/environment';
import { FilterDocumentsService } from './filter-documents.service';
import { all } from 'q';

@Injectable({
  providedIn: 'root'
})
export class DocumentService {

  constructor(private _http: HttpClient, private _filtServ: FilterService, private _docsFiltServ: FilterDocumentsService){ }
  _env = environment;

  getFilter: Subscription;
  getDocs: Subscription;
  allDocuments = new Subject<any>();
  allDocsBeh = new BehaviorSubject<any>(null);

  public searchResult = new BehaviorSubject(null);


  setAllDocuments(){
    this.getFilter = this._filtServ.getFilter().subscribe(
      filterConf => {
        // //console.log(filterConf)
        const filterData = new FormData();
        filterData.append("filter",JSON.stringify(filterConf));
        this.getDocs = this._http.post(this._env.BZ_API_BASE_URL + "documents/index/format/json",filterData,{withCredentials:true}).subscribe(
          (docs) =>{
            //console.log(docs)
            this.allDocuments.next(docs);
            this.allDocsBeh.next(docs)
            this.getFilter.unsubscribe();
            this.getDocs.unsubscribe();
            
          }
        )
      }
    )
  }
  getDocuments(){
    return this.allDocsBeh.getValue();
  }

  getFilteredDocuments(alldocs){
    const docFiltConf = this._docsFiltServ.filterDocumentState.getValue();
    const searchText = docFiltConf.searchText;
    const sortBy = docFiltConf.sortBy;
    const searchOn = docFiltConf.searchOn;
    const type = docFiltConf.type;
 
    if(searchText == "" && type == "ALL"){ // Si la recherche est vide et la categorie = TOUT
        
      //on garde tous les docs et on trie par date
      if(sortBy == "DATE"){
        alldocs.sort((a,b)=>{
          const dateA = new Date("20" + a.date[6]+a.date[7] + "-" + a.date[3]+a.date[4] + "-" + a.date[0]+a.date[1]);
          const dateB = new Date("20" + b.date[6]+b.date[7] + "-" + b.date[3]+b.date[4] + "-" + b.date[0]+a.date[1]);
          // //console.log(dateA);
          a.dateObj = dateA;
          b.dateObj = dateB;
          // //console.log(dateA);
          // //console.log(dateB);
          return (a.dateObj.getTime() > b.dateObj.getTime()) ? -1 : 1;
        })   
        this.searchResult.next(alldocs);
        return alldocs; 
        // //console.log(alldocs) 
      }

      //on garde tous les docs et on trie par Nom
      if(sortBy == "NAME"){
        alldocs.sort((a,b)=>{
          return (a.objet > b.objet) ? 1 : -1;
        })  

        this.searchResult.next(alldocs);
        return alldocs;  
        //console.log(alldocs)      
      }

    } else if (searchText == "" && type != "ALL"){ // Si la recherche est vide et une catégorie est séléctionnée

      //Ici il faut ne garder que les docs de la categorie séléctionnée
      const docsToShow = [];
  
      alldocs.forEach(
        x=>{
          if (x.type == type){
            docsToShow.push(x);
          } 
        }
      )

        //On trie ce qu'il reste par date
      if(sortBy == "DATE"){
        docsToShow.sort((a,b)=>{
          const dateA = new Date("20" + a.date[6]+a.date[7] + "-" + a.date[3]+a.date[4] + "-" + a.date[0]+a.date[1]);
          const dateB = new Date("20" + b.date[6]+b.date[7] + "-" + b.date[3]+b.date[4] + "-" + b.date[0]+a.date[1]);
          a.dateObj = dateA;
          b.dateObj = dateB;
          return (a.dateObj.getTime() > b.dateObj.getTime()) ? -1 : 1;
        })
        
        this.searchResult.next(docsToShow);
      }

      if(sortBy == "NAME"){
        docsToShow.sort((a,b)=>{
          return (a.objet > b.objet) ? -1 : 1;
        })  
        this.searchResult.next(docsToShow);       
      }

    } else if(searchText != "" && type == "ALL"){
      let docsToShow = [];

      if(searchOn == "NUMEROS"){ //Recherche sur les numéros

        alldocs.forEach(
          (x,index)=>{
            const obj = x.tdocname.toString();
            const txt = searchText.toString();
           
            if( obj.startsWith(txt)) {
              docsToShow.push(x);
            }
          }
        )     
      } else { //Recherche sur les titres
        alldocs.forEach(
          x=>{
            const obj = x.objet.toString().toLowerCase();
            const txt = searchText.toString().toLowerCase();
            // //console.log(obj)
            // //console.log(txt)
            if(obj.startsWith(txt)){
              docsToShow.push(x);
            }
          }
        )     
        
      }
      //console.log(docsToShow)
      if(docsToShow && sortBy == "DATE"){

        docsToShow.sort((a,b)=>{
          const dateA = new Date("20" + a.date[6]+a.date[7] + "-" + a.date[3]+a.date[4] + "-" + a.date[0]+a.date[1]);
          const dateB = new Date("20" + b.date[6]+b.date[7] + "-" + b.date[3]+b.date[4] + "-" + b.date[0]+a.date[1]);
          // //console.log(dateA);
          a.dateObj = dateA;
          b.dateObj = dateB;
          // //console.log(dateA);
          // //console.log(dateB);
          return (a.dateObj.getTime() > b.dateObj.getTime()) ? -1 : 1;
        })   

        this.searchResult.next(docsToShow)
        // //console.log(alldocs) 
      }

      if(docsToShow && sortBy == "NAME"){
        docsToShow.sort((a,b)=>{
          return (a.objet > b.objet) ? 1 : -1;
        })  
        this.searchResult.next(docsToShow)
        
      }
      
    } else if (searchText != "" && type != "ALL"){
      const docsToShow = [];

      const allDocsFiltered = [];
      alldocs.forEach(
        x => {
          if(x.type == type){
            allDocsFiltered.push(x);
          }
        }
      )
      
      if(searchOn == "NUMEROS"){
        allDocsFiltered.forEach(
          (x,index)=>{
            const obj = x.tdocname.toString();
            const txt = searchText.toString();
           
            if( obj.startsWith(txt)) {
              docsToShow.push(x);
            }
          }
        )
      } else {
        allDocsFiltered.forEach(
          x=>{
            const obj = x.objet.toString().toLowerCase();
            const txt = searchText.toString().toLowerCase();
            // //console.log(obj)
            // //console.log(txt)
            if(obj.startsWith(txt)){
              docsToShow.push(x);
            }
          }
        )
      }
      



      if(docsToShow && sortBy == "DATE"){
        docsToShow.sort((a,b)=>{
            const dateA = new Date("20" + a.date[6]+a.date[7] + "-" + a.date[3]+a.date[4] + "-" + a.date[0]+a.date[1]);
            const dateB = new Date("20" + b.date[6]+b.date[7] + "-" + b.date[3]+b.date[4] + "-" + b.date[0]+a.date[1]);
            // //console.log(dateA);
            a.dateObj = dateA;
            b.dateObj = dateB;
            // //console.log(dateA);
            // //console.log(dateB);
            return (a.dateObj.getTime() > b.dateObj.getTime()) ? -1 : 1;
        })
        this.searchResult.next(docsToShow)
      } else if(docsToShow && sortBy == "NAME"){
        docsToShow.sort((a,b)=>{
            return (a.objet > b.objet) ? 1 : -1;
          }) 
          this.searchResult.next(docsToShow)
      }
    }
  }

  dlDocument_Blank(path){
    // //console.log(path)
    // window.open(this._env.BZ_API_BASE_URL + "telechargement/directdownload/path/"+path,"_blank");
    return this._http.get(this._env.BZ_API_BASE_URL + "telechargement/directdownload/path/"+path, {withCredentials:true}).subscribe(
      doc => {
        // //console.log(doc);
      }
    )
  }
}
