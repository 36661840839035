import { Component, OnInit } from '@angular/core';
import { FilterService } from 'src/app/services/filter.service';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-lost-session',
  templateUrl: './lost-session.component.html',
  styleUrls: ['./lost-session.component.scss']
})
export class LostSessionComponent implements OnInit {

  constructor(private _filtServ: FilterService, private _authServ:AuthService) { 
    this._filtServ.initFilter();
  }

  ngOnInit() {
    setTimeout(()=>{
      this._authServ.logOut();
    },2000)
  }

}
