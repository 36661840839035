import { Component, OnInit } from '@angular/core';
import { DashboardService } from 'src/app/services/dashboard.service';
import { Subscription } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { ButtonService } from 'src/app/services/button.service';
import { FilterService } from 'src/app/services/filter.service';
import { MesStocksService } from 'src/app/services/mes-stocks.service';
import { DownloadService } from 'src/app/services/download.service';
import { debounceTime, share, take, debounce } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import { environment } from 'src/environments/environment';
import { ErrorService } from 'src/app/services/error.service';

@Component({
  selector: 'app-stocks-detail',
  templateUrl: './stocks-detail.component.html'
})
export class StocksDetailComponent implements OnInit {

  getMesStocks: Subscription;
  getMesStocksFerme: Subscription;
  dlSub: Subscription;
  filtChangeSub: Subscription;

  _env = environment;
  stocks;
  mvmnts;
  fermes;
  btnState;
  mvmntSelected = {id:null,tdoc:null};
  qualitesSelected;
  selectedDownload = "";
  param = this.route.snapshot.paramMap.get("produit");
  p: number = 1; //page de depart pagination
  getCamp: Subscription;
  selectedCamp

  noDepotMessage;
  noFermeMessage;

  noStockMesssage;
 

  constructor(private dashServ: DashboardService, 
              private route: ActivatedRoute, 
              private _btnServ: ButtonService, 
              private _filtServ: FilterService, 
              private _stockServ: MesStocksService,
              private _dlServ: DownloadService,
              private _toaster: ToastrService,
              private _router: Router,
              private _errorServ: ErrorService) {
                this.filtChangeSub = this._filtServ.filterChangeEmitter.subscribe(
                  newFilter =>  {
                    //console.log("emit filter srocks details")
                    this.ngOnInit()
                  }
                )
  }

  ngOnInit() {
    window.scrollTo(0, 0)
    const testProductName = this._env.BZ_PRODUCTS_NAMES.filter(prodName => prodName == this.param);
    //console.log(testProductName)
    if(testProductName.length == 0){
      this._router.navigate(['404'])
      return;
    } else {
      this._stockServ.setMesStocksDetails(this.param);
      this.btnState = this._btnServ.getBtnState(); //btn state == "DÉPÔT SILOS BZ"
      this.getCamp = this._filtServ.selectedCamp.subscribe(
        year => {
          //console.log(year)
          this.selectedCamp  = year;
        }
      )
      this.getMesStocks = this._stockServ.getStocksDetails().pipe(share()).subscribe(
        stockDetails => {
          //console.log(stockDetails);
            if(stockDetails && !stockDetails['depot'] && !stockDetails['ferme']){
              //console.log("CASE NO DETAIL")
              this.noStockMesssage = stockDetails[1];
            } else {
              this.stocks = stockDetails;
              this.noStockMesssage = null;
  
              if(stockDetails && stockDetails['depot'] &&  this.btnState == 'DÉPÔT SILOS BZ') {
                this.noDepotMessage = null;
                this.selectMvmnt(this.stocks.depot[0]['depot.identifiant'], this.stocks.depot[0]['depot.identifiant']) //pour selectionner le premier mvmnt depot
                this.setQualites(this.stocks.depot[0].qualitedepot); // premier détail par défaut mvmnt dépôt
              } else {
                this.noDepotMessage = "Pas de dépôt silo BZ"
                    //console.log("PAS DE DEPOT")
              }
    
              if(stockDetails && stockDetails['ferme'] && this.btnState == 'STOCK FERME DISPONIBLE'){
                this.noFermeMessage = null;
                //console.log(stockDetails['ferme'])
                this.selectMvmnt(this.stocks.ferme[0]['depot.identifiant'], this.stocks.depot[0]['depot.identifiant']) //pour selectionner le premier mvmnt depot
                this.setQualites(this.stocks.ferme[0].qualitedepot);
              } else {
                this.noFermeMessage = "Pas de stock ferme pour ce produit";
              }
            }
          
          
          
  
          // //console.log(this.stocks);
          // //console.log(this.stocks.ferme);
  
          // //Pour getter le stock ferme disponible
          // //=> PAS DE DATAS pour le moment //
          // this.fermes = this.stocks.ferme;
  
          this.getMesStocks ? this.getMesStocks.unsubscribe(): null;
        },error => {
          this.getMesStocks ? this.getMesStocks.unsubscribe(): null;
        }
      );
    }
  }

  ngDoCheck(){
    this.btnState = this._btnServ.getBtnState();
  }

  selectMvmnt(id,tDoc){
    this.mvmntSelected.id = id;
    this.mvmntSelected.tdoc = tDoc;
    //console.log(this.mvmntSelected);
  }

  setQualites(qualitesArray){
    //console.log(qualitesArray);
    this.qualitesSelected = qualitesArray;
  }

  // reset(){
  //   this.mvmntSelected=null;
  //   this.qualitesSelected=null;
  // }

  downloadDocMvmnt(mvmntId,mvmntTDoc){
    //console.log(mvmntId,mvmntTDoc);
    if(mvmntId && mvmntTDoc){
      this.dlSub = this._dlServ.downloadDocument(mvmntId,mvmntTDoc).subscribe(
        response => {
            //console.log(response)
            if(response['type'] == "application/download"){
              let dataType = response['type'];
              let binaryData = [];
              binaryData.push(response);
              let downloadLink = document.createElement('a');
              //console.log(downloadLink)
              downloadLink.href = window.URL.createObjectURL(new Blob(binaryData, {type: dataType}));
              downloadLink.setAttribute('download',mvmntTDoc + "-" + mvmntId);
              document.body.appendChild(downloadLink);
              downloadLink.click();
            } else {
              this._toaster.error("Aucun document trouvé! Echec du téléchargement")
            }
          
        }, 
        error => {
          //console.log(error);
        }
      )
    }
  }

  ngOnDestroy(){
    this.getCamp ? this.getCamp.unsubscribe():null;
    this.dlSub ? this.dlSub.unsubscribe():null;
    this.getMesStocks ? this.getMesStocks.unsubscribe():null;
    this.filtChangeSub ? this.filtChangeSub.unsubscribe():null;
  }

}
