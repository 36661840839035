import { Injectable } from '@angular/core';
import {
    HttpErrorResponse,
    HttpResponse,
    HttpRequest,
    HttpHandler,
    HttpEvent,
    HttpInterceptor
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { LoaderService } from './services/loader.service';
import { AuthService } from './services/auth.service';
import { catchError } from 'rxjs/operators';
import { Router } from '@angular/router';
import { ErrorService } from './services/error.service';
import { ToastrService } from 'ngx-toastr';
 
@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
    private requests: HttpRequest<any>[] = [];
 
    constructor(private _authServ: AuthService,private _router: Router,private _errorServ: ErrorService,private _toaster: ToastrService) { }
 
    intercept(req: HttpRequest<any>, next: HttpHandler) {
        return next.handle(req).pipe(
            catchError(
                (error: HttpErrorResponse) => {
                    //console.log(error);
                    if(error.status == 401 && error.error.errorCntxt == "not_allowed"){
                        // this._router.navigate(['lost_session']);
                        this._errorServ.setMessage(error.error.message)
                    } else if(error.status == 401) {
                        //console.log("REDIRECTION 401 SESSION PERDUE 5SECONDES PUIS LOGIN");
                        this._toaster.info("Session perdue, veuillez vous reconnecter")
                        this._authServ.logOut();
                        // this._router.navigate(['lost_session']);
                    } else if(error.status == 404) {
                        this._router.navigate(['404']);

                    } else if(error.status == 500){ 

                        //Faire une erreur 500
                        this._toaster.error("Erreur 500, erreur serveur si le problème  persiste, veuillez nous contacter. Vous allez être redirigé au login.")
                        setTimeout(() => {
                           // this._authServ.logOut();                            
                        }, 3000);
                    } else {
                        // this._router.navigate(['lost_session']);
                        // this._authServ.logOut();
                    }
                    // this._authServ.logOut();
                    return throwError(error);
                }
            )
        );
     
    }
}