import { Injectable, EventEmitter } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { FilterService } from './filter.service';

import { Subscription, Subject, BehaviorSubject } from 'rxjs';

import { NgForm } from '@angular/forms';
import { debounceTime, share } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ContratsService {
  getFilterContent:Subscription;
  getContratsIndexes:Subscription;

  getFilterAS:Subscription;
  getContratsAsigner: Subscription;

  typesSubArray: Subscription[];

  _env = environment;
  contratsIndexes = new BehaviorSubject(null);
  contratsAsigner = new BehaviorSubject(null);
  contratsNbr = new BehaviorSubject(null);

  contratIndexesFromCommercialisation = new BehaviorSubject(null);
  reloadContratsEmitter: EventEmitter<any>;

  contratTypes = []

  constructor(private _http: HttpClient,private _filtServ: FilterService) {
    this.reloadContratsEmitter = new EventEmitter();
  }


  emitReloadEvent():void {
    //console.log("emit filter action")
    this.reloadContratsEmitter.emit();
  }

  setMesContratsIndexes(){
    this.contratTypes = [];
    this.getFilterContent = this._filtServ.getFilter().pipe(debounceTime(5),share()).subscribe(
      FilterContent => {
        const filter = this._filtServ.createFilterFromCheckedValues(FilterContent);
        const filterData = new FormData();     
        // //console.log(filter);
        filterData.append("filter",JSON.stringify(filter));
        this.getFilterContent ? this.getFilterContent.unsubscribe() : null;

        if(FilterContent){
          this.getContratsIndexes = this._http.post(this._env.BZ_API_BASE_URL + 'nxcontrats/getcontratsindexes',filterData,{withCredentials:true}).pipe(debounceTime(10),share()).subscribe(
            response => {
              //console.log(response)
              if(!response[0].msgerreur){
                //console.log(JSON.parse(response[0][""]));
                this.contratsIndexes.next(JSON.parse(response[0][""]));
                this.getContratsIndexes.unsubscribe();
              } else {
                this.contratsIndexes.next([null,response[0].msgerreur]);
                this.getContratsIndexes.unsubscribe();
              }
              
            }, 
            error => {
              this.getContratsIndexes.unsubscribe();
            }
            )
        }
      }
    )
  }

  getMesContratsIndexés(){
    return this.contratsIndexes.asObservable();
  }

  SetMesContratsAsigner(){
      // const filter = this._filtServ.filterValue.getValue();
      // const filterData = new FormData();     
      //  //console.log(filter);
      // filterData.append("filter",JSON.stringify(filter));
      return this._http.post(this._env.BZ_API_BASE_URL + 'administration/contratsasigner/format/json',"",{withCredentials:true})
  }

  getMesContratsAsigner(){
    return this.contratsAsigner.asObservable();
  }

  blockContrat(formValues:NgForm){
    //console.log(formValues);
    return this._http.post(this._env.BZ_API_BASE_URL + 'transaction/validateblock', formValues,{withCredentials:true});
  }

  signContrat(formValues:NgForm){
    return this._http.post(this._env.BZ_API_BASE_URL + 'administration/ctvalidation/format/json', formValues,{withCredentials:true,responseType: 'blob' as 'json'});
  }

  signContratElectronique(formValues:NgForm){
    var form = new FormData()
    form.append("params", JSON.stringify(formValues))
    return this._http.post(this._env.BZ_API_BASE_URL + 'administration/ctvalidationelectronique/format/json', form,{withCredentials:true});
  }

  
}
