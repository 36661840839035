import { Component, OnInit,ViewChild } from '@angular/core';
import { Subscription } from 'rxjs';
import { MonGesService } from 'src/app/services/mon-ges.service';
import { UserService } from 'src/app/services/user.service';
import { NgModule } from '@angular/core';
import { FormsModule, FormGroup, NgForm, FormControl, Validators, ReactiveFormsModule } from '@angular/forms';
import { Router, NavigationEnd } from '@angular/router';
import { FilterService } from 'src/app/services/filter.service';
import { ToastrService } from 'ngx-toastr';


@Component({
  selector: 'app-questionnaire',
  templateUrl: './questionnaire.component.html'
})
export class QuestionnaireComponent implements OnInit {

filterName:string;
theme;
numeroTheme: number;
themeSelection: string;
travailleDuSolInputVisible;

  user;
  filePac;

  //déclaration des variables local
  gesListQuestion;
  getMaListQuestion: Subscription;
  getMaListTheme: Subscription;
  getUser: Subscription;  
  filterListener:Subscription;
  gesListMatiereOrganique = []; 
  paramTiers
  
  constructor(private _filterServ: FilterService,private _gesServ: MonGesService,private _userServ: UserService, private _rooter : Router, private _toaster: ToastrService) {
    this.filterListener = this._filterServ.filterChangeEmitter.subscribe(
      newFilter =>  {
        //console.log("listener mon-ges-questionnaire");
        this.ngOnInit();
    }
    );
   }

ngOnDestroy(){
    this.getMaListQuestion ? this.getMaListQuestion.unsubscribe():null;
    this.getMaListTheme ? this.getMaListTheme.unsubscribe():null;
    this.gesListMatiereOrganique = [];
  }

  ngOnInit() {
    this.user = this._userServ.getUserConnected();
    this._gesServ.SetThemeGes();    
    this.getMaListTheme = this._gesServ.getThemeGes().subscribe(
        tiersTheme => {
          //console.log(tiersTheme.length);
          this.theme = tiersTheme;     
          this.selectTheme(0);
        },error => {
          this.getMaListTheme.unsubscribe();
        }
      )

    this._gesServ.SetGesQuestion();
    this.getMaListQuestion = this._gesServ.getGesQuestion().subscribe(
        tiersQuestion => {
          this.gesListQuestion = tiersQuestion; 
          var subscibeparam
          subscibeparam = this._gesServ.getStaticsData().subscribe(param =>{
           
            if(param){
              this.paramTiers = param
              console.log(this.paramTiers)
              for (const ques of this.gesListQuestion) {
                for(let i = 0; i < ques.Tbl_LabelisationQuestionnaire.length; i++){
                  let str = ques.Tbl_LabelisationQuestionnaire[i].question_TblLabeQues;
                  str = str.replace('([####])', '(20'+ (param.Campagne.camp_ResCa - 1)+')');
                  ques.Tbl_LabelisationQuestionnaire[i].question_TblLabeQues = str;
                }
              }

            }
            console.log(this.gesListQuestion);
          })  

          this.toFormGroup();  
          
        },error => {
          this.getMaListQuestion.unsubscribe();
        }
      )    
  
  }

  selectTheme(num){
    this.numeroTheme = num;
    this.themeSelection = this.theme[num].thematique_ResLabeQues;
  }

  toFormGroup(){
    //ajoute une form a l'objet
    for (const theme of this.gesListQuestion){
      theme.form = this.questionToControl(theme.Tbl_LabelisationQuestionnaire, theme)
    }
    console.log(this.gesListQuestion);
  }

  questionToControl(listeQuestion, theme){
    var group: any = {};

    var subscibeparam
    subscibeparam = this._gesServ.getStaticsData().subscribe(param =>{
     
      if(param){

        let isModifiable = !param.Tiers.attente_signature_TblLabeTiers
        
        for (let q = 0; q < listeQuestion.length; q++){
          const question = listeQuestion[q]
          question.reponses_visible = false
          question.collapse_reponse = false 
          var rep: any = {};

          //pour chaque réponse ajoute un control a la form
          for(let i = 0; i < question.Tbl_LabelisationQuestionnaireReponses.length; i++){
            isModifiable = question.Tbl_LabelisationQuestionnaireReponses[i].date_validation_AssLabeQuesTiers

            const reponse = question.Tbl_LabelisationQuestionnaireReponses[i];
            if(question.Tbl_LabelisationQuestionnaireReponses.length > 1){
              for(let r = 0; r < question.Tbl_LabelisationQuestionnaireReponses.length; r++){
                
                  if(question.Tbl_LabelisationQuestionnaireReponses[r].date_validation_AssLabeQuesTiers == false){
                    isModifiable = false;
                    break;
                  }else{
                    isModifiable = true;
                  }
              }
              
            }
            if(theme.ordre_ResLabeQues === 1){
              if (question.ordre_affichage_TblLabeQues === 1) {
                rep[reponse.id_TblLabeQuesRepo] = new FormControl({value: param.Tiers.code_tiers, disabled : true})  
              }else{
                if (question.ordre_affichage_TblLabeQues === 2) {
                  rep[reponse.id_TblLabeQuesRepo] = new FormControl({value: this._userServ.getUserConnected().userName, disabled : true})
                }
              }
              question.reponses_visible = true;
            }else{
              if (reponse.type_html_TblLabeQuesRepo == 'ilot') {
                question.collapse_reponse = true
                if(reponse.Ilots){
                  var somme_ilot  = 0;
                  for(const ilot of reponse.Ilots){
                    //si j'ai un resultat GES alors le questionnaire n'est plus modifiable
                    somme_ilot = somme_ilot + ilot.saisie_AssLabeQuesTiers
                    rep[ilot.id_AssLabeQuesTiers] = new FormControl({value : ilot.saisie_AssLabeQuesTiers, disabled : !isModifiable});
                    rep[ilot.id_AssLabeQuesTiers].valueChanges.subscribe(change=>{
                      if(!change || change ==''){
                        change = 0
                      }

                      ilot.saisie_AssLabeQuesTiers = change;
                      
                      ilot.moyenne_ponderee_ilot = ilot.saisie_AssLabeQuesTiers//(ilot.saisie_AssLabeQuesTiers * (ilot.somme_ilot / 100)) / reponse.Ilots.length;

                      //parcours la moyenne des ilots pour calculée la pondérée
                      reponse.moyenne_ponderee = 0;
                      for(const m of reponse.Ilots){
                        reponse.moyenne_ponderee += m.moyenne_ponderee_ilot;
                      }
                      reponse.moyenne_ponderee = reponse.moyenne_ponderee / reponse.Ilots.length;
                      
                      // //console.log(reponse);
                    })  
                   
                  }

                  if(somme_ilot == 0){
                    //console.log(question.id_TblLabeQues)
                  }else{
                    question.reponses_visible = true
                  }
                }               
                
              }else{

                //si j'ai un resultat GES alors le questionnaire n'est plus modifiable
                question.reponses_visible = true;
                rep[reponse.id_TblLabeQuesRepo] = new FormControl({value : reponse.reponse_temp, disabled : !isModifiable});
                rep[reponse.id_TblLabeQuesRepo].valueChanges.subscribe(change=>{
                  for (const r of question.Tbl_LabelisationQuestionnaireReponses){
                    //partie radio button
                    if (r.id_TblLabeQuesRepo != reponse.id_TblLabeQuesRepo) {
                      r.reponse_temp = ''
                    }else{ 'partie reponse 5'
                    console.log(change)
                      r.reponse_temp = change;  
                      if(question.id_TblLabeQues == 5){
                        if(!change || change == ''){
                          change = 0;
                        }

                        var somme_ilot = 0
                        for(let j = 0; j < listeQuestion[q-1].Tbl_LabelisationQuestionnaireReponses[0].Ilots.length; j++){
                          somme_ilot += parseFloat(listeQuestion[q-1].Tbl_LabelisationQuestionnaireReponses[0].Ilots[j].saisie_AssLabeQuesTiers);
                        }
                        if (somme_ilot > 0){
                          r.rendement_ilot = change*1000 / somme_ilot;                          
                        }else{
                          r.rendement_ilot = 0;
                        }
                      }                      
                    }

                  }
                })

                //cas des matieres organiques
                if (reponse.type_html_TblLabeQuesRepo == 'combo' && reponse.id_TblLabeQues == 10){
                    if(reponse.Matiere_organique && this.gesListMatiereOrganique.length == 0){
                      for(const orga of reponse.Matiere_organique){
                          if(orga.quantite_AssMatiOrgaLabeTier != 0){
                            this.gesListMatiereOrganique.push(orga);
                          }                       
                      }

                    }
                  }


              }
              
            }
            
          }

          group[question.id_TblLabeQues] = new FormGroup(rep)
        
        }
      }
      subscibeparam ? subscibeparam.unsubscribe():null;       
    })
      
    //pour chaque question ajout une form
    group['thematique'] = new FormControl(theme.id_ResLabeQues);
    return new FormGroup(group);
  }

  ActiveQuestion(question){
    question.reponses_visible = true
  }
  DesactiveQuestion(question){
    question.reponses_visible = false
  }

testbtn(){
  //console.log(this.gesListQuestion[0].form.value)
}

  register(form) {
    let valeur_matiere;
    if(this.numeroTheme == 3){
       valeur_matiere = (<HTMLInputElement>document.getElementById("valeur_matiere")).value;
    }else{
       valeur_matiere = "0";
    }
    if(valeur_matiere == "0"){
    var questionnaireFini = false
    if(this.numeroTheme == (this.theme.length - 1)){
      questionnaireFini = true
    }
    if(form.value.thematique != 1){
      let theme_save;
    
      for (const theme of this.gesListQuestion){
        //console.log(theme)
        //console.log(form.value)
        //sélectionne les questions a sauvegarder
        if(theme.id_ResLabeQues == form.value.thematique){
          theme_save = theme 
        }      
      }
      //console.log(theme_save)
      if(theme_save){
        this._gesServ.saveQuestionnaire(theme_save, questionnaireFini);

        if (questionnaireFini){
/* 
          this._gesServ.calculges().subscribe(calcule=>{
            console.log(calcule)
            if(calcule['Code_Retour'] == "Erreur"){//s'il y a une erreur peutetre contacter le support ? (nous mdr)
              this._toaster.error(calcule['Message'])
            }else{
              //ici on récupère le resultat, s'il esdt favorable alors on passe a la suite, sinon on informe l'utilsateur qu'il n'est pas éligible et il
              //ne peut pas accéder à la page document
              this._toaster.success(calcule['Message'])
              if(calcule['Resultat'].Tbl_LabelisationScoreGesReel.score_TblLabeScorGesReel == 0){
                //si le resultat n'est pas button
                this._rooter.navigate(['/mes-labelisations/mon-ges']);
              }else{
                this._rooter.navigate(['/mes-labelisations/mon-ges/documentaire']);
              }
            }
          }) */
            this._rooter.navigate(['/mes-labelisations/mon-ges/documentaire']);
        }  
      }
    }
    if(!questionnaireFini){
      this.selectTheme(this.numeroTheme +1)
      window.scrollTo(0, 0);
    }
  }else{
     this._toaster.info("Un ajout de matière n'a pas été enregistré !")
  }
  }



subscribeAddMatiere : Subscription
ajoutMatiere(){  
  
  let id_ResMatiOrga = (<HTMLInputElement>document.getElementById("select_matiere")).value;
  let valeur_matiere = (<HTMLInputElement>document.getElementById("valeur_matiere")).value;
  console.log(valeur_matiere);
  if(valeur_matiere != "0"){
    let itemExiste = false;
    //vérification que élement n'existe pas dans la liste
    for (let q = 0; q < this.gesListMatiereOrganique.length; q++){
      if(this.gesListMatiereOrganique[q].id_ResMatiOrga == id_ResMatiOrga){
        //console.log('existe déjà');
        itemExiste = true;
        this._toaster.error("La matière organique existe déjà !\n Merci de la supprimer.")
        
        break;
      }
    }

    if(itemExiste == false){
      this._gesServ.addMatiere(id_ResMatiOrga,valeur_matiere);
      this.subscribeAddMatiere ? this.subscribeAddMatiere.unsubscribe():null;

      this.subscribeAddMatiere = this._gesServ.getMatiere().subscribe(
          orgas => {
            const resultat = JSON.parse(orgas[0]);
            this.gesListMatiereOrganique.push(resultat.Matiere_organique[0]);
          },error => {
            this.subscribeAddMatiere.unsubscribe();
          }
        )
      this.filterName = '';
      console.log((<HTMLInputElement>document.getElementById("valeur_matiere")).value);
        this._toaster.success("La matière organique est ajouté avec succès.")      
    }

  }else{
     this._toaster.info("Ajout de matière impossible ayant un tonnage à 0 !")
  }



}



DeleteMatiere(id_AssMatiOrgaLabeTier){
  this._gesServ.deleteMatiere(id_AssMatiOrgaLabeTier);

    for (let q = 0; q < this.gesListMatiereOrganique.length; q++){
      if(this.gesListMatiereOrganique[q].Ass_MatiereOrganiqueLabelisationTiers[0].id_AssMatiOrgaLabeTier == id_AssMatiOrgaLabeTier){
        this.gesListMatiereOrganique.splice(q, 1);
        break;
      }
    }
    this._toaster.success("Suppression de la matière organique avec succès");

/*  let index = this.gesListMatiereOrganique.findIndex(d => d.Ass_MatiereOrganiqueLabelisationTiers[0].id_AssMatiOrgaLabeTier === id_AssMatiOrgaLabeTier);
  this.gesListMatiereOrganique.splice(index, 1);//remove element from array*/
  
}

  pagePrecedente() {
    this.selectTheme(this.numeroTheme -1)
  }

  loadPacFile(){
    
          var getPac = this._gesServ.getPacFile().subscribe(
          fp => {
            if (fp) {
              //console.log(fp);
              this.filePac = fp;
            }
          }, error =>{
            getPac ? getPac.unsubscribe() : null;
          }
        )
  }

}