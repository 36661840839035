import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { Subscription } from 'rxjs';
import { DashboardService } from 'src/app/services/dashboard.service';
import { FilterService } from 'src/app/services/filter.service';
import { MaCommercialisationService } from 'src/app/services/ma-commercialisation.service';

@Component({
  selector: 'app-ma-commercialisation',
  templateUrl: './ma-commercialisation.component.html'
})
export class MaCommercialisationComponent implements OnInit {

  commercialisation;
  getMaCommerce: Subscription;
  getComFilt:Subscription;
  getCamp:Subscription;

  listenerSub:Subscription;
  viewportWidth;
  count = 0;

  listenToFilter: Subscription;
  noComToShowMessage;
  selectedCamp;
  
  constructor(private _comServ: MaCommercialisationService, private _filtServ: FilterService,private _loc: Location) { 
    if(this._loc.path() == '/ma-commercialisation'){
      this.listenerSub = this._filtServ.filterChangeEmitter.subscribe(newFilter => {
        //console.log("listener commercialisation");
        this.ngOnInit();
      })
    }
  }

  ngOnInit() {
    window.scrollTo(0, 0)
    // //console.log(this.commercialisation)
    this.getCamp = this._filtServ.selectedCamp.subscribe(
      year => {
        //console.log(year)
        this.selectedCamp  = year;
      }
      )
      this._comServ.setCommercialisation();
      this.getMaCommerce = this._comServ.getCommercialisation().subscribe(
        commerce => {
          //console.log(commerce);
          if(commerce[0] == null && commerce[1] == "pas de données"){
            //console.log("pas de données CASE !")
            this.noComToShowMessage = commerce[1];
            this.commercialisation = null;
            this.getMaCommerce.unsubscribe();
          } else {
            this.commercialisation = commerce;
            this.getMaCommerce.unsubscribe();
          }
          
        },error => {
          this.getMaCommerce.unsubscribe();
        }
      )
  }

  ngDoCheck(){
    this.viewportWidth = window.innerWidth;
  }

  ngOnDestroy(){
    this.listenerSub ? this.listenerSub.unsubscribe(): null;
    this.getMaCommerce ? this.getMaCommerce.unsubscribe() : null;
    this.getCamp ? this.getCamp.unsubscribe():null;
    // this.listenToFilter.unsubscribe();
  }


}
