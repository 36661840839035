import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "culturePipe"
})
export class cultureByIdPipe implements PipeTransform {
    transform(value: string): any {
        let result = null;
        const val = value.substr(2,3).toString();
        // //console.log(value);
        // //console.log(val);
        switch (val) {
            case "00": 
                result = "BLE";
                break;
            case "BLE": 
                result = "00";
                break;
            case "05": 
                result = "BLE DUR";
                break;
            case "BLE DUR": 
                result = "05";
                break;
            case "10": 
                result = "ORGE";
                break;
            case "ORGE": 
                result = "10";
                break;
            case "20": 
                result = "MAIS";
                break;
            case "MAIS": 
                result = "20";
                break;
            case "40": 
                result = "AVOINE";
                break;
            case "AVOINE": 
                result = "40";
                break;
            case "80": 
                result = "COLZA";
                break;
            case "COLZA": 
                result = "80";
                break;
            case "90": 
                result = "POIS";
                break;
            case "POIS": 
                result = "90";
                break;
            case "94": 
                result = "POIS CHICHE";
                break;
            case "POIS CHICHE": 
                result = "94";
                break;
            case "95": 
                result = "FEVEROLE";
                break;
            case "FEVEROLE": 
                result = "95";
                break;
        
            default:
                break;
        }
        // //console.log(result);
        return result;
    }
}