import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { ModalDirective } from 'angular-bootstrap-md';

@Component({
  selector: 'app-not-found',
  templateUrl: './not-found.component.html',
  styleUrls: ['./not-found.component.scss']
})
export class NotFoundComponent implements OnInit {
  
  @ViewChild('errorModal',null) modal: ModalDirective;


  constructor(private _router: Router) { }

  ngOnInit(){

  }

  ngAfterViewInit() {
    //console.log(this.modal)
    this.modal.show();
    setTimeout(() => {
      this._router.navigate(['accueil']);
    }, 4000);
  }

}
