import { Component, OnInit,Pipe, PipeTransform } from '@angular/core';
import { Location } from '@angular/common';
import {FormGroup, FormControl, ValidationErrors, AbstractControl, Validators, ValidatorFn} from '@angular/forms';
import { Subscription, BehaviorSubject, interval } from 'rxjs';
import { DashboardService } from 'src/app/services/dashboard.service';
import { FilterService } from 'src/app/services/filter.service';
import { ErrorService } from 'src/app/services/error.service';
import { MonGesService } from 'src/app/services/mon-ges.service';
import { LoaderService } from 'src/app/services/loader.service';
import { Router, NavigationEnd } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { debounceTime } from 'rxjs/operators';
import { DomSanitizer } from '@angular/platform-browser';
import * as pdfMake from "pdfmake/build/pdfmake";
import * as pdfFonts from 'pdfmake/build/vfs_fonts';


(<any>pdfMake).vfs = pdfFonts.pdfMake.vfs;


@Component({
  selector: 'app-mes-documents-label',
  templateUrl: './mes-documents-label.component.html'
})


export class MesDocumentsLabelComponent implements OnInit {

fileName = '';
file_name ='';
listeDocument;
fileUp;
controle;
id_ResDoTy;
fileList;
interval : Subscription
nouveauDocAsigne = false

creationAutoDeclaration;
enAttenteSignature = false
urlAccesSignature;

reportCampagneVisible = false;
reportCampagneNbr = 0;
private dlDocumentSub: Subscription;
paramList;

	constructor(private _filterServ: FilterService,private _gesServ: MonGesService, private _toaster: ToastrService, private _rooter : Router, private _sanitizer : DomSanitizer) {
		
    this.initialisePage();
	}


  reportCampagne(id_ResDoTy){
    if((<HTMLInputElement>document.getElementById("reportCamp_"+id_ResDoTy)).checked == true){
      this.reportCampagneVisible = true;
    }else{
      this.reportCampagneVisible = false;
    }
  }


  ngOnInit(){

    this.remplirListeDoc();
    this.remplirListeParam();
  }

  initialisePage(){
    window.scrollTo(0, 0);
    //this._gesServ.setStaticsData();
    this.remplirListeDoc();
    
  }

  change_contractualisation(){
    if (!this.paramList.Tiers.attente_signature_TblLabeTiers){
      this.paramList.Tiers.collecte_100_pourcent_TblLabeTiers = !this.paramList.Tiers.collecte_100_pourcent_TblLabeTiers
      this._gesServ.change100PourcentCollecte(this.paramList)
    }
    
  }

  remplirListeParam(){
    var sub_param = this._gesServ.getStaticsData().subscribe(
        param =>{
          if (param){
            console.log(param)
            this.paramList = param
            
          }
          
        }
      )

  }

  subscribeListeDoc : Subscription
  remplirListeDoc(){
    this._gesServ.getListeDocuments();

    
    this.subscribeListeDoc ? this.subscribeListeDoc.unsubscribe():null;
    this.subscribeListeDoc=this._gesServ.listeDocument.subscribe(
      doc =>{
        //console.log(doc);
          if(doc){
              this.listeDocument = doc;
              this.changeStatusReportCampagne();
              //console.log(this.listeDocument);
          }        
        }
      )
  }


  changeStatusReportCampagne(){
    //active le report de campange si il y a 1 document
    for(const report of this.listeDocument){
      if(report.Document.id_ResDoTy == 2873 && report.Document.Listes.length > 0){
        this.reportCampagneVisible = true;
        this.reportCampagneNbr = report.Document.Listes.length;
      }else if(report.Document.id_ResDoTy == 2873 && report.Document.Listes.length == 0){
        this.reportCampagneVisible = false;
        this.reportCampagneNbr = report.Document.Listes.length;
      }
    }
  }

  retourDocument(){
    this.creationAutoDeclaration = false;
  }

  subscribeDocDelete : Subscription
	Deletefile(id_AssDoLabeTiers){
		this._gesServ.deleteFile(id_AssDoLabeTiers);
    this.subscribeDocDelete ? this.subscribeDocDelete.unsubscribe():null;
    this.subscribeDocDelete= this._gesServ.listeDocument.subscribe(doc =>{

          this.listeDocument = doc;
          //console.log(this.listeDocument);
        }
      )
	}

  ngOnDestroy(){
    this.interval ? this.interval.unsubscribe():null;
    this.subscribeListeDoc ? this.subscribeListeDoc.unsubscribe():null;
    this.subscribeDocDelete ? this.subscribeDocDelete.unsubscribe():null;
  }

  openFile(id_ResDoTy){
  	this.id_ResDoTy = id_ResDoTy;
    //si c'est une auto déclaration
    if(id_ResDoTy == 2805){
      this.creationAutoDeclaration= true;
      this.nouveauDocAsigne = false;
    }else{
      let element: HTMLElement =  document.querySelector("input[name='"+this.id_ResDoTy+"']");
      element.click();  
    }
  	
  }


subscribeFilePac : Subscription;
subscribeDocUpload : Subscription;
file_data:any=null
fileChange(index,event) {

    this.fileList = event.target.files;
    //check whether file is selected or not
    if (this.fileList.length > 0) {

        const file = this.fileList[0];
        //get file information such as name, size and type
        //console.log('finfo',file.name,file.size,file.type);
        this.file_name = file.name;
        //max file size is 4 mb
/*        if((file.size/1048576)<=4)
        {*/
          let formData = new FormData();
          let info={id:2,name:'raja'}
          formData.append('file', file, file.name);
          formData.append('id','2');
          formData.append('tz',new Date().toISOString())
          formData.append('update','2')
          formData.append('info',JSON.stringify(info))
          formData.append('id_ResDoTy',this.id_ResDoTy)
          this.file_data=formData

	      this._gesServ.fileUpload(this.file_data);

        this.subscribeFilePac ? this.subscribeFilePac.unsubscribe() : null;
	      this.subscribeFilePac = this._gesServ.getPacFile().subscribe(
	          fp => {
	            //console.log(fp);
	            if (fp) {
	              //console.log(fp['controle']);
	              //verification de la structure et ou de la campagne
	              if (fp['controle'] != "") {
	                this.controle = fp['controle'];
	              }else{
	                this.fileUp = fp;
	              }
	              
	            }
	          }, error =>{
	            this.subscribeFilePac ? this.subscribeFilePac.unsubscribe() : null;
	          }
	        )


  /*      }else{
          //this.snackBar.open('File size exceeds 4 MB. Please choose less than 4 MB','',{duration: 2000});
        }*/
        this.listeDocument = null
        this.subscribeDocUpload ? this.subscribeDocUpload.unsubscribe() : null;
        this.subscribeDocUpload = this._gesServ.listeDocument.subscribe(doc =>{

          this.listeDocument = doc;
          this.changeStatusReportCampagne();
          ////console.log(this.listeDocument);
        }
      )
        
    }

  }

  changeCB()  {
    if(this.durabiliteForm.value.pourcent_100_TblLabeAutoDecl == '1'){
      this.durabiliteForm.setControl('surface_totale_TblLabeAutoDecl', new FormControl(''));
      this.durabiliteForm.setControl('surface_non_labourables_TblLabeAutoDecl', new FormControl(''));
      this.durabiliteForm.setControl('poucentage_non_durable_TblLabeAutoDecl', new FormControl(''));
    }
  }

  private  validationDuraForm: ValidatorFn = (control: AbstractControl):
  ValidationErrors | null =>{
    const pourcent_100 = control.get('pourcent_100_TblLabeAutoDecl').value;
    const surface_totale_TblLabeAutoDecl = control.get('surface_totale_TblLabeAutoDecl').value;
    const surface_non_labourables_TblLabeAutoDecl = control.get('surface_non_labourables_TblLabeAutoDecl').value;
    const poucentage_non_durable_TblLabeAutoDecl = control.get('poucentage_non_durable_TblLabeAutoDecl').value;
    const fait_a_TblLabeAutoDecl = control.get('fait_a_TblLabeAutoDecl').value;
    return fait_a_TblLabeAutoDecl && (pourcent_100 == '1' || (surface_totale_TblLabeAutoDecl && surface_non_labourables_TblLabeAutoDecl && poucentage_non_durable_TblLabeAutoDecl )) ? null : {nonValid : true};
  };

  public durabiliteForm = new FormGroup({
    pourcent_100_TblLabeAutoDecl : new FormControl(null),
    surface_totale_TblLabeAutoDecl : new FormControl(''),
    surface_non_labourables_TblLabeAutoDecl : new FormControl(''),
    poucentage_non_durable_TblLabeAutoDecl : new FormControl(''),
    fait_a_TblLabeAutoDecl : new FormControl('')
  }, {validators : this.validationDuraForm})


  subscribeParam : Subscription;
  subscribePac : Subscription;
  subscribeProcedureYouSign : Subscription;


  visualiserPdf(url){
    var splittedStringArray = url.split("/");
    //window.open('http://nx-bz-api.e-bz.local/upload/'+url); 
    this.dlDocumentSub = this._gesServ.downloadDocument(url).subscribe(
      response => {
        //console.log(response)
         // if(response['type'] == "application/download"){
            let dataType = response['type'];
            let binaryData = [];
            binaryData.push(response);
            let downloadLink = document.createElement('a');
            //console.log(downloadLink)
            downloadLink.href = window.URL.createObjectURL(new Blob(binaryData, {type: dataType}));
            downloadLink.setAttribute('download',splittedStringArray[splittedStringArray.length -1]);

            document.body.appendChild(downloadLink);
            downloadLink.click();
         // } else {
           // this._toaster.error("Aucun document trouvé! Echec du téléchargement")
          //}

        // this.mvmntFilename.next(fileName);
        this.dlDocumentSub.unsubscribe();
      },
      error => {
        this.dlDocumentSub.unsubscribe();
      } 
    )    
  }



  

}




