import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { ActivatedRoute } from '@angular/router';
import { ButtonService } from 'src/app/services/button.service';
import { MaCommercialisationService } from 'src/app/services/ma-commercialisation.service';
import { Subscription } from 'rxjs';
import { CompileStylesheetMetadata } from '@angular/compiler';
import { FilterService } from 'src/app/services/filter.service';
import { ToolsService } from 'src/app/services/tools.service';

@Component({
  selector: 'app-custom-button',
  templateUrl: './custom-button.component.html',
  styleUrls: ['./custom-button.component.scss']
})
export class CustomButtonComponent implements OnInit {

  btnNames:string[];
  btnState;
  pageUrl:string;
  param;
  spaceParam;

  getCommercialisation: Subscription;
  constructor(
    private _loc: Location,
    private route: ActivatedRoute, 
    private _btnServ: ButtonService,
    private _maComServ: MaCommercialisationService,
    private _filtServ: FilterService,
    private _tools: ToolsService) {
      this.pageUrl = this._loc.path();
    this.param = this.route.snapshot.paramMap.get("produit") 
    if(this.param != null){
      this.spaceParam = this._tools.helperUrlProduct(this.param)
    }
    if(this.pageUrl == "/mes-stocks/detail/"+this.spaceParam){
      this.btnNames = ["DÉPÔT SILOS BZ"];
      this.btnState = this._btnServ.setBtnState("DÉPÔT SILOS BZ");
      this.display("DÉPÔT SILOS BZ")
      // console.log(this.btnState)
    }

    if(this.pageUrl == "/ma-commercialisation/detail/" + this.spaceParam){
      // console.log("param dans le service button =>", param)
      // this._maComServ.setCommercialisation();
      this.getCommercialisation = this._maComServ.getCommercialisation().subscribe(
        macommerce =>{
          console.log(macommerce);
          const coms = macommerce.MaCommercialisation;
          let names = [];
          macommerce.map(
            x=>{
              if(x.culture == this.param){
                const detail = x;

                detail.typecontrat.map(
                  (x,index)=>{
                    if(index == 0){
                      this._btnServ.setBtnState(x['typecontrat.libelle']);
                      this.btnState = this._btnServ.getBtnState();
                    }
                    names.push(x['typecontrat.libelle']);
                    this.btnNames = names;
                  }
                )
              }
            }
          )
          console.log(macommerce);

        }, error => {
          console.log(error);
        }
      )

      
    }

    if(this.pageUrl == "/mes-reglements/detail/"+this.spaceParam){
      this.btnNames = ["À VENIR","EFFECTUÉS"];
      this._btnServ.setBtnState("À VENIR");
      this.btnState = this._btnServ.getBtnState();
      this.display("À VENIR")
      console.log(this.btnState)
    }

    if(this.pageUrl == "/infos-de-marche"){
      this.btnNames = ["NOTE DE CONJONCTURE BZ"];
      this._btnServ.setBtnState("À VENIR");
      this.btnState = this._btnServ.getBtnState();
      this.display("À VENIR")
      console.log(this.btnState)
    }

    if(this.pageUrl == "/ma-synthese-commerciale"){
      this.btnNames = ["GRAPHES","TABLEAU"];
      this._btnServ.setBtnState("GRAPHES");
      this.btnState = this._btnServ.getBtnState();
      this.display("GRAPHES")
      console.log(this.btnState)
    }

    if(this.pageUrl == "/mes-livraisons-et-enlevements/detail/"+this.spaceParam){
      this.btnNames = ["À VENIR","EFFECTUÉES"];
      this._btnServ.setBtnState("À VENIR");
      this.btnState = this._btnServ.getBtnState();
      this.display("À VENIR")
      console.log(this.btnState)
    }
    }

  ngOnInit() {
    
  }


  display(state){
    this._btnServ.setBtnState(state);
    this.btnState = this._btnServ.getBtnState();
    // console.log(this.btnState)
  }

  getTypeContratCom(type){
      console.log(type)
      this._maComServ.typeContratComSelected.next(type);
  }

}
