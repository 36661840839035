import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Subject, Subscription, Observable, BehaviorSubject, forkJoin, UnsubscriptionError } from 'rxjs';
import { FilterService } from './filter.service';
import { share, refCount,map, flatMap, takeWhile, takeLast, debounce, take, debounceTime } from 'rxjs/operators';


@Injectable({
  providedIn: 'root'
})
export class MaCommercialisationService {
  private _env = environment;
  private maCommerce = new Subject<any>();
  private filterValue = new BehaviorSubject<any>(null);
  public typeContratComSelected = new BehaviorSubject<any>(null);
  getFilter:Subscription;
  getCommerce:Subscription;

  constructor(private _http: HttpClient,private _filtServ: FilterService) { }

  // getBookWithAuthor(id: number): Observable<any> {
  //   return this.http.get('/api/books/' + id)
  //     .map((res: any) => res.json())
  //     .flatMap((book: any) => {
  //       return this.http.get('/api/authors/' + book.author_id)
  //         .map((res: any) => {
  //           let author = res.json();
  //           book.author = author;
  //           return book;
  //         });
  //     });
  // }



  setCommercialisation(){

      this.getFilter = this._filtServ.getFilter().pipe(debounceTime(1),share()).subscribe(
       filterContent => {
         const filterConf = this._filtServ.createFilterFromCheckedValues(filterContent);
         //console.log(filterConf)
         
          if(filterConf){
              const filterData = new FormData();
              filterData.append("filter",JSON.stringify(filterConf));
              //console.log(filterContent)
              this.getFilter ? this.getFilter.unsubscribe():null;
      
                this.getCommerce =  this._http.post(this._env.BZ_API_BASE_URL + "macommercialisation/nxgetcommercialisation/format/json",filterData,{withCredentials:true}).pipe(share()).subscribe(
                  commerce => {
                    //console.log(commerce);
                    if(!commerce[0].msgerreur){

                      const res = JSON.parse(commerce[0][""]);
                      const resCom = res.MaCommercialisation;
                      this.maCommerce.next(resCom);
                      
                      this.getCommerce.unsubscribe();
                        
                    } else {
                      //console.log("CASE")
                      this.maCommerce.next([null,commerce[0]["msgerreur"]]);
                      this.getCommerce.unsubscribe();
                      this.getFilter ? this.getFilter.unsubscribe(): null;

                    }
                    
                  }, error => {
                    this.getCommerce ? this.getCommerce.unsubscribe():null;
                    this.getFilter ? this.getFilter.unsubscribe():null;
                  }
                )
          } else {
            
            this.getFilter ? this.getFilter.unsubscribe():null;
            this.getCommerce ? this.getCommerce.unsubscribe():null;
          }
       }
      )
 }

 getCommercialisation(){
   return this.maCommerce.asObservable();
 }

}
