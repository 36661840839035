import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { NgForm } from "@angular/forms";
import { Router } from '@angular/router';
import { Location } from '@angular/common';

import { environment } from "../../environments/environment"; 
import { CookieService } from 'ngx-cookie-service';
import { Subscription, BehaviorSubject } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { LoaderService } from './loader.service';
import { ToastrStateService } from './toastr-state.service';



@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor(
    private _http: HttpClient, private _router: Router,
    private _loc: Location, private _cookieServ: CookieService,
    private _toaster: ToastrService, private _loader: LoaderService,
    private _crossToaster: ToastrStateService) { }
    
  serverMessage: string;
  loginState: boolean = false;
  private _env = environment; 

  public isLogged = new BehaviorSubject(false);

  decoSub: Subscription;
  expiredDate;


  authLogin(formData: NgForm){
    const data = JSON.stringify(formData);
    /*console.log(data);*/
    const headers = { 'Content-Type': 'application/x-www-form-urlencoded' };
    return this._http.post(this._env.BZ_API_BASE_URL + "login/login/format/json",data, {withCredentials: true});
  }

  isLoggedIn(){
    const user = this._cookieServ.get("userId");
    const userName = this._cookieServ.get('userName');
    const userLastName = this._cookieServ.get('userLastName');
    const userFirstName = this._cookieServ.get('userFirstName');
    const userTel = this._cookieServ.get('userTel');
    const usermail = this._cookieServ.get('usermail');
    const userProfile = this._cookieServ.get('userProfile');
    const sessionPhp = this._cookieServ.get("sessionId");

    console.log(sessionPhp);
      if(!sessionPhp || sessionPhp == ""){ // || sessionPhp == "undefined" mais ne fonctionne pas sur re7 car undefined 
        this.isLogged.next(false);
        return false;
      } else {
        this.isLogged.next(true);
        return true;
      }      
  }

  setLogin(bool:boolean,sessionId:string,id:string,username:string,userfirstname:string,userlastname:string, usertel: string,usermail:string,userProfile:string){

    this.expiredDate = new Date();
    this.expiredDate.setMinutes( (this.expiredDate.getMinutes() + 60)  );  //now + 1heure 

    this.loginState = bool;
    this._cookieServ.set( 'userId', id );
    this._cookieServ.set('userName', username);
    this._cookieServ.set('userFirstName', userfirstname);
    this._cookieServ.set('userLastName', userlastname);
    this._cookieServ.set('userTel', usertel);
    this._cookieServ.set('userMail', usermail);
    this._cookieServ.set('userProfile', userProfile);
    this._cookieServ.set('sessionId', sessionId,this.expiredDate);
    this.isLogged.next(true);
    
    this._router.navigate(["accueil"]);
  }

  //write this.cookieService.set( 'key',JSON.stringify('value'), this.expiredDate); //read console.log(JSON.parse(this.cookieService.get( 'key'));

  logOut(){
     if(!this._cookieServ.check('sessionId')){
       this._crossToaster.fillToasterSubj({type: "info", message:"Vous êtes déconnecté, retour au login"})
     }
      this.decoSub = this._http.get(this._env.BZ_API_BASE_URL + "deconnexion", {withCredentials: true}).subscribe(
        response => {
          console.log(response)
          if(response && !response['error']){
            /*console.log("success log Out => retour accueil");*/
            this._cookieServ.delete('userId');
            this._cookieServ.delete('userName');
            this._cookieServ.delete('userMail');
            this._cookieServ.delete('userName');
            this._cookieServ.delete('userFirstName');
            this._cookieServ.delete('userLastName');
            this._cookieServ.delete('userTel');
            this._cookieServ.delete('userProfile');
            this._cookieServ.delete('sessionId');
            document.cookie = "sessionId=; expires=Thu, 01-Jan-70 00:00:01 GMT; path=/; domain=.e-bzgrains.com;";
            document.cookie = "userId=; expires=Thu, 01-Jan-70 00:00:01 GMT; path=/; domain=.e-bzgrains.com;";
            document.cookie = "userMail=; expires=Thu, 01-Jan-70 00:00:01 GMT; path=/; domain=.e-bzgrains.com;";
            document.cookie = "userName=; expires=Thu, 01-Jan-70 00:00:01 GMT; path=/; domain=.e-bzgrains.com;";
            document.cookie = "userProfile=; expires=Thu, 01-Jan-70 00:00:01 GMT; path=/; domain=.e-bzgrains.com;";
            this.isLogged.next(false);
            this._router.navigate([''])              
            this.decoSub ? this.decoSub.unsubscribe() : null;
          } else {
            this.isLogged.next(true);
            this._router.navigate(['']);
            this._toaster.error(response['error'])
            this.decoSub ? this.decoSub.unsubscribe() : null;
          }
        },
        error => {
          console.log(error);
          // this.decoSub ? this.decoSub.unsubscribe() : null;
        }
      )

  }

  firstConnexion(formData: NgForm){
    const data = formData;
    return this._http.post(this._env.BZ_API_BASE_URL + "premiereconnexion/validation",data);
  }

  loginInterne(formData: NgForm){
    const data = JSON.stringify(formData);
    /*console.log(data);*/
    return this._http.get(this._env.BZ_API_BASE_URL + "login/logininterne?tonton=tata&toto=null");

    
  }
}
