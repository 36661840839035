import { Component, OnInit } from '@angular/core';
import { HostListener } from "@angular/core";
import {
  trigger,
  state,
  style,
  transition,
  animate
} from "@angular/animations";
import {Chart} from 'chart.js';
import { AuthService } from 'src/app/services/auth.service';
import { AclService } from 'src/app/services/acl.service';
import { Location } from '@angular/common';
import { ContratsService } from 'src/app/services/contrats.service';
import { Subscription } from 'rxjs';
import { UserService } from 'src/app/services/user.service';
import { Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { ToastrService } from 'ngx-toastr';
import { MessageService } from 'src/app/services/message.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  animations: [
    trigger("headerAnim", [
      state(
        "closed",
        style({ transform: "translateY(-100%)", visibility: "hidden" })
      ),
      state(
        "open",
        style({ transform: "translateY(0)", visibility: "visible" })
      ),
      transition("open => closed", animate("0.4s 20ms ease-out")),
      transition("closed => open", animate("0.4s 150ms ease-in"))
    ])
  ]
})

export class HeaderComponent implements OnInit {
  
  isOpen:string = "closed";
  screenHeight:number =  window.innerHeight;
  screenWidth:number =  window.innerWidth;
  pageUrl = this._loc.path();
  getContratsToSign:Subscription;
  contratToSignNumber:number;

  messagesNonLusNbr;

  userInfos;

  constructor(private _authServ: AuthService, private _acl: AclService, 
              private _loc: Location,public _contratServ:ContratsService,
              private _userServ: UserService,private _router: Router,
              private _cookieServ: CookieService, public _messageServ: MessageService,
              private _toaster: ToastrService) {
   }

  ngOnInit() { 
    this.screenHeight = window.innerHeight;
    this.screenWidth = window.innerWidth;

    this.userInfos = this._userServ.getUserConnected();
    if(this.screenWidth > 991){
      this.isOpen = "closed";
    }
    // this._acl.setAcl();

    if(!this._contratServ.contratsAsigner.getValue() ){
      this.getContratsToSign = this._contratServ.SetMesContratsAsigner().subscribe(
        (response:string) => {
          //console.log(response);
          if(response[0]['msgerreur']=="pas de contrat"){
            this._contratServ.contratsAsigner.next([null,response[0]['msgerreur']])
            this._contratServ.contratsNbr.next(0);
            this.contratToSignNumber = this._contratServ.contratsNbr.getValue();
          } else {
            const parsed = JSON.parse(response[0][""]);
            const asigner = parsed['MescontratsAsigner'];
            //console.log(asigner[0].nb);
            this._contratServ.contratsAsigner.next(asigner[0].contrats);
            this._contratServ.contratsNbr.next(asigner[0].nb);
            this.contratToSignNumber = this._contratServ.contratsNbr.getValue();
            //console.log(this._contratServ.contratsNbr.getValue())
          }
          this.getContratsToSign ? this.getContratsToSign.unsubscribe() : null;
          
          },
          error =>{
            this.getContratsToSign ? this.getContratsToSign.unsubscribe() : null;
          }
          )
    } else {
      this.contratToSignNumber = this._contratServ.contratsNbr.getValue();
      //console.log(this.contratToSignNumber)
    }

    if(!this._messageServ.messagesDests.getValue() || !this._messageServ.mesMessages.getValue() || !this._messageServ.messagesNonLusNbr.getValue()){
      this._messageServ.setAllMessages().subscribe(
        response => {
          //console.log(response)
          this._messageServ.messagesDests.next(response[1]);
          const raw = JSON.parse(response[2]);
          console.log(raw);
          var messages = ""
          if(raw.notifications.notification.message){
            messages = raw.notifications.notification.message.map(
              not => {
              if(not){
              not['id']= not['@attributes'].id;
                not['date']= not['@attributes'].date;
                not['label']= not['@attributes'].label;
                not['nom']= not['@attributes'].nom;
                not['pjointe']= not['@attributes'].pjointe;
                not['extract']= not.contenu.substring(0,150);
                not['type']= not['@attributes'].type;
                return not;
                }else{
                return null
                }
                
              }
            )
          }
          this._messageServ.mesMessages.next(messages); 
          // this._messageServ.messagesNonLusNbr.next()
          this._messageServ.messagesNonLusNbr.next(parseInt(raw['notifications']['@text']))
          this.messagesNonLusNbr = this._messageServ.messagesNonLusNbr.getValue();
          //console.log(this.messagesNonLusNbr)
        }
      )
    } else {
      this.messagesNonLusNbr = this._messageServ.messagesNonLusNbr.getValue();
      //console.log(this.messagesNonLusNbr)
    }

    

  }

  ngAfterViewChecked(){
    // this.contratToSignNumber = this._contratServ.contratsNbr.getValue();
  }

  controlMenu(){
      //console.log(this.isOpen)
      this.isOpen = this.isOpen == "closed" ? "open" : "closed";
  }
  
  closeMenu(){
    this.isOpen = "closed";
  }

  logOut(){
    this._authServ.logOut();
  }

  goHome(){
    // this.isOpen = "open";
    this._router.navigate(['accueil']);
  }

  ngDoCheck(){
    this.screenWidth = window.innerWidth;
    this.contratToSignNumber = this._contratServ.contratsNbr.getValue();
  }

  ngOnDestroy(){
    this.getContratsToSign ? this.getContratsToSign.unsubscribe() : null;
  }

}
