import {Chart} from 'chart.js';
import { ViewChild,Directive, Component, ElementRef, OnInit, Renderer2 } from '@angular/core';
import { TooltipDirective } from 'angular-bootstrap-md';
import { DashboardService } from 'src/app/services/dashboard.service';
import { Subscription } from 'rxjs';
import { FilterService } from 'src/app/services/filter.service';
import { MesLivraisonsService } from 'src/app/services/mes-livraisons.service';

@Component({
    selector: 'app-donut',
    template:`<h5 class="text-center m-0 mb-2 main-subtitle margin-top-small" style="min-height:20px;color:#878787">MES <span class="text-darkgreen" style="font-weight: 400">LIVRAISONS ET ENLÈVEMENTS</span></h5>
              <div class="p-4 m-0 mt-1" style="min-height:264px;" id="donut-container">
                <div *ngIf="mesLivraisons" class="next-enlev" style="grid-area: next;display:grid;align-content:center;justify-items:center">
                    <div class="text-center mb-2 mt-4" style="color:#878787">Prochains enlèvements</div>
                    <div class="text-center card-warning mt-2" style="line-height: 1em;font-weight:bolder;color:#C31D2B;">{{mesLivraisons.prochenl}}</div>
                </div>
                <div *ngIf="mesLivraisons" [routerLink]="'/mes-livraisons-et-enlevements'" class="badge badge-pill p-1 bt-hov" style="background-color:#83BA21;grid-area:btnR;height:20px"><span class="mx-3">VOIR PLUS</span></div>
                <div class="disclaimer pt-2" style="display:grid;grid-area: discl;grid-column: 1 / -1;justify-self:flex-start">                  
                  <span style="font-size:0.6em;color:#878787">*Du total contractualisé avec BZ Collecte</span>
                </div>
              </div>
              <div *ngIf="!mesLivraisons && noLivShowMessage" class="row d-flex justify-content-center align-items-center w-100">
                <div class="card justify-content-center align-items-center p-4 w-100" style="min-height:286px">
                    <p class="text-culture custom-danger">{{noLivShowMessage}}</p>
                </div>
              </div>`,
    styles: [
      ` @media screen and (max-width: 420px){
            #donut-container{
              display: grid;
              justify-content:center;
              align-content:center;
              justify-items:center;
              align-items:center;
              grid-template-columns:100%;
              grid-template-rows: 150px 117px 117px 60px 20px;
              grid-template-areas: "dnt"
                                    "infos" 
                                    "next" 
                                    "btnR " 
                                    "discl";
            }
        }

        @media screen and (min-width: 420px){
          #donut-container { 
            display: grid; 
            grid-template-columns:40% 30% 30%;
            grid-template-rows: 80% 15% 5%;
            grid-template-areas: "dnt infos next" 
                                  ". btnR ." 
                                  "discl . .";
            justify-content:center;
            align-content:center;
            justify-items:center;
          } 
        }
      `
    ]
})

export class DonutComponent implements OnInit {

  mesLivraisons;
  getLivraisons:Subscription;
  filtSubListener:Subscription;
  noLivShowMessage;
    constructor(
      private _elementRef : ElementRef,
      private _dashServ: DashboardService,
      private _filtServ: FilterService,
      private _livraisonServ: MesLivraisonsService) {
        this.filtSubListener = this._filtServ.filterChangeEmitter.subscribe(newFilter => this.ngOnInit())
      }

    ngOnInit() {
      this._livraisonServ.setLivraisons();
      this._livraisonServ.getLivraisons().subscribe(
        livraisons => {
          //console.log(livraisons)
          if(livraisons[0]){
            this.mesLivraisons = livraisons[0];
            //console.log(this.mesLivraisons);
  
            const shipped = this.mesLivraisons.qteeffectuee;
            const left = this.mesLivraisons.qteavenir;
            const shippedLabel = shipped + " T EFFECTUÉES";
            const leftLabel = left + " À VENIR"
  
            const el = this._elementRef.nativeElement.querySelector('#donut-container');
            el.style.display = 'grid';
            const infoEl = <HTMLElement>el.querySelector(".canva-infos")
            const formerCanvaEl = <HTMLElement>el.querySelector(".canvas-container");
            formerCanvaEl ? formerCanvaEl.remove():null;
            infoEl ? infoEl.remove():null;
            //console.log(el)
                el.classList.add('card');
    
                el.insertAdjacentHTML(
                    'afterbegin', 
                            '<div class="canvas-container" style="grid-area: dnt;width:300px;display:grid;align-content:center;justify-items:center;">'+
                              '<canvas style="background:transparent url(/assets/icons/dashboard-green-svg/03-livraisons.svg) no-repeat center center;background-size: 50% 50%;" class="canvas"></canvas>'+
                            '</div>'+
                            '<div class="canva-infos" style="display:grid;align-content:center;justify-items:flex-start">' +
                              '<div class="d-flex justify-content-center align-items-start mt-3" style="font-size:0.9em">'+
                                  '<i class="fas fa-square mr-2" style="color:#aacd69;font-size:1.2em"></i>'+
                                '<span class="text-legend" style="color:#878787"> '+ shipped +' T <br><span class="smaller-legend" style="position:relative;color:#878787">EFFECTUÉES<i class="fas fa-asterisk" style="font-size:0.3em;position:absolute;top:-2px;right:-10px;color:#878787"></i></span></span>'+
                              '</div>'+
                              '<div class="d-flex justify-content-center align-items-start" style="font-size:0.9em">'+
                                '<i class="fas fa-square mr-2" style="color:#83BA21;font-size:1.2em"></i>'+
                                '<span class="text-legend" style="color:#878787">'+ left +' T <br><span class="smaller-legend" style="position:relative;"> À VENIR<i class="fas fa-asterisk" style="font-size:0.3em;position:absolute;top:-2px;right:-10px;color:#878787"></i></span></span>'+
                              '</div>'+
                            '</div>'                                           
                );
                let canva = el.querySelector('.canvas');
                
                /* //console.log(canva) */
                
    
                let donutCtx = canva.getContext('2d');
                
    
                // let infosDivEls = el.querySelectorAll('.canva-infos');
                // let infoFirstEl =  infosDivEls[0];
                // let infoLastEl =  infosDivEls[infosDivEls.length- 1];
                // infoLastEl.insertAdjacentHTML(
                //   'beforeend',
                  
                // );
    
                /*  //console.log(infoLastEl) */
    
                var data = {
                      labels: [
                        shippedLabel,
                        leftLabel
                      ],
                      datasets: [
                          {
                              "data": [shipped, left], 
                              "backgroundColor": [
                                  "#AADB52",
                                  "#83B920"
                              ]
                          }
                        ]
                }; 
                
                var chart = new Chart(
                  donutCtx,
                  {
                      "type": 'doughnut',
                      "data": data,
                      "options": {
                          "cutoutPercentage": 65,
                          "animation": {
                              "animateRotate": true
                          },
                          tooltips: {
                            callbacks: {
                              label: function(tooltipItem, data) {
                                //console.log(tooltipItem);
                                //console.log(data);
                                // const value = tooltipItem.value;
                                return data.labels[tooltipItem.index];
                              },
                            }
                          },
                          "legend": { 
                            "display": false
                        },
                        "responsive":true,
                      }
                  }
                );
          } else {
            const el = this._elementRef.nativeElement.querySelector('#donut-container');
            el.style.display = 'none';
            this.noLivShowMessage = livraisons[1];
            //console.log(this.noLivShowMessage);
            this.mesLivraisons = null;
            this.getLivraisons ? this.getLivraisons.unsubscribe():null;
          }
        }
      )
    }

    ngOnDestroy(){
      this.filtSubListener ? this.filtSubListener.unsubscribe():null;
    }
}
