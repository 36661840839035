import { Component, OnInit } from '@angular/core';
import { FormGroup, Validators, FormControl } from '@angular/forms';
import { Subscription } from 'rxjs';
import { AuthService } from 'src/app/services/auth.service';
import { ToastrService } from 'ngx-toastr';
import { ToastrStateService } from 'src/app/services/toastr-state.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-connexion-interne',
  templateUrl: './connexion-interne.component.html',
  styleUrls: ['./connexion-interne.component.scss']
})
export class ConnexionInterneComponent implements OnInit {

  loginForm:FormGroup;
  isSubmit = false;
  
  logSub: Subscription;
  viewportWidth;


  constructor(private _authServ: AuthService,private _toasterState: ToastrStateService,private _router: Router) { }

  ngOnInit() {
    this.loginForm = new FormGroup({
      login: new FormControl("", {
        validators: [Validators.required,Validators.email]
      }),
      pwd: new FormControl("", {
        validators: [Validators.required]
      }),
    });
  }

  loginInterne(){
    if(!this.loginForm.valid && this.loginForm.touched){
      console.log(this.loginForm)
      return;

    } else {
      this._authServ.loginInterne(this.loginForm.value).subscribe(
        response => {
          console.log(response);
          if(response['message'] != "Vous êtes connecté"){
            console.log(response)
            this._toasterState.toasterState.next({type: "error", message: response['message']});
            this._toasterState.emitToasterEvent({type: "error", message: response['message']}) 

          } else {
            this._authServ.setLogin(true,response['sessionId'] ,response['userId'],response['userName'],response['userFirstName'],response['userLastName'], response['userTel'],response['userMail'],response['userProfile']);
            this._toasterState.toasterState.next({type: "success", message: response['message']});
            this._toasterState.emitToasterEvent({type: "success", message: response['message']});
            this._router.navigateByUrl('/accueil');

          }
        }
      )
    }
  }

  ngDoCheck(){
    this.viewportWidth = window.innerWidth;
  }

}
