import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment'
import { Subject, BehaviorSubject, Subscription } from 'rxjs';
import { FilterService } from './filter.service';
import { LoaderService } from './loader.service';

@Injectable({
  providedIn: 'root'
})
export class InfomarchesService {

  constructor(private _http: HttpClient, private _filtServ: FilterService, private _loader: LoaderService ) { }
  _env = environment;

  infoMarches = new Subject<any>();
  infoCourbesMatif = new Subject<any>();
  InfoCourbesTitle = new BehaviorSubject(null);
  infoCBOT = new BehaviorSubject<any>(null);

  filterConf = {};

  getFilterIM: Subscription;
  getMatif:Subscription;
  
  getFilterCBOT: Subscription;
  getCBOT: Subscription;

  getCourbes: Subscription;

  setInfoMarche(){
    this.getFilterIM = this._filtServ.getFilter().subscribe(
      filterContent => {
         const filterConf = this._filtServ.createFilterFromCheckedValues(filterContent);
        // //console.log(filterContent);
        // //console.log(filterConf);
        const filterData = new FormData();
        filterData.append("filter",JSON.stringify(filterConf));

        this.getFilterIM ? this.getFilterIM.unsubscribe() : null;
        if(filterContent){
          this.getMatif =  this._http.post(this._env.BZ_API_BASE_URL + "transaction/matif/format/json",filterData,{withCredentials:true}).subscribe(
            infos => {
              // //console.log(infos);
              this.infoMarches.next(infos);
              this.getMatif.unsubscribe();
              
            }, error => {
              //console.log(error);
              this.getMatif.unsubscribe();
            }
          )
        }
      }
    )
    
  }

  getInfoMarche(){
    return this.infoMarches.asObservable();
  }

  setInfoCBOT(){
    this.getFilterCBOT = this._filtServ.getFilter().subscribe(
      filterContent => {
         const filterConf = this._filtServ.createFilterFromCheckedValues(filterContent);
        // //console.log(filterContent);
        // //console.log(filterConf);
        const filterData = new FormData();
        filterData.append("filter",JSON.stringify(filterConf));
        this.getFilterCBOT ? this.getFilterCBOT.unsubscribe() : null; 

        if(filterContent){
          this.getCBOT = this._http.post(this._env.BZ_API_BASE_URL + "transaction/infosmarches/format/json",filterData,{withCredentials:true}).subscribe(
            CBOT => {
              // //console.log("CBOT SERVICE =>",CBOT);
              this.infoCBOT.next(CBOT);
              this.getCBOT.unsubscribe(); 
            }, error => {
              //console.log(error);
              this.getCBOT.unsubscribe(); 
            }
          )
        }
      }
    )
  }

  getInfoCBOT(){
    return this.infoCBOT.asObservable();
  }

  setInfoCourbesMatif(idmhValue:string,titre:string){
    if(idmhValue && titre){
      this.InfoCourbesTitle.next(titre);
      //console.log(idmhValue)
      let data = new FormData();
      data.append("idmh",idmhValue);
      
      this.getCourbes = this._http.post(this._env.BZ_API_BASE_URL + "courbesmatif/index/format/json",data,{withCredentials:true}).subscribe(
        CourbesData => {
          // //console.log("Courbes DATAS =>",CourbesData);
          this.infoCourbesMatif.next(CourbesData);
          this.getCourbes.unsubscribe();
        }, error => {
          //console.log(error);
          this.getCourbes.unsubscribe();
        }
      )
    }
  }

  getInfoCourbesMatif(){
    return this.infoCourbesMatif.asObservable();
  }

  getInfoCourbesTitle(){
    return this.InfoCourbesTitle.asObservable();
  }


}
