import { Component, OnInit } from '@angular/core';
import { Produit } from 'src/app/models/produit';
import { DashboardService } from 'src/app/services/dashboard.service';
import { InfomarchesService } from 'src/app/services/infomarches.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-dash-info-marche',
  templateUrl: './dash-info-marche.component.html'
})
export class DashInfoMarcheComponent implements OnInit {

  getMarches: Subscription;
  infosDeMarche;
  constructor(private infoServ: InfomarchesService,) { }

  ngOnInit() {
    this.infoServ.setInfoMarche();
    this.getMarches = this.infoServ.getInfoMarche().subscribe(
      infos => {
        this.infosDeMarche = infos;
        console.log(this.infosDeMarche )
      }
    )
  }

  ngOnDestroy(){
    if(this.getMarches){
      this.getMarches.unsubscribe();
    }
  }

}
