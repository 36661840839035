import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "contratSvgPathPipe"
})
export class contratSvgPipe implements PipeTransform {
    transform(value: string): any {
        let result = null;
        // //console.log(value);
        switch (value) {
            case "B Baz": 
                result = "/assets/icons/contrats/b_baz.svg";
                break;
            case "B Beuz": 
                result = "/assets/icons/contrats/b_beuz.svg";
                break;
            case "B Futur": 
                result = "/assets/icons/contrats/b_futur.svg";
                break;
            case "B Zap": 
                result = "/assets/icons/contrats/b_zap.svg";
                break;
            case "B Zenith": 
                result = "/assets/icons/contrats/b_zenith.svg";
                break;
            case "B Zen": 
                result = "/assets/icons/contrats/b_zen.svg";
                break;
            case "B Osmoz": 
                result = "/assets/icons/contrats/osmoz.svg";
                break;
            case "B Symbioz": 
                result = "/assets/icons/contrats/symbioz.svg";
                break;
            case "Securiz": 
                result = "/assets/icons/contrats/securiz.svg";
                break;
            case "Optimiz": 
                result = "/assets/icons/contrats/optimiz.svg";
                break;
        
            default:
                break;
        }
    // //console.log(result);
        return result;
    }
}