import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "contratColorsPipe"
})
export class contratColorsPipe implements PipeTransform {
    transform(value: string): any {
        let result = null;
        // //console.log(value);
        switch (value) {
            case "B Baz": 
                result = 'rgba(59, 89, 152, 0.6)';
                break;
            case "B Beuz": 
                result = "rgba(236, 238, 25, 0.6)";
                break;
            case "B Futur": 
                result = "rgba(255,101,80,0.6)";
                break;
            case "B Zap": 
                result = "rgba(162, 155, 254, 0.6)";
                break;
            case "B Zenith": 
                result = "rgba(170, 205, 105, 0.6)";
                break;
            case "B Zen": 
                result = "rgba(0, 206, 201, 0.6)";
                break;
            case "B Osmoz": 
                result = "rgba(232, 67, 147, 0.6)";
                break;
            case "B Symbioz": 
                result = "RGBA(55,164,216,0.6)";
                break;
        
            default:
                break;
        }
    // //console.log(result);
        return result;
    }
}