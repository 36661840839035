import { Component, OnInit } from '@angular/core';
import { DocumentService } from 'src/app/services/document.service';
import { FilterService } from 'src/app/services/filter.service';
import { Subscription } from 'rxjs';
import { ResearchService } from 'src/app/services/research.service';
import { PagerService } from 'src/app/services/pager.service';
import { FilterDocumentsService } from 'src/app/services/filter-documents.service';
import { DownloadService } from 'src/app/services/download.service';
import { ToastrService } from 'ngx-toastr';


@Component({
  selector: 'app-documents',
  templateUrl: './documents.component.html'
})
export class DocumentsComponent implements OnInit {

  constructor(
    private _docServ: DocumentService, 
    private _filtServ: FilterService,
    private _searchServ: ResearchService,
    private pagerService: PagerService,
    private _docFiltServ: FilterDocumentsService,
    private _downloadServ: DownloadService,
    private _toaster:ToastrService) 
  { 
    
  }

  private getAllDocs: Subscription;
  private dlDocumentSub: Subscription;
  public allDocsRaw;
  public DocsFiltered; // Valeur qui vient 
  private allDocsCount:number;

  /////PAGER
  pager: any = {};

  // paged items
  pagedItems: any[];

  viewportWidth;

  p: number = 1;

  ngOnInit() { 
    window.scrollTo(0, 0)
    this._docServ.setAllDocuments();
  }

  dlDocument(num,documentType){
    this.dlDocumentSub = this._downloadServ.downloadDocument(num,documentType).subscribe(
      response => {
        console.log(response)
          if(response['type'] == "application/download"){
            let dataType = response['type'];
            let binaryData = [];
            binaryData.push(response);
            let downloadLink = document.createElement('a');
            console.log(downloadLink)
            downloadLink.href = window.URL.createObjectURL(new Blob(binaryData, {type: dataType}));
            downloadLink.setAttribute('download',documentType + "-" + num + ".pdf");
            document.body.appendChild(downloadLink);
console.log(num);
console.log(documentType);
            console.log(downloadLink);
            downloadLink.click();
          } else {
            this._toaster.error("Aucun document trouvé! Echec du téléchargement")
          }

        // this.mvmntFilename.next(fileName);
        this.dlDocumentSub.unsubscribe();
      },
      error => {
        this.dlDocumentSub.unsubscribe();
      } 
    )
  }


  ngAfterContentChecked(){
    this.allDocsRaw = this._docServ.getDocuments();
    if(this.allDocsRaw && !this.pagedItems){
      this._docServ.getFilteredDocuments(this.allDocsRaw)
      this.DocsFiltered = this._docServ.searchResult.getValue();
      // this.setPage(1)
      // console.log(this.DocsFiltered)
    }
  } 

  ngDoCheck(){
    if(this.DocsFiltered){
      this.DocsFiltered = this._docServ.searchResult.getValue();
      console.log(this.DocsFiltered)
    }
    this.viewportWidth = window.innerWidth;
  }

  ngOnChanges(){
    this.dlDocumentSub ? this.dlDocumentSub.unsubscribe() : null;
  }

  

}

