import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { BehaviorSubject } from 'rxjs';
import { CookieService } from 'ngx-cookie-service';
import { debounceTime, share } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  constructor(private _http: HttpClient,private _cookieServ: CookieService) { }

  _env=environment;

  public userInfos = new BehaviorSubject(null);

  public userRole = new BehaviorSubject(null);

  getUserDetail(){
    return this._http.get(this._env.BZ_API_BASE_URL + "compte/index/format/json",{withCredentials:true});
  }

  getUserConnected(){
    const userDetails = {
      userName: this._cookieServ.get("userName"),
      userMail: this._cookieServ.get("userMail"),
      userFirstName: this._cookieServ.get("userFirstName"),
      userLastName: this._cookieServ.get("userLastName"),
      userTel: this._cookieServ.get("userTel"),
    }
    
    return userDetails;
  }

  modifyPassword(loginString){
    const objLogin = JSON.stringify({"login": loginString});
    return this._http.post(this._env.BZ_API_BASE_URL + "compte/changepwd", objLogin, {withCredentials:true})
  }
}
