import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-btn-retour',
  templateUrl: './btn-retour.component.html'
})
export class BtnRetourComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }
  
  returnBack(){
    return window.history.back();
  }
}
