import { Component, OnInit, Input } from '@angular/core';
import { FormControl } from '@angular/forms';
import { ValidationService } from '../../../services/validation.service';

@Component({
  selector: 'app-error-span',
  templateUrl: './error-span.component.html',
  styleUrls: ['./error-span.component.scss']
})
export class ErrorSpanComponent implements OnInit {

  @Input() control: FormControl;;
  constructor() {}

  ngOnInit() {}
  get errorMessage() {
    for (let propertyName in this.control.errors) {
      if (this.control.errors.hasOwnProperty(propertyName)) {
        return ValidationService.getValidatorErrorMessage(propertyName, this.control.errors[propertyName]);
      }
    }    
    return null;
  }

}
